import {Button, Link, Stack} from "@mui/material";
import React, {useEffect} from "react";

import {useForm} from "react-hook-form";
import MInputText from "../components/common/MInputText";
import {apiLogin} from "../store/api_user";
import FindEmailDialog from "../components/modal/FindEmailDialog";
import {feedbackSnackbarAtom} from "../store/atoms/atom_config";
import {useSetAtom} from "jotai";
import {useSearchParams} from "react-router-dom";
import {getTokenCookie} from "../store/cookies";
import RequestResetPasswordDialog from "../components/modal/RequestResetPasswordDialog";
import {buildErrorMessage, moveURL} from "../util/misc";


function Login() {

    const {
        handleSubmit, control,
        formState: {isSubmitting, isDirty, errors},
    } = useForm({
        defaultValues: {email: "", password: ""},
        shouldFocusError: true,
    });
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const [searchParams, setSearchParams] = useSearchParams();
    const [openFindEmailDialog, setOpenFindEmailDialog] = React.useState(false);
    const [openResetPasswordDialog, setOpenResetPasswordDialog] = React.useState(false);
    const redirectUrl = searchParams.get('redirect');
    useEffect(() => {
        const token = getTokenCookie();
        if (token) {
            moveURL(redirectUrl && redirectUrl !== '/login' ? redirectUrl : '/')
        }
    }, []);

    const doLogin = async (data) => {
        const {email, password} = data;
        try {
            await apiLogin(email, password);
            moveURL(redirectUrl ? redirectUrl : '/')
        } catch (e) {
            setFeedbackSnackbar([buildErrorMessage(e, {401: '로그인 정보가 잘못되었습니다.'}), 'error']);
        }
    }

    return (
        <div className="bf-center-container">
            <Stack className="bf-login">
                <div className="header">
                    <div className='logo'></div>
                </div>

                <form onSubmit={handleSubmit(doLogin)}>
                    <Stack spacing={2} className="form">
                        <MInputText
                            fullWidth
                            name="email" control={control}
                            id="email" type="text"
                            label="이메일"
                            placeholder="test@email.com"
                            aria-invalid={!isDirty ? undefined : errors.email ? "true" : "false"}
                            variant="outlined"
                            rules={{
                                required: "이메일은 필수 입력입니다.",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "이메일 형식에 맞지 않습니다."
                                }
                            }}
                        />
                        {errors.email && <small role="alert">{errors.email.message}</small>}
                        <MInputText
                            name="password" control={control}
                            id="password" type="password"
                            placeholder="****************"
                            label="비밀번호"
                            aria-invalid={!isDirty ? undefined : errors.password ? "true" : "false"}
                            rules={{
                                required: "비밀번호는 필수 입력입니다.",
                                minLength: {
                                    value: 6,
                                    message: "8자리 이상 비밀번호를 사용하세요."
                                }
                            }}
                        />
                        {errors.password && <small role="alert">{errors.password.message}</small>}
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row" spacing={2}>
                                <Link component="button" type="button" variant="body2" color="#000"
                                      onClick={()=> setOpenFindEmailDialog(true)}>이메일 찾기</Link>
                                <Link component="button" type="button" variant="body2" color="#000"
                                      onClick={()=> setOpenResetPasswordDialog(true)}>비밀번호 찾기</Link>
                            </Stack>
                            <Link href="/signup" variant="body2" color="#000">회원 가입</Link>
                        </Stack>
                        <Button type="submit" variant="hc_contained_navy" style={{height:56}} disabled={isSubmitting}>
                            로그인
                        </Button>
                    </Stack>
                </form>
            </Stack>
            <FindEmailDialog aria-labelledby="find-email-dialog"
                open={openFindEmailDialog}
                onClose={() => setOpenFindEmailDialog(false)}
            />
            <RequestResetPasswordDialog aria-labelledby="reset-password-dialog"
                                        open={openResetPasswordDialog}
                                        onClose={() => setOpenResetPasswordDialog(false)}
            />
        </div>
    );
}

export default Login;
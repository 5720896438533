import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField
} from "@mui/material";
import React from "react";
import {useMutation} from "@tanstack/react-query";
import {apiResetPasswordRequest} from "../../store/api_user";
import {feedbackSnackbarAtom} from "../../store/atoms/atom_config";
import {useSetAtom} from "jotai";


function RequestResetPasswordDialog(props) {

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);

    const resetPasswordRequest = useMutation({
        mutationFn: apiResetPasswordRequest,
        onSuccess: (data, variables, context) => {
            console.log('[mutation] apiResetPasswordRequest', data);
            setFeedbackSnackbar(['비밀번호 재설정 링크를 이메일로 보냈습니다.', 'info']);
            onCancel();
        },
        onError: (error, variables, context) => {
            console.log('onError', error, error.cause, variables, context)
            if (error.cause?.err_code === 404) {
                setFeedbackSnackbar(["사용자를 찾을 수 없습니다.", "error"]);
            } else {
                setFeedbackSnackbar(['서버에서 문제가 발생했습니다.', 'error']);
            }
        }
    });

    const onCancel = () => {
        setName('');
        setEmail('');
        props.onClose();
    }

    return (
    <Dialog fullWidth className="bf-modal" {...props}>
        <DialogTitle className="title">비밀번호 재설정하기</DialogTitle>
        <DialogContent className="container">
            <DialogContentText>
                가입하실 때 입력한 이름과 이메일을 입력하시면 비밀번호를 재설정할 수 있는 링크를 보내드립니다.
            </DialogContentText>
            <Stack className="bf-modal-content">
                <TextField className="form-item" label="이름" variant="outlined" fullWidth value={name}
                    onChange={(e) => setName(e.target.value)}/>
                <TextField className="form-item" label="이메일" variant="outlined" fullWidth value={email}
                    onChange={(e) => setEmail(e.target.value)}/>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button variant="hc_contained_navy" onClick={onCancel}>취소</Button>
            <Button variant="hc_contained_navy" onClick={() => resetPasswordRequest.mutate({name, email})}
                    disabled={!name || !email}>재설정 링크 보내기</Button>
        </DialogActions>
    </Dialog>

    )
}

export default RequestResetPasswordDialog;
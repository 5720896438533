import {useAtomValue} from "jotai";
import {calcScorecardTotal} from "../service/formService";
import {metadataAtom} from "../store/atoms/atom_config";

function ScorecardScoreView({storeAtom, formMeta}) {
    const store = useAtomValue(storeAtom);
    const metadata = useAtomValue(metadataAtom);
    if (!metadata || !store || Object.keys(store).length === 0) return null;

    const {score, total} = calcScorecardTotal(store, formMeta);
    return (<div className="bf-score-info">🚩 총 점 : <span className="score">{score}</span> / {total}점</div>);
}

export default ScorecardScoreView;
import {useAtomValue} from "jotai";
import {metadataAtom} from "../store/atoms/atom_config";

function BFRecommendGuide({campaignId}) {
    const metadata = useAtomValue(metadataAtom);
    let scheduleText;
    switch (campaignId) {
        case 'brianfellow_2023':
        case 'brianfellow_004':
            scheduleText = '추천 기간: 8/1 ~ 8/8'
            break;
        case 'brianfellow_005':
            scheduleText = '추천 기간: 4/29(월) ~ 5/12(일)'
            break;
    }
    const campaignName = metadata?.campaigns[campaignId]?.campaign_name || '브라이언 펠로우';
    if (!metadata) return null;
    return (
        <div className="bf-recommend-guide">
            <h1>{campaignName} 후보자 추천</h1>
            <div className="guide-hello">
                <p>
                안녕하세요. 브라이언임팩트입니다.<br />
                브라이언 펠로우는 가치있는 활동에 온전히 몰입할 수 있도록 혁신가들을 지혜롭게 돕는 프로그램입니다.<br />
                더 나은 세상을 만들기 위해 행동하고 있는 사람을 브라이언 펠로우로 추천해 주세요.
                </p>
            </div>
            <div className="guide-text">
                <ul>
                    <li> 펠로우십 네트워크에만 제공되는 비공개 추천 양식입니다. </li>
                    <li> 추천서는 PC에서 작성을 권장합니다. </li>
                    <li> 최대 3명까지 추천 가능합니다. </li>
                    <li><strong> {scheduleText} </strong></li>
                </ul>
            </div>
        </div>
    )

}

export default BFRecommendGuide;
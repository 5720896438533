import React, {useRef} from 'react';
import {Box} from '@mui/material';
import FormItemView from "./FormItemView";
import FormItemValidatorView from "./FormItemValidatorView";
import {useAtomValue} from "jotai";
import {metadataAtom} from "../store/atoms/atom_config";
import classNames from "classnames";
import FormAnnotation from "./FormAnnotation";

const findSection = (sectionArr, sectionKey) => {
    return sectionArr.find(section => section.key === sectionKey);
}

const buildDescElement = function(descMeta, listClass, itemClass) {
    if (! descMeta) return null;
    const {type, content} = descMeta;
    switch (type) {
        case 'ul':
            return <ul className={classNames(listClass)}>{(content||[]).map((str, idx) => (
                <li key={idx} className={classNames(itemClass)}>{str}</li>))}</ul>;
        case 'ol':
            return <ol className={classNames(listClass)}>{(content||[]).map((str, idx) => (
                <li key={idx} className={classNames(itemClass)}>{str}</li>))}</ol>;
        case 'plain':
            return <div className={classNames(listClass, 'plain')}>{(content||[]).map((str, idx) => (
                <p key={idx} className={classNames(itemClass)}>{str}</p>))}</div>;
        default:
            return null;
    }
}

const buildHeaderDescElement = function(descMeta) {
    const cname = descMeta.position === 'below' ? 'step-header below' : 'step-header';
    return <div className={cname}>{buildDescElement(descMeta, 'step-header-desc')}</div>;
}

const buildSectionDescElement = function(descMeta) {
    return buildDescElement(descMeta, 'section-desc');
}

function FormStepBodyView({formMeta, campaignId, formType, formAtoms, funcWriteChangeIntoStore, isOwner, isEmbed}) {

    const metadata = useAtomValue(metadataAtom);
    const formData = useAtomValue(formAtoms.dataAtom);
    const formStepInfo = useAtomValue(formAtoms.stepInfoAtom);
    const formDependency = useAtomValue(formAtoms.dependencyAtom);

    //const [store] = useMemo(() => useAtom(applicationStoreAtom), [formStepInfo]);
    const storeCache = useRef({});

    console.log("[FormStepBodyView]", formMeta, metadata, formData, formStepInfo);

    if (!metadata || !formData) return null;
    const readOnly = !isOwner || formData['status'] !== 'draft';

    // ToDo: step 없는 경우도 적용하기
    const funcWrapper = (key, value, format) => {
        storeCache.current[key] = value;
        funcWriteChangeIntoStore(key, value, format);
    }

    const getInit = (key) => {
        const initContent = formData.form_data[key];
        //console.log('### getInit:', key, initContent, storeCache.current[key]);
        if (storeCache.current[key]) {
            return {...initContent, ["value"]: storeCache.current[key]};
        } else
            return initContent;
    }

    const getDependencyEffect = (itemMeta) => {
        //console.log('### check dependency:', itemMeta, formDependency);
        const dep = itemMeta.options?.dependency;
        return dep?.item_key && formDependency[dep?.item_key]
            ? dep?.effect : null;
    }

    return (
        <Box className='form-body'>
            {formStepInfo.currentStep === 0 &&
                <FormAnnotation campaignId={campaignId} docType={formData.doc_type} docId={formData.doc_id} forceReadOnly={isEmbed}/>}
            {formMeta.steps.map((step, stepIdx) => (
                formStepInfo.currentStep === stepIdx &&
                <div key={`step-${stepIdx}`} className={classNames('form-step')}>
                    {step.header?.desc?.position === 'above' && buildHeaderDescElement(step.header.desc)}
                    <h2 className='step-title'>{step.title}</h2>
                    {step.header?.desc?.position === 'below' && buildHeaderDescElement(step.header.desc)}
                    {step.section_keys.map((sectionKey, idx) => {
                        const section = findSection(formMeta.sections, sectionKey);
                        return (
                            <div key={`section-${stepIdx}-${idx}`} className='form-section'>
                                {!formMeta.options?.hide_section_info && section.section_type!=='hidden' && (<>
                                    <h3 className='section-title'>{section.title}</h3>
                                    {section.desc && buildSectionDescElement(section.desc)}
                                </>)}
                                {section.items.map(item => (
                                    <React.Fragment key={item.key}>
                                        <FormItemView
                                            meta={item}
                                            initContents={getInit(item.key)}
                                            funcWriteChangeIntoStore={funcWrapper}
                                            readOnly={readOnly}
                                            dependencyEffect={getDependencyEffect(item)}
                                            formMeta={{formType, campaignId}}
                                        />
                                        <FormItemValidatorView itemKey={item.key} validatorAtom={formAtoms.validatorAtom} />
                                    </React.Fragment>
                                ))}
                            </div>
                        )
                    })}
                </div>
            ))}
        </Box>
    )

}

export default FormStepBodyView;
import React from 'react';
import {Box} from '@mui/material';
import HCTextField from "./form/HCTextField";
import HCTextArea from "./form/HCTextArea";
import HCRadioSelect from "./form/HCRadioSelect";
import HCCheckSelect from "./form/HCCheckSelect";
import HCDatePicker from "./form/HCDatePicker";
import HCTableAg from "./form/HCTableAg";
import HCCheckDepend from "./form/HCCheckDepend";
import UnknownFormItem from "./form/UnknownFormItem";
import HCAttachedFileTableAg from "./form/HCAttachedFileTableAg";
import HCSlider from "./form/HCSlider";

function FormItemView(props) {

    const {meta} = props;
    const getFormClass = function() {
        const itemFormat = meta.format;
        switch (itemFormat) {
            case 'textfield': return <HCTextField {...props} />;
            case 'textarea': return <HCTextArea {...props} />;
            case 'radio_select': return <HCRadioSelect {...props} />;
            case 'check_select': return <HCCheckSelect {...props} />;
            case 'date_picker': return <HCDatePicker {...props} />;
            case 'table': return <HCTableAg {...props} />;
            case 'check_depend': return <HCCheckDepend {...props} />;
            case 'attached_file_table': return <HCAttachedFileTableAg {...props} />;
            case 'slider': return <HCSlider {...props} />;
            default: return <UnknownFormItem {...props} />;
        }
    }

    const getItemDescElement = function() {
        if (!meta?.desc) return null;
        const {type, content} = meta.desc;
        switch (type) {
            case 'ul':
                return <ul className="form-item-desc">{(content||[]).map((str, idx) => (<li key={idx}>{str}</li>))}</ul>;
            case 'ol':
                return <ol>{(content||[]).map((str, idx) => (<li key={idx}>{str}</li>))}</ol>;
            case 'plain':
                return <div className="form-item-desc">{(content||[]).map((str, idx) =>
                    (<p key={idx}>{str}</p>))}</div>;
            default:
                console.log('invalid item desc', meta.desc);
                return null;
        }
    }

    const isHiddenHeader = meta.format === 'check_depend' || meta.options.hide_title === true;

    return (
        <Box className='form-body-item'>
            {!isHiddenHeader && <div className='form-item-title'>{meta.title}{meta.required && <span className="form-item-required">*</span>}</div>}
            {!isHiddenHeader && <Box className='form-item-guide'>{getItemDescElement()}</Box>}
            <Box className='form-item-container'>{getFormClass()}</Box>
        </Box>
    )
}

export default FormItemView;
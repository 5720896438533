import {API_BASE_URL, build_default_headers, check_api_response} from "./api_common";

const apiFetchEvaluationList = async(campaignId, evalRound, evalSubRound, mode) => {
    console.log('[API] fetch evaluation list', campaignId, evalRound, evalSubRound, mode);
    const params = {campaign_id: campaignId, eval_round: evalRound, mode: mode}
    if (evalSubRound) params['eval_subround'] = evalSubRound;
    const queryString = new URLSearchParams(params).toString();
    const url = `${API_BASE_URL}/evaluations?${queryString}`;
    const response = await fetch(url, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json;
}

const apiFetchEvaluation = async(evaluation_id) => {
    console.log('[API] fetch_evaluation', evaluation_id);
    const response = await fetch(`${API_BASE_URL}/evaluations/${evaluation_id}`, {
        headers: build_default_headers(),
    })
    const json = await check_api_response(response);
    return json;
}


export {
    apiFetchEvaluationList,
    apiFetchEvaluation,
}
import React, {useEffect, useState} from 'react';
import {Checkbox, FormControl, FormControlLabel, FormGroup, OutlinedInput, Stack} from '@mui/material';
import {divideArray} from "../../util/misc";

function HCCheckSelect({meta, initContents, funcWriteChangeIntoStore, readOnly, dependencyEffect}) {
    // console.log('[HCCheckSelect]', meta?.key, meta, initContents);
    const selectOptions = meta?.options?.select_options;
    const maxSelect = meta?.options?.max_select;
    const numColumns = meta?.options?.columns;
    const customizableOptions = meta?.options?.customizable_options;

    // { 'opt1': true, 'opt2': false, ... }
    const [selected , setSelected] = useState(null);
    // { 'opt1': 'custom1', 'opt2': 'custom2', ... }
    const [customizedValues, setCustomizedValues] = useState(null);
    const dataKey = meta.key;
    const disabled = dependencyEffect === 'disabled';

    useEffect(() => {
        if (!initContents || !meta) return;
        const selectedObj = {}
        selectOptions.forEach((opt, idx) => {
            const key = opt.value || opt.title;
            selectedObj[key] = initContents?.value?.checked?.includes(key);
        });
        setSelected(selectedObj);
        setCustomizedValues(initContents.value?.customized||{})
    }, [initContents]);

    if (! selected) return null;

    // checked: list[str]
    // customized: dict[str, str]
    const obj2db = (obj) => {
        const ret = [];
        selectOptions.forEach((opt, idx) => {
            const key = opt.value || opt.title;
            if (obj[key]) {
                ret.push(key);
            }
        });
        return {checked: ret}
    }

    const onChangeFn = (e) => {
        if (readOnly) return;
        const name = e.target.name.split('chk_')[1];
        const newValue = {...selected, [name]: e.target.checked};
        const newDb = obj2db(newValue);
        if (maxSelect && newDb.checked.length > maxSelect) return;

        funcWriteChangeIntoStore(dataKey, {...newDb, customized: customizedValues});
        setSelected(newValue);
    }

    const onChangeCustomFn = (e) => {
        const name = e.target.name.split('cus_')[1];
        const newValue = {...customizedValues, [name]: e.target.value};

        funcWriteChangeIntoStore(dataKey, {
            checked: obj2db(selected).checked,
            customized: newValue
        });
        setCustomizedValues(newValue);
    }

    const sx_custom = [{marginTop:0, marginLeft:2}, {display: 'none'}];
    const renderOneItem = (opt, idx, groupIdx) => {
        const key = opt.value || opt.title;
        const checkBox =
            (<FormControlLabel key={`${groupIdx}-${idx}`} label={opt.title} control={
                <Checkbox checked={!! selected[key]} onChange={onChangeFn} name={`chk_${key}`}
                          disabled={disabled} size="small" sx={{padding: "5px"}} color="navy" />}
            />);
        const customBox = (customizableOptions && customizableOptions.includes(key)) ?
            (<FormControl sx={selected[key] ? sx_custom[0] : sx_custom[1]}>
                <OutlinedInput value={customizedValues[key]||''} size="small" name={`cus_${key}`}
                               disabled={disabled}
                               onChange={onChangeCustomFn}
                               readOnly={readOnly}
                />
            </FormControl>) : null;
        return customBox ? (<React.Fragment key={`${groupIdx}-${idx}`}>{checkBox}{customBox}</React.Fragment>) : checkBox;
    }


    const groups = divideArray(selectOptions, numColumns);
    return (
        <Stack spacing={2} direction="row" sx={{ml:1}}>
            {groups.map((group, groupIdx) =>
                (<FormGroup key={`grp-${groupIdx}`}>
                    {group.map((opt, idx) => renderOneItem(opt, idx, groupIdx))}
                </FormGroup>)
            )}
        </Stack>
    )
}

export default HCCheckSelect;
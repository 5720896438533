import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import "../../css/shareSnsDlg.css"
import {shareKakao} from "../../util/shareKakaoLinks";

function ShareSnsDialog(props) {

    const {open, onClose, title, desc, imageUrl, imageKakaotalk, url, buttonTitle} = props;
    const [clipboardCopied, setClipboardCopied] = useState(false);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://developers.kakao.com/sdk/js/kakao.js";
        script.async = true;
        document.body.appendChild(script);
        return () => document.body.removeChild(script);
    }, []);

    const urlShared = url || window.location.href;

    const onDlgClose = () => {
        setClipboardCopied(false);
        if (onClose) onClose();
    }

    const shareKakaotalk = () => {
        shareKakao(urlShared, title, "", imageKakaotalk || imageUrl, buttonTitle || '사이트로 가기');
        onDlgClose();
    }
    const shareFacebook = () => {
        const sharedLink = encodeURIComponent(urlShared);
        const share = `https://www.facebook.com/dialog/share?display=popup&href=${sharedLink}&redirect_uri=${sharedLink}`;
        window.open(share, '_blank', 'width=100,height=100');
        onDlgClose();
    }

    const shareX = () => {
        const sharedLink = "text=" + encodeURIComponent(title + " \n ") + encodeURIComponent(urlShared);
        const share = `https://twitter.com/intent/tweet?${sharedLink}`;
        window.open(share, '_blank', 'width=800,height=600');
        onDlgClose();
    }

    const onCopyClipboard = async () => {
        await navigator.clipboard.writeText(urlShared);
        setClipboardCopied(true);
    }

    return (
        <Dialog className="share-sns-dlg" open={open}>
            <DialogTitle>공유하기</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Stack className='btn-container' direction="row" spacing={1} justifyContent="space-between" alignContent="center">
                        <Button className="btn-logo kakao" onClick={shareKakaotalk}></Button>
                        <Button className="btn-logo facebook" onClick={shareFacebook}></Button>
                        <Button className="btn-logo twitter" onClick={shareX}></Button>
                    </Stack>
                    <Stack className='btn-container' spacing={1}>
                        <Button className="copy-btn" variant="hc_outlined_black" onClick={onCopyClipboard}>URL 클립보드로 복사</Button>
                        {clipboardCopied && <div className="copied">URL이 복사되었습니다.<br/>원하는 곳에 붙여넣기(Ctrl+V)해 주세요.</div>}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDlgClose}>닫기</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ShareSnsDialog;
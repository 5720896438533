import {API_BASE_URL, build_default_headers, check_api_response} from "./api_common";

const apiFetchScorecard = async(scorecard_id) => {
    console.log('[API] fetch_scorecard', scorecard_id);
    const response = await fetch(`${API_BASE_URL}/scorecards/${scorecard_id}`, {
        headers: build_default_headers(),
    })
    const json = await check_api_response(response);
    return json;
}

const apiUpdateScorecard = async(params) => {
    const {id, formData} = params;
    console.log('[API] update_scorecard', id);
    const response = await fetch(`${API_BASE_URL}/scorecards/${id}/update-body`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(formData),
    })
    const json = await check_api_response(response);
    return json;
}

const apiSubmitScorecard = async(params) => {
    const {id, isSubmit} = params;
    console.log('[API] submit_scorecard', id, isSubmit);
    const response = await fetch(`${API_BASE_URL}/scorecards/${id}/submit`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({type: isSubmit ? 'submit' : 'withdraw'}),
    })
    const json = await check_api_response(response);
    return json;
}

export {
    apiFetchScorecard,
    apiUpdateScorecard,
    apiSubmitScorecard,
}
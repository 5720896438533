import React from "react";

function bf004_application_agreement() {
    return {
        "title": "개인정보 수집 및 이용에 대한 동의",
        "content": (
            <div className="bf-app-agree-terms">
                <p className="desc">재단법인 브라이언임팩트(이하 “재단”)는 지원서 사이트 서비스(이하 “서비스”) 제공을 위하여 지원서 작성·제출과 관련하여 아래와 같은 개인정보를 수집
                    및
                    이용하고자 합니다.
                    재단은 귀하의 정보를 관리함에 있어서 「개인정보 보호법」에서 규정하고 있는 책임과 의무를 준수하고 귀하가 동의하신 목적 외 다른 목적으로는 활용하지 않음을 알려드립니다</p>
                <p className="title">수집하려는 개인정보의 항목</p>
                <p className="desc">회원가입시 제공 정보, 생년월일, 직업/소속/조직에 관한 사항(직업명, 조직명, 역할/직책, 조직규모, 조직유형),
                    활동기본정보(활동/솔루션 분야, 주요활동지역범위), 주요 활동 히스토리, 사회문제 아젠다 및 지원자의 활동·목표·파트너에 대한 사항,
                    SNS 계정, 지원이력, 수상이력, 추가 자기소개 자료(인터뷰, 영상자료 등) 등 지원서 기재항목</p>
                <p className="title">개인정보 수집 및 이용목적</p>
                <p className="desc">지원자 선정 및 관리 등 지원 프로그램 운영 및 관리</p>
                <p className="title">개인정보의 보유 및 이용기간</p>
                <p className="desc">서비스 종료 또는 동의철회시까지</p>
                <p className="desc2">동의를 거부하실 수 있으나 동의를 거부하실 경우 지원 프로그램 참여가 제한됩니다.</p>
            </div>
        )
    }
}

export default bf004_application_agreement;
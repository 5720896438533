import ChangePasswordDialog from "../components/modal/ChangePasswordDialog";
import {useSearchParams} from "react-router-dom";

function ResetPassword() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token')
    return (
        <div>
            <ChangePasswordDialog hideBackdrop className="dialog-only" mode="reset" open={true} token={token} />
        </div>
    );
}

export default ResetPassword
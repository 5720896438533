import {DataGrid} from "@mui/x-data-grid";
import {useAtomValue, useSetAtom} from "jotai";
import {feedbackSnackbarAtom, metadataAtom} from "../store/atoms/atom_config";
import {globalConfirmAtom} from "../store/atoms/atom_ui";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {apiFetchEvaluationList} from "../store/api_evaluation";
import React from "react";
import {useParams} from "react-router-dom";
import {utcDayjs} from "../util/misc";

function BFEvaluationList(props) {
    const {campaignId} = props;
    const {round} = useParams();
    const roundInfo = round.split('-');

    const metadata = useAtomValue(metadataAtom);

    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const setGlobalConfirm = useSetAtom(globalConfirmAtom);

    const queryClient = useQueryClient();
    const {isSuccess, data}= useQuery({
        queryKey: [campaignId, round, 'scorecard-my'],
        queryFn: () => apiFetchEvaluationList(campaignId, roundInfo[0],
            roundInfo.length > 1 ? roundInfo[1] : null, "my"),
        enabled: !!(metadata?.connected)
    });

    const columns = [
        {field: 'id', headerName: 'ID', cellClassName: "evaluation-cell", headerClassName: "evaluation-header",},
        /*{field: 'evaluator_name', headerName: '평가자', cellClassName: "evaluation-cell", headerClassName: "evaluation-header",},*/
        {field: 'applicant', headerName: '지원자', flex: 1, cellClassName: "evaluation-cell", headerClassName: "evaluation-header",
            renderCell: (p) => <a href={`/c/${campaignId}/eval/${round}/${p.row.id}`}>{p.row.applicant}</a>
        },
        {field: 'scorecard_evaluation', headerName: '점수', cellClassName: "evaluation-cell", headerClassName: "evaluation-header",},
        {field: 'scorecard_id', headerName: '진행상황', width: 120, cellClassName: "evaluation-cell", headerClassName: "evaluation-header",
            renderCell: (p) => {
                const status = p.row.scorecard_status;
                const text = status === 'draft' ? '작성 중' : (status === 'submitted' ? '제출 완료' : '알수 없음');
                return <span className={`status-${status}`}>{text}</span>;
            }
        },
        { field: 'scorecard_updated_at', headerName: '최근 수정일', width: 150, cellClassName: "evaluation-cell", headerClassName: "evaluation-header"},
    ]

    if (!isSuccess) return null;
    const formMetaDict = data.form_meta_dict;
    const convertData = (data) => {
        const rows = [];
        data && data.forEach((item) => {
            const info = [];
            if (item.target_user.nickname) info.push(item.target_user.nickname);
            if (item.target_user.job || item.target_user.org) info.push(item.target_user.org || item.target_user.job);
            const infoStr = info.length > 0 ? ` (${info.join(',')})` : '';
            let scorecard_evaluation = '';
            if (item.scorecard) {
                const formMeta = formMetaDict[item.scorecard.form_format_id];
                if (formMeta.options?.use_score) {
                    scorecard_evaluation = `${item.calculated_score} / ${item.calculated_total_score}`;
                } else if (formMeta.options?.use_pass_fail) {
                    scorecard_evaluation = item.scorecard.form_data[formMeta.options?.use_pass_fail]?.value || '';
                }
            }
            const row = {
                'id': item.eval_id,
                'application_id': item.application_id,
                'applicant': `${item.target_user.name}${infoStr}` ,
                'evaluator_name': item.evaluator_name,
                'evaluator_id': item.evaluator_id,
                'scorecard_id': item.scorecard_id,
                'scorecard_evaluation': scorecard_evaluation,
                'scorecard_status': item.scorecard.status,
                'scorecard_updated_at': utcDayjs(item.scorecard.updated_at).format('MM.DD HH:mm'),
            }
            rows.push(row);
        });
        return rows;
    }
    const evaluations = convertData(data?.evaluations);
    const numSubmitted = evaluations.filter((item) => item.scorecard_status === 'submitted').length;
    const getListTitle = () => {
        switch(roundInfo[0]) {
            case 'paper': return '서류 심사 리스트';
            case 'onsite': return '현장 인터뷰 평가 리스트';
            case 'final': return '최종 후보 심사 리스트';
            default: return '심사 리스트'
        }
    }

    return (<>
        <div className="bf-eval-list-desc">
            <h2>{getListTitle()}</h2>
            {evaluations.length > 0 &&
            <p className='txt'>배정 완료되었습니다. 총 <span className='num'>{evaluations.length}명</span> 배정되었습니다.
                (심사 Status : {numSubmitted}/{evaluations.length})</p>}
            {evaluations.length === 0 && <p className='txt'>배정된 심사가 없습니다.</p>}
        </div>
        <div className="bf-recommend-list">
            <DataGrid className="grid-container" columns={columns} rows={evaluations}
                      autoHeight hideFooter />
        </div>
    </>);
}

export default BFEvaluationList;
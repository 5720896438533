import {Button, IconButton, Stack} from "@mui/material";
import {useAtom} from "jotai";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import React from "react";

function FormStepNavi({formAtoms}) {
    const [formStepInfo, setFormStepInfo] = useAtom(formAtoms.stepInfoAtom);
    const prevStep = () => {
        if (formStepInfo.currentStep > 0) {
            setFormStepInfo({...formStepInfo, currentStep: formStepInfo.currentStep - 1});
        }
    }
    const nextStep = () => {
        if (formStepInfo.currentStep < formStepInfo.numStep - 1) {
            setFormStepInfo({...formStepInfo, currentStep: formStepInfo.currentStep + 1});
        }
    }
    return (
        <Stack direction="row" className="step-navi" justifyContent="space-evenly">
            {formStepInfo.currentStep > 0 &&
            <Stack direction="row" className="step-navi-btn">
                <IconButton onClick={prevStep}><ArrowCircleLeftIcon className="btn orange" /></IconButton>
                <Button color="black" onClick={prevStep} className="title-text">이전 단계로</Button>
            </Stack>}
            {formStepInfo.currentStep < formStepInfo.numStep - 1 &&
            <Stack direction="row" className="step-navi-btn">
                <Button color="black" onClick={nextStep} className="title-text">다음 단계로</Button>
                <IconButton onClick={nextStep}><ArrowCircleRightIcon className="btn orange" /></IconButton>
            </Stack>}
        </Stack>
    )
}

export default FormStepNavi;
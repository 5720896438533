import classNames from "classnames";
function StepIndicator({numStep, currentStep, isLinear}) {
    console.log('[StepIndicator]', numStep, currentStep, isLinear);
    return (
        <div className="step-ind-wrap">
            <div className="links">
            {[...Array(numStep)].map((item, i) =>
                    <div key={i+1} className={classNames("dot", {active: i===currentStep})}>{i+1}</div>)}
            </div>
        </div>
    )
}

export default StepIndicator;
const getFormTypeMeta = (metadata, campaignId, formType, subType) => {
    const formTypeMeta = (metadata?.campaigns?.[campaignId].documents?.[formType] || [])
        .filter((doc) => doc.subtype === subType);
    return formTypeMeta?.[0];
}

const getFormFormatId = (metadata, campaignId, formType, subType) => {
    const formTypeMeta = getFormTypeMeta(metadata, campaignId, formType, subType)
    return formTypeMeta?.format;
}

const getFormTermsNeeded = (userInfo, metadata, campaignId, formType, subType) => {
    if (! userInfo) return null;
    const formTypeMeta = getFormTypeMeta(metadata, campaignId, formType, subType);
    const term = formTypeMeta?.terms?.[0]; // ToDo: 추후 확장을 위해 list 되어 있으나 현재는 1개만 사용
    if (! term) return null;
    return ((userInfo?.terms||[]).indexOf(term.id) === -1) ? term : null;
}

const isFormSubmittable = (metadata, campaignId, formType, subType) => {
    const formTypeMeta = getFormTypeMeta(metadata, campaignId, formType, subType);
    return formTypeMeta?.submittable;
}

const getSubmittableForms = (metadata, campaignId, formType) => {
    return (metadata?.campaigns?.[campaignId].documents?.[formType] || [])
        .filter((doc) => doc.submittable);
}

const getCreatableForms = (metadata, campaignId, formType, from) => {
    return (metadata?.campaigns?.[campaignId].documents?.[formType] || [])
        .filter((doc) => doc.can_create_from === from);
}

const getCampaignStatus = (metadata, campaignId) => {
    return metadata?.campaigns?.[campaignId]?.status;
}

const isFormMenuVisible = (metadata, campaignId, formType, subType) => {
    const formTypeMeta = getFormTypeMeta(metadata, campaignId, formType, subType);
    return formTypeMeta?.visible === true;
}

const getFormHomeUrl = (metadata, campaignId, formType, subType) => {
    if (formType === 'scorecard') {
        return `/c/${campaignId}/eval/${subType}`
    } else {
        return `/c/${campaignId}/doc/${formType}`
    }
}

export {
    getFormTypeMeta,
    getFormFormatId,
    getFormTermsNeeded,
    isFormSubmittable,
    getCampaignStatus,
    isFormMenuVisible,
    getFormHomeUrl,
    getSubmittableForms,
    getCreatableForms,
}
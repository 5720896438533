import dayjs from "dayjs";
import {isEmptyArray, isValidEmail, isValidUrl} from "../util/misc";
import {
    apiCreateNominationWithData,
    apiFetchNewEmptyNomination,
    apiFetchNomination,
    apiSubmitNomination,
    apiUpdateNomination
} from "../store/api_nomination";
import {
    apiCreateApplicationWithData,
    apiFetchApplication,
    apiFetchNewEmptyApplication,
    apiSubmitApplication,
    apiUpdateApplication
} from "../store/api_application";
import {apiFetchScorecard, apiSubmitScorecard, apiUpdateScorecard} from "../store/api_scorecard";
import * as Sentry from "@sentry/react";
import {FatalException} from "./exceptions";
import {
    apiCreateDiggingNoteWithData,
    apiFetchDiggingNote,
    apiFetchNewEmptyDiggingNote,
    apiSubmitDiggingNote,
    apiUpdateDiggingNote
} from "../store/api_digging_note";

const _noOpApi = () => {
    return Promise.reject('No API is provided');
};

function selectFormDocumentApi(formType) {
    switch (formType) {
        case 'nomination':
            return {
                formFetchApi: apiFetchNomination,
                newDocApi: apiFetchNewEmptyNomination,
                updateFormBodyApi: apiUpdateNomination,
                createFormApi: apiCreateNominationWithData,
                submitFormApi: apiSubmitNomination,
            }
        case 'application':
            return {
                formFetchApi: apiFetchApplication,
                newDocApi: apiFetchNewEmptyApplication,
                updateFormBodyApi: apiUpdateApplication,
                createFormApi: apiCreateApplicationWithData,
                submitFormApi: apiSubmitApplication,
            }
        case 'scorecard':
            return {
                formFetchApi: apiFetchScorecard,
                newDocApi: _noOpApi,
                updateFormBodyApi: apiUpdateScorecard,
                createFormApi: _noOpApi,
                submitFormApi: apiSubmitScorecard,
            }
        case 'digging_note':
            return {
                formFetchApi: apiFetchDiggingNote,
                newDocApi: apiFetchNewEmptyDiggingNote,
                updateFormBodyApi: apiUpdateDiggingNote,
                createFormApi: apiCreateDiggingNoteWithData,
                submitFormApi: apiSubmitDiggingNote,
            }
        default:
            throw Error('Unknown formType: ' + formType);
    }
}

const _getFormDependencyObject = (formData) => {
    const dependObj = {};
    Object.keys(formData).forEach((key) => {
        const item = formData[key];
        if (item.format === 'check_depend') {
            dependObj[key] = item.value;
        }
    });
    return dependObj;
}

const setFormDocumentData = (data, formType, formMeta,
                             setData, setStore, setNeedSave, setFormStepInfo, setFormDependency) => {
    setData(data[formType]);
    setStore(data[formType].form_data);
    if (setNeedSave) setNeedSave(false);
    if (setFormStepInfo) setFormStepInfo({currentStep: 0, numStep: isEmptyArray(formMeta.steps) ? 0 : formMeta.steps.length,});
    const dependObj = _getFormDependencyObject(data[formType].form_data);
    setFormDependency(dependObj);
}

// scorecard edit 시에 점수를 조정해야하므로 필요
// 점수 계산 로직은 server와 sync를 맞춰야 함
const calcScorecardTotal = (formData, formMeta) => {
    console.log('#### calc scorecard total: ', formData, formMeta);
    let score = 0;
    let total = 0;
    formMeta.sections.forEach((section) => {
        section.items.forEach((item) => {
            if (item.format === 'slider') {
                total += Math.max(...item.options.values);
                score += formData[item.key].value;
            }
        });
    });
    return { score, total };
}

const findItemMeta = (formMeta, itemKey) => {
    let found = null;
    formMeta.sections.forEach((section) => {
        const fr = section.items.find((item) => item.key === itemKey);
        if (fr) found = fr;
    });
    return found;
}

const checkRequired = (format, value) => {
    switch (format) {
        case 'check_select': return (value?.checked?.length > 0);
        case 'radio_select': return (value !== undefined && value !== null);
        case 'date_picker': return (!!value);
        case 'table': return (value?.length > 0);
        case 'textarea': return (!!value);
        case 'textfield': return (!!value);
        default: return true;
    }
}

const validateFormData = (formData, formMeta) => {
    const validateResult = [];
    console.log('#### validate: ', formData, formMeta);
    formMeta.sections.forEach((section) => {
        section.items.forEach((item) => {
            const depend = item.options?.dependency;
            if (depend && depend.effect === 'disabled') {
                if (formData[depend.item_key].value) return; // skip validation
            }
            if (!checkRequired(item.format, formData[item.key].value)) {
                if (item.required) {
                    validateResult.push({ key: item.key, message: '필수 항목입니다.' });
                }
                return; // 항목이 비어 있으므로 추가 검증은 하지 않음
            }
            const validator = item.options?.validator;
            if (validator) {
                switch (validator) {
                    case 'email':
                        if (!isValidEmail(formData[item.key].value))
                            validateResult.push({ key: item.key, message: '이메일 형식이 올바르지 않습니다.' });
                        break;
                    case 'url':
                        if (!isValidUrl(formData[item.key].value))
                            validateResult.push({ key: item.key, message: 'URL 형식이 올바르지 않습니다.("http://" 혹은 "https://"를 포함한 전체 주소를 적어주세요)' });
                        break;
                    default:
                }
            }
            if (item.format === 'date_picker') {
                if (! dayjs(formData[item.key].value, 'YYYY.MM.DD').isValid())
                    validateResult.push({ key: item.key, message: '날짜 형식이 올바르지 않습니다.' });
            }
        });
    });
    return validateResult;
}

const getStatusStr = (formType, status, admin) => {
    if (formType === 'nomination') {
        switch (status) {
            case 'draft': return admin ? '작성 중' : '저장 (제출 전)';
            case 'submitted': return '제출 완료';
            default: return "알수없음";
        }
    } else if (formType === 'application') {
        switch (status) {
            case 'draft': return admin ? '작성 중' : '저장 (제출 전)';
            case 'submitted': return '제출 완료';
            default: return "알수없음";
        }
    } else if (formType === 'scorecard') {
        switch (status) {
            case 'draft': return admin ? '작성 중' : '저장 (제출 전)';
            case 'submitted': return '제출 완료';
            default: return "알수없음";
        }
    } else {
        return "알수없음";
    }
}

const getFormNameFromType = (formType) => {
    switch (formType) {
        case 'nomination': return '추천서';
        case 'application': return '지원서';
        case 'scorecard': return '심사표';
        case 'digging_note': return '디깅노트';
        default: return '[알수없음]';
    }
}

const getFormActionNameFromType = (formType) => {
    switch (formType) {
        case 'nomination': return '추천';
        case 'application': return '지원';
        case 'scorecard': return '심사';
        case 'digging_note': return '제출';
        default: return '[알수없음]';
    }
}

const formItemToStr = (form, key) => {
    const item = form[key]
    switch (item?.format) {
        case 'check_select':
            return item?.value?.checked?.join(',');
        default:
            return item?.value||'';
    }
}

const writeChangeToStore = (key, value, format, formId, formMeta, setStore, setNeedSave, setFormDependency) => {
    setStore((prev) => {
        let newItem;
        if (! prev[key]) {
            newItem = {}
            const itemMeta = findItemMeta(formMeta, key);
            if (!itemMeta) {
                const errorLog = `Unknown Item [${formId}] [${key}]`;
                console.error(errorLog);
                Sentry.captureException(new FatalException(errorLog));
                newItem['format'] = 'unknown';
            } else {
                newItem['format'] = itemMeta.format;
            }
        } else {
            newItem = {...prev[key]};
        }
        newItem['value'] = value;
        return {...prev, [key]: newItem};
    });
    setNeedSave(true);
    if (format === 'check_depend') {
        setFormDependency((prev) => {return {...prev, [key]: value};});
    }
}

export {
    selectFormDocumentApi,
    calcScorecardTotal,
    validateFormData,
    getStatusStr,
    findItemMeta,
    formItemToStr,
    setFormDocumentData,
    writeChangeToStore,
    getFormNameFromType,
    getFormActionNameFromType,
}
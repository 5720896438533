import axios from 'axios';
import {API_BASE_URL, build_default_headers} from "./api_common";

const apiGetPresignedUploadUrl = async (params) => {
    const {campaignId, formType, fileName, fileType, partitionCount} = params;
    const response = await axios({
        method: 'POST',
        headers: build_default_headers(),
        url: `${API_BASE_URL}/storages/get_pre_signed_upload_url`,
        data: {
            campaign_id: campaignId,
            form_type: formType,
            file_name: fileName,
            content_type: fileType,
            partition_count: partitionCount||1
        }
    });
    return response.data;
}

const apiUploadFileToS3 = async (params) => {
    const {url, data, onProgress} = params;
    const response = await axios({
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        url: url,
        data: data,
        onUploadProgress: (pe) => {
            onProgress(pe.loaded, pe.total)
        },
    });
    return response.data;
}

const apiGetPresignedDownloadUrl = async (params) => {
    const {campaignId, objectName} = params;
    const response = await axios({
        method: 'POST',
        headers: build_default_headers(),
        url: `${API_BASE_URL}/storages/get_pre_signed_download_url`,
        data: {
            campaign_id: campaignId,
            object_name: objectName
        }
    });
    return response.data;
}


/*
static* multiPartUploadFileToS3(payload, options) {
    console.log('[API] multiPartUploadFileToS3: ', payload);
    const {signedUrl, fileName, fileBlob} = payload;
    const numChunks = signedUrl.length;
    const chunkSize = Math.ceil(fileBlob.size / numChunks);
    const calls = [];
    signedUrl.forEach((url, idx) => {
        const start = idx * chunkSize;
        const blob = idx < numChunks-1 ? fileBlob.slice(start, start+chunkSize) : fileBlob.slice(start);
        calls.push(call(axios, {
            method: 'PUT',
            headers: {
                'Content-Type': fileBlob.type,
                'Content-Disposition': `attachment;filename=${encodeURIComponent(fileName)}`,
            },
            url: url,
            data: blob,
            onUploadProgress: (pe) => options.onProgress(pe.loaded, pe.total, idx)
        }))
    });
    const results = yield all(calls);
    const partInfo = results.map((part, idx) => ({
        ETag: part.headers.etag,
        PartNumber: idx + 1
    }));
    return buildApiResult(true, payload, partInfo);
}

static* completeMultipartUpload(payload, options) {
    console.log('[API] completeMultipartUpload: ', payload);
    const headers = yield buildHeaders();
    const {campaignId, target, objectName, uploadId, partInfo} = payload;
    return yield axios({
        method: 'POST',
        headers: headers,
        data: {campaignId, target, objectName, uploadId, partInfo},
        url: '/api/priv/complete_multipart_upload',
    })
        .then(response => response.data)
        .then(json => buildApiResult(true, payload, json.data, json.profile));
}
*/

export {
    apiGetPresignedUploadUrl,
    apiUploadFileToS3,
    apiGetPresignedDownloadUrl,
}
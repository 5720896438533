import {QueryCache, QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {CssBaseline} from '@mui/material';
import {
    createBrowserRouter,
    createRoutesFromChildren,
    createRoutesFromElements,
    matchRoutes,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    useNavigationType
} from "react-router-dom";
import {useEffect} from "react";
import {ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {honeycombTheme} from "./components/common/theme";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import * as Sentry from '@sentry/react';

import BFLayout from "./pages/BFLayout";
import CampaignHome from "./pages/CampaignHome";
import FeedbackSnackbar from "./components/common/FeedbackSnackbar";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import {useSetAtom} from "jotai";
import {feedbackSnackbarAtom} from "./store/atoms/atom_config";
import GlobalModalDialog from "./components/common/GlobalModalDialog";
import GenericFormView from "./pages/GenericFormView";
import GenericFormHome from "./pages/GenericFormHome";
import ResetPassword from "./pages/ResetPassword";
import ErrorWithCode from "./pages/error/errorWithCode";
import {useLocation} from "react-router";
import {buildErrorMessage, moveURL} from "./util/misc";
import GenericEvaluationView from "./pages/GenericEvaluationView";
import GenericEvaluationHome from "./pages/GenericEvaluationHome";
import BrianFellowLanding from "./pages/landing/BrianFellowLanding";

dayjs.extend(utc);
dayjs.extend(dayjsCustomParseFormat);
const ERR404 = <Navigate to={"/error/404"} replace={true} />;
const ERR_UNKNOWN = <Navigate to={"/error/e999"} replace={true} />;

Sentry.init({
    dsn: "https://0cb9850b96c8431ebde0b2240fd27267@o4505318897745920.ingest.sentry.io/4505606803816448",
    environment: process.env.REACT_APP_STAGE || "dev",
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    tracesSampleRate: 0.1,
});

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Outlet />}>
            <Route index element={<Navigate to={"/program/brianfellow"} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/program" errorElement={<ErrorWithCode />}>
                <Route path="brianfellow" element={<BFLayout hasPublicPage />}>
                    <Route index element=<BrianFellowLanding /> />
                </Route>
            </Route>
            <Route path="/c" element={<Outlet />} errorElement={<ErrorWithCode />}>
                <Route path=":campaignId" element={<BFLayout />}>
                    <Route index element={<CampaignHome />} />
                    <Route path="doc" element={<Outlet />} >
                        <Route path=":formType">
                            <Route index element={<GenericFormHome />} />
                            <Route path=":formSubType" element={<Outlet />}>
                                <Route path=":formId" element={<GenericFormView />} />
                            </Route>
                        </Route>
                        <Route index element={ERR404} />
                    </Route>
                    <Route path="eval" element={<Outlet />} >
                        <Route path=":round">
                            <Route index element={<GenericEvaluationHome />} />
                            <Route path=":evaluationId" element={<GenericEvaluationView />} />
                        </Route>
                    </Route>
                </Route>
                <Route index element={ERR404} />
            </Route>
            <Route path="/error/:errCode" element={<ErrorWithCode />} />
            <Route path="*" element={<Navigate to={"/error/404"} replace={true} />} />
        </Route>
    )
);

function App() {
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    // https://velog.io/@cnsrn1874/breaking-react-querys-api-on-purpose
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: 0,
                refetchOnWindowFocus: false
            }
        },
        queryCache: new QueryCache({
            onError: (err, query) => {
                if (! query.meta?.silent) {
                    if (err.cause?.err_code === 403) {
                        moveURL('/error/403');
                    }
                    if (query.meta?.errorMessage) {
                        setFeedbackSnackbar([query.meta.errorMessage, 'error']);
                    } else {
                        setFeedbackSnackbar([
                            buildErrorMessage(err, query.meta?.errorMessages||{}), 'error']);
                    }
                }
            },
            onSuccess: (data, query) => {
                if (query.meta?.successMessage) {
                    setFeedbackSnackbar([query.meta.successMessage, 'info']);
                }
            }
        })
    });

    if (process.env.REACT_APP_STAGE === 'prod') {
        const noOp = function(){}; // no-op function
        if (window.console) { window.console.log = noOp; }
    }

    return (
        <ThemeProvider theme={honeycombTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}
                                  dateFormats={{monthShort: 'M', 'monthAndYear': 'YYYY. MM'}}>
                <QueryClientProvider client={queryClient}>
                    <div className="App">
                        <CssBaseline/>
                        <RouterProvider router={router} />
                        <FeedbackSnackbar />
                        <GlobalModalDialog />
                    </div>
                    {/*<ReactQueryDevtools initialIsOpen={false} />*/}
                </QueryClientProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;

import {getCampaignStatus, isFormMenuVisible} from "./metaService";

function checkRoleForMenu(userInfo, campaignId, doc_type, sub_type, menu_access) {
    // console.log('[checkRoleForMenu]', userInfo, campaignId, doc_type, sub_type, menu_access);
    switch (menu_access) {
        case 'public': return true;
        case 'admin': return isAdmin(userInfo, campaignId);
        case 'boot': return isBootOrAdmin(userInfo, campaignId);
        case 'role':
            switch (doc_type) {
                case 'application': return true;
                case 'nomination': return userInfo.roles[`nominator:${campaignId}`];
                case 'digging_note': return userInfo.roles[`brianfellow:${campaignId}`];
                case 'scorecard':
                    switch (sub_type) {
                        case 'paper': return userInfo.roles[`evaluator:${campaignId}`] || isBootOrAdmin(userInfo, campaignId);
                        case 'onsite': return userInfo.roles[`evaluator:${campaignId}`] || isBootOrAdmin(userInfo, campaignId);
                        case 'final-pre1': return userInfo.roles[`evaluator:${campaignId}`] || userInfo.roles[`evaluator-final:${campaignId}`];
                        case 'final-pre2': return userInfo.roles[`evaluator:${campaignId}`] || userInfo.roles[`evaluator-final:${campaignId}`];
                        case 'final': return userInfo.roles[`evaluator:${campaignId}`] || userInfo.roles[`evaluator-final:${campaignId}`];
                        case 'fellow_extend': return userInfo.roles[`evaluator:${campaignId}`];
                        default: return false;
                    }
                default: return false;
            }
        default: return false;
    }
}


function applicationMenuExist(userInfo, metadata, campaignId) {
    return isFormMenuVisible(metadata, campaignId, 'application', 'site')
}

function nominationMenuExist(userInfo, metadata, campaignId) {
    if (! isFormMenuVisible(metadata, campaignId, 'nomination', 'network')) return false;
    return isAdmin(userInfo, campaignId) ||
        (userInfo?.roles && (userInfo.roles[`nominator:${campaignId}`] || userInfo.roles['boot']));
}

function evalPaperMenuExist(userInfo, metadata, campaignId) {
    if (!['evaluating'].includes(getCampaignStatus(metadata, campaignId))) return false;
    return isAdmin(userInfo, campaignId) || isBoot(userInfo, campaignId)
}

function evalOnsiteMenuExist(userInfo, metadata, campaignId) {
    if (!['evaluating'].includes(getCampaignStatus(metadata, campaignId))) return false;
    return isAdmin(userInfo, campaignId) || isBoot(userInfo, campaignId)
}

function evalFinalMenuExist(userInfo, metadata, campaignId) {
    if (!['evaluating'].includes(getCampaignStatus(metadata, campaignId))) return false;
    return isAdmin(userInfo, campaignId) || isBoot(userInfo, campaignId) ||
        (userInfo?.roles && userInfo.roles[`evaluator:${campaignId}`]);
}

function diggingNoteMenuExist(userInfo, campaignId) {
    return isAdmin(userInfo, campaignId) || isBoot(userInfo, campaignId) ||
        (userInfo?.roles && userInfo.roles[`brianfellow:${campaignId}`]);
}

function dashboardMenuExist(userInfo, campaignId) {
    return isAdmin(userInfo, campaignId) || isBoot(userInfo, campaignId)
}

function isAdmin(userInfo, campaignId) {
    // ToDo: campaignId가 없는 경우 (program home)
    for (let role in userInfo?.roles||[]) {
        if (role === 'admin') return true;
        if (campaignId && role === `admin:${campaignId}`) return true;
        if (!campaignId && role.startsWith('admin:')) return true;
    }
    return false;
}

function isBoot(userInfo, campaignId) {
    return userInfo?.roles && userInfo.roles['boot']
}

function isBootOrAdmin(userInfo, campaignId) {
    return isAdmin(userInfo, campaignId) || isBoot(userInfo, campaignId)
}

function checkBrianFellow(userInfo) {
    for (let role in userInfo.roles) {
        if (role.startsWith('brianfellow:')) return role.split(':')[1];
    }
    return null;
}

export {
    applicationMenuExist,
    nominationMenuExist,
    dashboardMenuExist,
    evalPaperMenuExist,
    evalOnsiteMenuExist,
    evalFinalMenuExist,
    diggingNoteMenuExist,
    isAdmin,
    isBoot,
    isBootOrAdmin,
    checkBrianFellow,
    checkRoleForMenu,
}
import React, {useState} from 'react';
import {OutlinedInput, Grid} from '@mui/material';
import NoDataDiv from "./sub/NoDataDiv";

function HCTextField({meta, initContents, funcWriteChangeIntoStore, readOnly, dependencyEffect}) {
    // console.log('[HCTextField]', meta?.key, meta, initContents, readOnly, dependencyEffect);
    const itemFormat = meta.format;
    const placeholder = meta.options?.placeholder;
    const grid_width = meta.options?.view_grid_width;

    const [contents , setContents] = useState(initContents?.value);
    const dataKey = meta.key;
    //console.log('hcTextField', dataKey, contents);

    const onChangeFn = (e) => {
        setContents(e.target.value);
        funcWriteChangeIntoStore(dataKey, e.target.value);
    }

    const emptyReadOnly = readOnly && !contents;

    return (
        <Grid container>
            <Grid item xs={12} sm={grid_width}>
                {!emptyReadOnly && <OutlinedInput
                    fullWidth
                    placeholder={placeholder}
                    value={contents}
                    onChange={onChangeFn}
                    readOnly={readOnly}
                    disabled={dependencyEffect === 'disabled'}
                ></OutlinedInput>}
                {emptyReadOnly && <NoDataDiv />}
            </Grid>
        </Grid>
    )
}

export default HCTextField;
import {setTokenCookie} from "./cookies";
import {API_BASE_URL, build_default_headers, check_api_response} from "./api_common";

const apiLogin = async (email, password) => {
    const response = await fetch(`${API_BASE_URL}/users/login`, {
        method: 'POST',
        headers: build_default_headers(true, true),
        body: new URLSearchParams({
            'username': email,
            'password': password,
        })
    });
    const json = await check_api_response(response);
    const { access_token } = json;
    setTokenCookie(access_token);
}

const apiSignup = async (params) => {
    const {name, email, mobile_phone, password, agree_terms, agree_privacy, agree_marketing, agree_ad} = params;
    const response = await fetch(`${API_BASE_URL}/users`, {
        method: 'POST',
        headers: build_default_headers(true),
        body: JSON.stringify({
            name, email, mobile_phone, password, agree_terms, agree_privacy, agree_marketing, agree_ad,
        }),
    });
    const json = await check_api_response(response);
    return json;
}

const apiFindEmail = async (params) => {
    const {name, token} = params;
    const response = await fetch(`${API_BASE_URL}/users/find-email`, {
        method: 'POST',
        headers: build_default_headers(true),
        body: JSON.stringify({name, token,})
    });
    const json = await check_api_response(response);
    return json;
}

const apiResetPasswordRequest = async (params) => {
    const {name, email} = params;
    const response = await fetch(`${API_BASE_URL}/users/reset-password/request`, {
        method: 'POST',
        headers: build_default_headers(true),
        body: JSON.stringify({name, email,})
    });
    const json = await check_api_response(response);
    return json;
}

const apiResetPassword = async (params) => {
    const {token, new_password} = params;
    const response = await fetch(`${API_BASE_URL}/users/reset-password/reset`, {
        method: 'POST',
        headers: build_default_headers(true),
        body: JSON.stringify({token, new_password})
    });
    const json = await check_api_response(response);
    return json;
}

const apiChangePassword = async (params) => {
    const {old_password, new_password} = params;
    const response = await fetch(`${API_BASE_URL}/user/change-password`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({old_password, new_password})
    });
    const json = await check_api_response(response);
    return json;
}


const apiConnect = async () => {
    const response = await fetch(`${API_BASE_URL}/user/connect`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json;
}

const apiAnonConnect = async () => {
    const response = await fetch(`${API_BASE_URL}/user/anonymous-connect`, {
        headers: build_default_headers(true),
    });
    const json = await check_api_response(response);
    return json;
}


const apiAgreeTerms = async(params) => {
    const {terms_id} = params;
    console.log('[API] agree_terms', terms_id);
    const response = await fetch(`${API_BASE_URL}/user/agree-terms`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({terms_id}),
    })
    const json = await check_api_response(response);
    return json;
}


export {
    apiLogin,
    apiConnect,
    apiAnonConnect,
    apiSignup,
    apiFindEmail,
    apiResetPasswordRequest,
    apiResetPassword,
    apiChangePassword,
    apiAgreeTerms,
}
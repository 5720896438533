import {useAtomValue} from "jotai";


function FormItemValidatorView({itemKey, validatorAtom}) {

    const validator = useAtomValue(validatorAtom);
    const itemValidateInfo = validator[itemKey];
    if (!itemValidateInfo) return null;
    return (
        <div className="form-item-validator">
            <div className="msg">{itemValidateInfo.message}</div>
        </div>
    )
}

export default FormItemValidatorView;
import React, {useState} from 'react';
import {OutlinedInput, Grid, Checkbox, FormControlLabel, FormGroup} from '@mui/material';

function HCCheckDepend({meta, initContents, funcWriteChangeIntoStore, readOnly}) {
    //console.log('[HCTextField]', meta?.key, meta, initContents, readOnly);
    const dataKey = meta.key;
    const [checkValue, setCheckValue] = useState(initContents?.value || false);

    const onChangeFn = (e) => {
        if (readOnly) return;
        setCheckValue(e.target.checked);
        funcWriteChangeIntoStore(dataKey, e.target.checked, meta.format);
    }

    return (
        <FormGroup className="hc-check-depend">
            <FormControlLabel
                label={<span className="label">{meta.title}</span>}
                control={<Checkbox checked={checkValue} onChange={onChangeFn} color="navy"  />} />
        </FormGroup>
    )
}

export default HCCheckDepend;
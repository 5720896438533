import React from "react";

function bf003_fellow_extend_nda() {
    return {
        "title": "비밀유지 서약서",
        "content": (
            <div className="bf-app-agree-terms">
                <p className="desc">본인은 재단법인 브라이언임팩트(이하 ‘재단’)의 &lt;브라이언 펠로우&gt;자문위원으로 참여함에 있어 관련 규정과 양심에 따라 공정하게 자문∙심의하고, 공정한 업무집행과 펠로우의 비밀보호를 위해 자문업무의 착수 및 종료 이후에도 다음의 사항을 준수할 것을 엄숙히 서약합니다.</p>
                <br/>
                <p className="desc">1. 본인은 자문위원 참여와 관련하여 지득한 일체의 사항(펠로우가 제출한 활동 및 사업 아이디어 및 기밀 등 포함)이 직무상 기밀사항을 인정한다.</p>
                <p className="desc desc2">2. 본인은 이 기밀을 제3자에게 제공 또는 누설하지 아니하고, 이를 재단 및 펠로우의 허락 없이 자신 또는 제3자를 위하여 사용하지 아니한다.</p>
                <p className="desc desc2">3. 본인은 자문업무가 종료된 즉시 자문위원 참여와 관련하여 재단으로부터 제공받은 일체의 자료를 복구 또는 재생되지 아니하는 방법으로 파기한다.</p>
                <p className="desc desc2">4. 본인은 펠로우의 개인정보 보호를 위하여 관련 법령과 재단이 정하는 개인정보 내부관리계획을 준수하고, 적절한 절차 없이 무단조회, 누출, 오용하지 아니한다.</p>
                <p className="desc desc2">5. 본인은 자신이 자문위원으로 위촉(임명)된 사실에 대하여 제3자에게 알리거나 제3자가 알게 하지 아니하고, 이 위촉 사실에 대해 비밀을 유지한다.</p>
                <p className="desc desc2">6. 본인이 이 서약서의 내용을 위반한 때에는 관련법령 및 계약에 따른 처벌 및 불이익을 감수한다.</p>
            </div>
        )
    }
}

export default bf003_fellow_extend_nda;
import React from "react";
import {Stack} from "@mui/material";
import {useAtomValue} from "jotai";
import {metadataAtom} from "../store/atoms/atom_config";
import {layoutConfigAtom} from "../store/atoms/atom_ui";

function Footer() {
    const metadata = useAtomValue(metadataAtom);
    const layoutConfig = useAtomValue(layoutConfigAtom);
    if (!metadata) return null;
    if (layoutConfig.hideFooter) return <footer className="footer-mini"></footer>;

    return (
        <footer className="footer">
            <span className='brianimpact-logo-small' />
            <Stack className="footer-contents">
                <div className="policy">
                    <span><a href="https://brianimpact.oopy.io/apply/policy/privacy" target="_blank" rel="noreferrer">개인정보처리방침</a></span>
                    <span><a href="https://brianimpact.oopy.io/apply/policy/terms" target="_blank" rel="noreferrer">이용약관</a></span>
                    <span><a href="https://brianimpact.org" target="_blank" rel="noreferrer">홈페이지</a></span>
                </div>
                <div className="footer-text">
                    <p>이메일 : brianfellow@brianimpact.org</p>
                    <p>주소 : 서울시 강남구 선릉로 428</p>
                    <p>© 2021 Brian Impact Foundation. All Rights Reserved.</p>
                </div>
            </Stack>
        </footer>
    )
}

export default Footer;
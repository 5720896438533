// https://tech.osci.kr/react-hook-form-with-mui/
import React from "react";
import {TextField} from "@mui/material";
import {useController} from "react-hook-form";

function MInputText({name, rules, control, readOnly, ...props}) {
    const {
        field: { value, onChange, onBlur },
        fieldState: { isDirty, isTouched, error },
    } = useController({name, rules, control});

    return (
        <TextField
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!error}
            inputProps={{readOnly: readOnly}}
            {...props}
        />
    );
}

export default MInputText;
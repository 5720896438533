import React, {useState} from 'react';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';

function HCRadioSelect({meta, initContents, funcWriteChangeIntoStore, readOnly, dependencyEffect}) {
    //console.log('[HCRadioSelect]', meta?.key, meta, initContents);
    const selectOptions = meta?.options?.select_options;
    const [selected , setSelected] = useState(initContents?.value || selectOptions[0].value);
    const dataKey = meta.key;
    const disabled = dependencyEffect === 'disabled';

    const onChangeFn = (e) => {
        if (readOnly) return;
        setSelected(e.target.value);
        funcWriteChangeIntoStore(dataKey, e.target.value);
    }

    return (
        <RadioGroup row value={selected} onChange={onChangeFn}>
            {selectOptions.map((opt, idx) =>
                (<FormControlLabel key={idx} value={opt.value || opt.title} control={
                    <Radio color="navy" disabled={disabled} disableRipple={readOnly} />
                } label={opt.title} />))
            }
        </RadioGroup>
    )
}

export default HCRadioSelect;
import {check_api_response, build_default_headers} from "./api_common";
import {API_BASE_URL} from "./api_common";


const apiFetchDiggingNoteList = async(campaignId, mode) => {
    console.log('[API] fetch digging_note list', campaignId, mode);
    const params = {campaign_id: campaignId, mode: mode}
    const queryString = new URLSearchParams(params).toString();
    const response = await fetch(`${API_BASE_URL}/digging_notes?${queryString}`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json;

}

const apiFetchDiggingNote = async(docId) => {
    console.log('[API] fetch_nomination', docId);
    const response = await fetch(`${API_BASE_URL}/digging_notes/${docId}`, {
        headers: build_default_headers(),
    })
    const json = await check_api_response(response);
    return json;
}

const apiFetchNewEmptyDiggingNote = async(campaignId, docType, docSubType) => {
    console.log('[API] fetch_new_empty_digging_note', campaignId, docType, docSubType);
    const params = {campaign_id: campaignId, doc_type: docType, doc_subtype: docSubType}
    const response = await fetch(`${API_BASE_URL}/digging_notes/new-empty-doc`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(params),
    });
    const json = await check_api_response(response);
    return json;
}

const apiUpdateDiggingNote = async(params) => {
    const {id, formData} = params;
    console.log('[API] update_digging_note', id);
    const response = await fetch(`${API_BASE_URL}/digging_notes/${id}/update-body`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(formData),
    })
    const json = await check_api_response(response);
    return json;
}

const apiDeleteDiggingNote = async(params) => {
    const {id} = params;
    console.log('[API] delete_digging_note', id);
    const response = await fetch(`${API_BASE_URL}/digging_notes/${id}`, {
        method: 'DELETE',
        headers: build_default_headers(),
    })
    const json = await check_api_response(response);
    return json;
}

const apiSubmitDiggingNote = async(params) => {
    const {id, isSubmit} = params;
    console.log('[API] submit_nomination', id, isSubmit);
    const response = await fetch(`${API_BASE_URL}/digging_notes/${id}/submit`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({type: isSubmit ? 'submit' : 'withdraw'}),
    })
    const json = await check_api_response(response);
    return json;
}

const apiCreateDiggingNoteWithData = async(params) => {
    const {id, data} = params;
    console.log('[API] apiCreateDiggingNoteWithData');
    const response = await fetch(`${API_BASE_URL}/digging_notes/${id}/create-with-data`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(data),
    })
    const json = await check_api_response(response);
    return json;
}

export {
    apiFetchDiggingNoteList,
    apiFetchDiggingNote,
    apiFetchNewEmptyDiggingNote,
    apiUpdateDiggingNote,
    apiCreateDiggingNoteWithData,
    apiDeleteDiggingNote,
    apiSubmitDiggingNote,
}
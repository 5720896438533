import {Navigate, useParams} from "react-router-dom";
import {metadataAtom, userInfoAtom} from "../store/atoms/atom_config";
import {useAtomValue} from "jotai";
import {getFormHomeUrl} from "../service/metaService";
import {Box} from "@mui/material";

function CampaignHome() {
    const metadata = useAtomValue(metadataAtom);
    const userInfo =  useAtomValue(userInfoAtom);
    const {campaignId} = useParams();

    if (!userInfo || !metadata) return null;

    const campaignMeta = metadata.campaigns[campaignId];
    if (!campaignMeta) {
        return <Navigate to={"/error/404"} replace={true}/>;
    }

    const sorted = (metadata?.campaigns?.[campaignId]?.menu || []).sort(
        (a, b) => a.redirect_priority - b.redirect_priority);
    if (sorted.length > 0) {
        const path = getFormHomeUrl(metadata, campaignId, sorted[0].doc_type, sorted[0].doc_subtype);
        return <Navigate to={path} replace={true} />;
    }

    return (
        <div className="bf-landing-static">
            <div className="container">
                <Box sx={{display: {xs: "none", sm: "block"}}} className="title bold">
                    용기있는 걸음, 변화를 위한 시도로<br/>
                    모두가 함께 살아갈 세상을 움직이는 사람들
                </Box>
                <Box sx={{display: {xs: "block", sm: "none"}}} className="title bold">
                    용기있는 걸음,<br/>
                    변화를 위한 시도로<br/>
                    모두가 함께 살아갈 세상을<br/>
                    움직이는 사람들
                </Box>
                <div className="notice">
                    <div className="notice-box">
                        <div className="notice-title">브라이언 펠로우는?</div>
                        <div className="notice-content">
                            <div>
                                브라이언 펠로우는 가치있는 활동에 온전히 몰입할 수 있도록 더 나은 세상을 만드는 혁신가들을 지혜롭게 돕는 프로그램입니다. <br/>
                                혁신가들의 여정이 지치지 않고 대담한 도전과 새로운 시도를 꿈꿀 수 있도록 시드머니, 네트워크, 확성기 등의 지원과 전문가를 연결한 지지기반을 제공합니다.
                            </div>
                            <div style={{margin: "10px 0"}}>
                                <a className="notice-link" href="https://brianimpact.org/program/35/">브라이언 펠로우 더 알아보기
                                    ></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bf_logo"/>
            </div>
        </div>
    );
}

export default CampaignHome;
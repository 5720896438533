import React from "react";
import {Checkbox, FormControlLabel,} from "@mui/material";
import {useController} from "react-hook-form";


function MCheckbox(props) {
    const {name, rules, control, disabled, label,
        size = "medium",
    } = props;
    const {
        field: { value, onChange },
    } = useController({
        name,
        rules,
        control,
    });

    return (
        <FormControlLabel
            name={name}
            label={label}
            control={
                <Checkbox className="chkbox" size={size} checked={value} disabled={disabled}
                    onChange={onChange}
                />
            }
        />
    );
}

export default MCheckbox;
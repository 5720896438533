import React from 'react';
import BFRecommendGuide from "../components/BFRecommendGuide";
import BFRecommendList from "../components/BFRecommendList";
import {Navigate, useParams} from "react-router-dom";
import BFApplicationGuide from "../components/BFApplicationGuide";
import BFApplicationList from "../components/BFApplicationList";
import BFDiggingNoteGuide from "../components/BFDiggingNoteGuide";
import BFDiggingNoteList from "../components/BFDiggingNoteList";

function GenericFormHome(props) {
    const {campaignId, formType} = useParams();
    const childProps = {campaignId, ...props};
    if (formType === 'nomination') {
          return (
            <div className="bf-document-home">
                <BFRecommendGuide {...childProps} />
                <BFRecommendList {...childProps} />
            </div>
          );
    } else if (formType === 'application') {
        return (
            <div className="bf-document-home">
                <BFApplicationGuide {...childProps} />
                <BFApplicationList {...childProps} />
            </div>
        );
    } else if (formType === 'digging_note') {
        return (
            <div className="bf-document-home">
                <BFDiggingNoteGuide {...childProps} />
                <BFDiggingNoteList {...childProps} />
            </div>
        );
    } else {
        return <Navigate to={'/error/404'} replace={true} />
    }
}

export default GenericFormHome;
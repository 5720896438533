// https://tech.osci.kr/react-hook-form-with-mui/
import React, {useEffect} from "react";
import {Button, Stack, TextField} from "@mui/material";
import {useTimer} from 'react-timer-hook';

function TimerTextField({expiryTimestamp, onExpire, onOk, ...props}) {

    //const expiryTimestamp = dayjs().add(5, 'minute').toDate();
    //const expiryTimestamp = dayjs().add(10, 'seconds').toDate();
    const {seconds, minutes, restart} = useTimer({
        expiryTimestamp, onExpire: onExpire, autoStart: true});

    useEffect(() => {
        restart(expiryTimestamp, true);
    }, [expiryTimestamp]);
    const [value, setValue] = React.useState('');

    const timerText = `남은 시간: ${minutes}:${seconds}`;
    return (
        <Stack direction="row" spacing={1}>
            <TextField
                {...props}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                helperText={timerText}
            />
            <Button className="btn" variant="hc_contained_navy" style={{width: 100}}
                onClick={() => onOk(value)}>확인</Button>
        </Stack>
    );
}

export default TimerTextField;
import Slider from '@mui/material/Slider';
import {styled} from '@mui/material/styles';

const HCStyledSlider = styled(Slider)({
    color: '#1e2d37',
    height: 8,
    '& .Mui-disabled': {
        color: '#1e2d37',
    },
    '& .MuiSlider-track': {
        color: '#1e2d37',
    },
    '& .MuiSlider-rail': {
        opacity: 1,
        backgroundColor: '#d9d9d9',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#ffffff',
        height: 6,
        width: 6,
        borderRadius: '50%',
        '&.MuiSlider-markActive': {opacity: 1,},
    },
    '& .MuiSlider-valueLabel': {
        fontWeight: 500,
        fontSize: "0.8125rem",
        backgroundColor: '#1e2d37',
    },
    '& .MuiSlider-markLabel': {
        top: 36,
        fontWeight: 500,
        fontSize: "0.8125rem",
        transform: 'translateX(-80%)',
        '&[data-index="0"]': {transform: 'translateX(-20%)'}
    },
    '& .MuiSlider-disabled': {
        color: '#d9d9d9',
    }
})

export default HCStyledSlider;
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    OutlinedInput,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {isValidUrl} from "../../../util/misc";
import StyledTextArea from "../../common/StyleTextAreaAutoSize";

function convertFieldToDialog(source, targetObj, key, fieldType) {
    switch (fieldType) {
        case 'date':
            targetObj[key] = dayjs(source, 'YYYY.MM.DD');
            break;
        case 'date_yyyymm':
            targetObj[key] = dayjs(source, 'YYYY.MM');
            break;
        case 'date_period_yyyymm':
            const [from, to, current] = source.split(',');
            targetObj[key] = {
                from: dayjs(from, 'YYYY.MM'),
                to: dayjs(to, 'YYYY.MM'),
                current: current === 'Y'
            }
            //console.log('convertFieldToDialog', source, targetObj[key], from, to);
            break;
        default:
            targetObj[key] = source === undefined ? '' : source;
    }
}

function convertFieldToStore(source, targetObj, key, fieldType) {
    switch (fieldType) {
        case 'date':
            targetObj[key] = source.format('YYYY.MM.DD');
            break;
        case 'date_yyyymm':
            targetObj[key] = source.format('YYYY.MM');
            break;
        case 'date_period_yyyymm':
            const to = source.current ? '' : source.to.format('YYYY.MM');
            targetObj[key] = `${source.from.format('YYYY.MM')},${to},${source.current ? 'Y' : 'N'}`;
            break;
        default:
            targetObj[key] = source;
    }
}

function verifyField(source, key, fieldType) {
    switch (fieldType) {
        case 'date':
            return dayjs(source, 'YYYY.MM.DD').isValid() ? null : '날짜 형식이 올바르지 않습니다.';
        case 'date_yyyymm':
            return dayjs(source, 'YYYY.MM').isValid() ? null : '날짜 형식이 올바르지 않습니다.';
        case 'date_period_yyyymm':
            const [from, to, current] = source.split(',');
            const fromDayjs = dayjs(from, 'YYYY.MM'), toDayjs = dayjs(to, 'YYYY.MM');
            return dayjs(from, 'YYYY.MM').isValid() && (current === 'Y' || dayjs(to, 'YYYY.MM').isValid())
                && (!toDayjs.isBefore(fromDayjs)) ? null : '입력 값에 오류가 있습니다.';
        case 'url':
            return isValidUrl(source) ? null : 'URL 형식이 올바르지 않습니다.("http://" 혹은 "https://"를 포함한 전체 주소를 적어주세요)';
        default:
            return null;
    }
}


function HCTableEditDialog({item, itemMeta, itemDefaultValue, onEditSave, onCancel, predefinedColumns, ...props}) {
    const columns = predefinedColumns || itemMeta?.options?.columns || [];
    const [itemValue, setItemValue] = React.useState(itemDefaultValue);
    const [itemValueError, setItemValueError] = React.useState({});
    // console.log('[HCTableEditDialog]', item, itemValue, itemMeta);

    useEffect(() => {
        const newItemValue = {};
        const newError = {}
        columns.forEach((column) => {
            const {field, type} = column;
            convertFieldToDialog(item?.[field] || itemDefaultValue[field], newItemValue, field, type);
            newError[field] = false;
        });
        newItemValue['_rowIndex'] = item?._rowIndex;
        setItemValue(newItemValue);
        setItemValueError(newError);
        //console.log('[HCTableEditDialog]', newItemValue);
    }, [item]);

    function onSubmit(data) {
        const newStoreValue = {};
        const newError = {};
        columns.forEach((column) => {
            const {field, type} = column;
            convertFieldToStore(itemValue[field], newStoreValue, field, type);
            newError[field] = verifyField(newStoreValue[field], field, type);
        });
        if (Object.values(newError).some(o => o)) {
            setItemValueError(newError);
        } else {
            newStoreValue['_rowIndex'] = itemValue._rowIndex;
            onEditSave(newStoreValue);
        }
    }

    function setField(newValue, secondKey, field, type) {
        if (secondKey) {
            setItemValue({...itemValue, [field]: {...itemValue[field], [secondKey]: newValue}});
        } else {
            setItemValue({...itemValue, [field]: newValue});
        }
    }


    function getField(column) {
        const {type, title, desc, field, defaultValue} = column;
        switch (column.type) {
            case 'string':
            case 'url':
            case 'defined':
                return <OutlinedInput
                    fullWidth
                    value={itemValue[field]}
                    onChange={(e) => setField(e.target.value, null, field, type)}
                    name={field}
                    disabled={column.type === 'defined'}
                ></OutlinedInput>
            case 'text':
                return <StyledTextArea
                    value={itemValue[field]}
                    onChange={(e) => setField(e.target.value, null, field, type)}
                    minRows={2}
                    maxRows={5}
                    disabled={column.type === 'defined'}
                ></StyledTextArea>
            case 'date':
                return <DatePicker value={itemValue[field]} format="YYYY. MM. DD"
                    onChange={(newValue) => setField(newValue, null, field, type)} />
            case 'date_yyyymm':
                return <DatePicker views={['month', 'year']} format="YYYY. MM." value={itemValue[field]}
                    onChange={(newValue) => setField(newValue, null, field, type)} />
            case 'date_period_yyyymm':
                return <Stack direction="row" spacing={1} alignItems="center">
                    <DatePicker views={['month', 'year']} format="YYYY. MM."
                                value={itemValue[field]['from']}
                                onChange={(newValue) => setField(newValue, 'from', field, type)} />
                    <Typography>~</Typography>
                    {!itemValue[field]['current'] && <DatePicker views={['month', 'year']} format="YYYY. MM."
                                value={itemValue[field]['to']} minDate={itemValue[field]['from']}
                                onChange={(newValue) => setField(newValue, 'to', field, type)} />}
                    <FormControlLabel sx={{width:120}} label="현재" control={
                        <Checkbox checked={itemValue[field]['current']} size="small"
                                  onChange={(e) => setField(e.target.checked, 'current', field, type)} />} />
                </Stack>
        }

    }

    if (!item) return null;

    return (
        <Dialog fullWidth className="hc-table-edit" {...props}>
            <DialogTitle>{itemMeta.title}</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                {columns.map((col, idx) => {
                    if (col.hidden) return null;
                    return <div key={idx} className="col-container">
                        <div className="col-title">{col.title}</div>
                        {col.desc && <div className="col-desc">{col.desc}</div>}
                        {getField(col)}
                        {itemValueError[col.field] && <div><Typography variant="caption" color="red">{itemValueError[col.field]}</Typography></div>}
                    </div>
                })}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="hc_contained_navy" onClick={onCancel}>취소</Button>
                <Button type="submit" variant="hc_contained_navy" onClick={onSubmit}>저장</Button>
            </DialogActions>
        </Dialog>
    )
}

export default HCTableEditDialog
import {Grid} from '@mui/material';
import {useState} from "react";
import StyledTextArea from "../common/StyleTextAreaAutoSize";
import NoDataDiv from "./sub/NoDataDiv";

function HCTextArea({meta, initContents, funcWriteChangeIntoStore, readOnly, dependencyEffect}) {
    //console.log('[HCTextArea]', meta?.key, meta, initContents);
    const {view_grid_width, view_init_row, view_max_row, placeholder} = meta.options||{};
    const [contents , setContents] = useState(initContents?.value);
    const dataKey = meta.key;
    const disabled = readOnly || dependencyEffect === 'disabled';
    const onChangeFn = (e) => {
        setContents(e.target.value);
        funcWriteChangeIntoStore(dataKey, e.target.value);
    }
    const emptyReadOnly = readOnly && !contents;

    return (
        <Grid container>
            <Grid item xs={12} sm={view_grid_width}>
                {!emptyReadOnly && <StyledTextArea
                    placeholder={placeholder}
                    value={contents}
                    onChange={onChangeFn}
                    minRows={readOnly ? null : view_init_row}
                    maxRows={readOnly ? null : view_max_row}
                    disabled={disabled}
                ></StyledTextArea>}
                {emptyReadOnly && <NoDataDiv />}
            </Grid>
        </Grid>
    )
    // 아래 속성으로 readonly 가능한데 warning 뜸 (readOnly 아니냐며)
    // disabled와 같은 모양으로 보이므로 disabled로 통일
    // readonly={readOnly ? '' : false}
}

export default HCTextArea;
import {API_BASE_URL, build_default_headers, check_api_response} from "./api_common";


const apiFetchApplicationList = async(campaignId, mode) => {
    console.log('[API] fetch application list', campaignId);
    const params = {campaign_id: campaignId, mode: mode}
    const queryString = new URLSearchParams(params).toString();
    const response = await fetch(`${API_BASE_URL}/applications?${queryString}`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json;
}

const apiFetchApplication = async(application_id) => {
    console.log('[API] fetch_application', application_id);
    const response = await fetch(`${API_BASE_URL}/applications/${application_id}`, {
        headers: build_default_headers(),
    })
    const json = await check_api_response(response);
    return json;
}

const apiFetchNewEmptyApplication = async(campaignId, docType, docSubType) => {
    console.log('[API] fetch_new_empty_application', campaignId, docType, docSubType);
    const params = {campaign_id: campaignId, doc_type: docType, doc_subtype: docSubType};
    const response = await fetch(`${API_BASE_URL}/applications/new-empty-doc`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(params),
    });
    const json = await check_api_response(response);
    return json;
}


const apiUpdateApplication = async(params) => {
    const {id, formData} = params;
    console.log('[API] update_application', id);
    const response = await fetch(`${API_BASE_URL}/applications/${id}/update-body`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(formData),
    })
    const json = await check_api_response(response);
    return json;
}

const apiDeleteApplication = async(params) => {
    const {id} = params;
    console.log('[API] delete_application', id);
    const response = await fetch(`${API_BASE_URL}/applications/${id}`, {
        method: 'DELETE',
        headers: build_default_headers(),
    })
    const json = await check_api_response(response);
    return json;
}

const apiSubmitApplication = async(params) => {
    const {id, isSubmit} = params;
    console.log('[API] submit_application', id, isSubmit);
    const response = await fetch(`${API_BASE_URL}/applications/${id}/submit`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({type: isSubmit ? 'submit' : 'withdraw'}),
    })
    const json = await check_api_response(response);
    return json;
}

const apiCreateApplicationWithData = async(params) => {
    const {id, data} = params;
    console.log('[API] apiCreateApplicationWithData');
    const response = await fetch(`${API_BASE_URL}/applications/${id}/create-with-data`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(data),
    })
    const json = await check_api_response(response);
    return json;

}

export {
    apiFetchApplicationList,
    apiFetchApplication,
    apiFetchNewEmptyApplication,
    apiUpdateApplication,
    apiCreateApplicationWithData,
    apiDeleteApplication,
    apiSubmitApplication,
}
import "../../../css/bfLandingStatic.css";
import {Box, Button} from "@mui/material";
import {useSetAtom} from "jotai/index";
import {layoutConfigAtom} from "../../../store/atoms/atom_ui";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import ShareSnsDialog from "../../../components/modal/ShareSnsDialog";
import classNames from "classnames";
import {useAtomValue} from "jotai";
import {metadataAtom} from "../../../store/atoms/atom_config";

function BrianFellow5Teaser() {
    const setLayoutConfig = useSetAtom(layoutConfigAtom);
    const metadata = useAtomValue(metadataAtom);

    const BF_S05_HOME = '/c/brianfellow_005/doc/application';

    const [shareDlgOpen, setShareDlgOpen] = useState(false);

    useEffect(() => {
        setLayoutConfig((prev) => { return {...prev, hideFooter: true}});
    });

    const stage = metadata?.campaigns?.['brianfellow_005']?.stage || 'before';

    const shareTitle = '브라이언 펠로우 5기 후보를 찾습니다';
    const shareDesc = '가치있는 활동에 몰입할 수 있도록 혁신가들을 돕는 재단법인 브라이언임팩트의 지원 프로그램입니다';
    const shareUrl = 'https://apply.brianimpact.org/program/brianfellow';
    const shareImg = 'https://static.brianimpact.org/images/common/brianfellow_og_image_lg.png';
    const shareImgKakaotalk = 'https://static.brianimpact.org/images/common/brianfellow_kakaotalk_share.png';

    const onShare = () => {
        if (navigator.share !== undefined) {
            navigator.share({
                title: shareTitle,
                text: shareDesc,
                url: shareUrl,
            })
        } else {
            setShareDlgOpen(true);
        }
    }

    const dDay = Math.ceil(dayjs("2024-05-02T00:00:00+0900").diff(dayjs(), 'day', true));
    const deadLine = Math.floor(dayjs("2024-05-31T23:59:59+0900").diff(dayjs(), 'day', true));

    return (
        <div className="bf-landing-static">
            <div className="container">
                <Box sx={{display: {xs: "none", sm: "block"}}} className="title bold">
                    용기있는 걸음, 변화를 위한 시도로<br/>
                    모두가 함께 살아갈 세상을 움직이는 사람들<br/><br/>
                    브라이언 펠로우 시즌 5 후보를 찾습니다
                </Box>
                <Box sx={{display: {xs: "block", sm: "none"}}} className="title bold">
                    용기있는 걸음,<br/>
                    변화를 위한 시도로<br/>
                    모두가 함께 살아갈 세상을<br/>
                    움직이는 사람들<br/><br/>
                    브라이언 펠로우<br/>
                    시즌 5 후보를 찾습니다
                </Box>
                <div className="notice">
                    <div className="notice-box">
                        <div className="notice-title">브라이언 펠로우는?</div>
                        <div className="notice-content">
                            <div>
                                브라이언 펠로우는 가치있는 활동에 온전히 몰입할 수 있도록 더 나은 세상을 만드는 혁신가들을 지혜롭게 돕는 프로그램입니다. <br/>
                                혁신가들의 여정이 지치지 않고 대담한 도전과 새로운 시도를 꿈꿀 수 있도록 시드머니, 네트워크, 확성기 등의 지원과 전문가를 연결한 지지기반을 제공합니다.
                            </div>
                            <div style={{margin: "10px 0"}}>
                                <a className="notice-link" href="https://brianimpact.org/program/35/">브라이언 펠로우 더 알아보기
                                    ></a>
                            </div>
                        </div>
                    </div>
                    <div className="notice-box">
                        <div className="notice-title">지원기간</div>
                        <div className="notice-content bold">24. 5. 2(목) ~ 5. 31(금)</div>
                    </div>
                    <div className="notice-box">
                        <div className="notice-title">지원방법</div>
                        <div className="notice-content">위 지원기간 동안 ‘브라이언 펠로우 지원 페이지(현재 사이트)’에 접속하시면, 회원가입 후 지원서를 작성하실 수
                            있습니다.
                        </div>
                    </div>
                    {stage === 'before' && dDay >= 0 && (<div className="notice-box">
                        <div className="notice-title">지원일까지</div>
                        <div className="notice-content bold">{dDay === 0 ? 'D-Day' : `D-${dDay}`}</div>
                    </div>)}
                    {stage === 'applying' && deadLine >= 0 && (<div className="notice-box">
                        <div className="notice-title">지원마감까지</div>
                        <div className={classNames("notice-content", "bold", {"red":deadLine <= 7})}>
                            {deadLine === 0 ? 'D-Day' : `D-${deadLine}`}</div>
                    </div>)}
                    {(stage !== 'before' && stage !== 'applying') && (<div className="notice-box">
                        <div className="notice-title pc"></div>
                        <div className="notice-content bold end">지원 기간이 종료되었습니다.<br/>
                            <span className="desc">지원해주신 분들은 로그인 후 지원현황을 확인하실 수 있습니다.</span>
                        </div>
                    </div>)}
                    {stage === 'applying' && (<div className="notice-box">
                        <div className="notice-title"></div>
                        <div className="notice-content">
                            <Button className="go-apply-btn" variant="hc_contained_navy"
                                    href={BF_S05_HOME}>지원하러 가기</Button>
                            <Button className="go-share-btn" variant="hc_outlined_black"
                                    onClick={onShare}>공유하기</Button>
                        </div>
                    </div>)}
                    <ShareSnsDialog open={shareDlgOpen} onClose={() => setShareDlgOpen(false)}
                                    title={shareTitle} desc={shareDesc} imageUrl={shareImg} url={shareUrl}
                                    buttonTitle="지원하러 가기" imageKakaotalk={shareImgKakaotalk} />
                </div>
                <div className="bf_logo"/>
            </div>
        </div>
    );
}

export default BrianFellow5Teaser;
import {API_BASE_URL, build_default_headers, check_api_response} from "./api_common";

const apiRequestCodeVerification = async (params) => {
    let payload;
    const {request_type} = params;
    if (request_type === 'sms') {
        const phone = '+82' + params.phone_number.replace(/\D/g, '');
        payload = { phone_number: phone, check_scheme: params.check_scheme, name: params.name };
    } else {
        payload = { email: params.email, check_scheme: params.check_scheme };
    }
    const response = await fetch(`${API_BASE_URL}/misc/${request_type}-verification/request`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(payload),
    });
    const json = await check_api_response(response);
    return json;
}

const apiVerifyCodeToken = async (params) => {
    const {request_type, code_token, code} = params;
    // request_type = sms or email
    const response = await fetch(`${API_BASE_URL}/misc/${request_type}-verification/verify`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({
            code_token: code_token,
            code: code
        }),
    });
    const json = await check_api_response(response);
    return json;
}


export {
    apiRequestCodeVerification,
    apiVerifyCodeToken,
}
import React, {useRef} from 'react';
import {Box} from '@mui/material';
import FormItemView from "./FormItemView";
import FormItemValidatorView from "./FormItemValidatorView";
import {useAtomValue} from "jotai";
import {metadataAtom} from "../store/atoms/atom_config";
import classNames from "classnames";
import FormAnnotation from "./FormAnnotation";

const buildDescElement = function(descMeta, listClass, itemClass) {
    if (! descMeta) return null;
    const {type, content} = descMeta;
    switch (type) {
        case 'ul':
            return <ul className={classNames(listClass)}>{(content||[]).map((str, idx) => (
                <li key={idx} className={classNames(itemClass)}>{str}</li>))}</ul>;
        case 'ol':
            return <ol className={classNames(listClass)}>{(content||[]).map((str, idx) => (
                <li key={idx} className={classNames(itemClass)}>{str}</li>))}</ol>;
        case 'plain':
            return <div className={classNames(listClass)}>{(content||[]).map((str, idx) => (
                <p key={idx} className={classNames(itemClass)}>{str}</p>))}</div>;
        default:
            return null;
    }
}

const buildHeaderDescElement = function(descMeta) {
    return buildDescElement(descMeta, 'header-desc');
}

const buildSectionDescElement = function(descMeta) {
    return buildDescElement(descMeta, 'section-desc');
}

function FormBodyView({campaignId, formType, formMeta, formAtoms, funcWriteChangeIntoStore, isOwner, isEmbed}) {

    const metadata = useAtomValue(metadataAtom);
    const formData = useAtomValue(formAtoms.dataAtom);
    const formDependency = useAtomValue(formAtoms.dependencyAtom);

    const storeCache = useRef({});

    const funcWrapper = (key, value, format) => {
        storeCache.current[key] = value;
        funcWriteChangeIntoStore(key, value, format);
    }

    const getInit = (key) => {
        const initContent = formData.form_data[key];
        //console.log('### getInit:', key, initContent, storeCache.current[key]);
        if (storeCache.current[key]) {
            return {...initContent, ["value"]: storeCache.current[key]};
        } else
            return initContent;
    }

    const getDependencyEffect = (itemMeta) => {
        //console.log('### check dependency:', itemMeta, formDependency);
        const dep = itemMeta.options?.dependency;
        return dep?.item_key && formDependency[dep?.item_key]
            ? dep?.effect : null;
    }

    if (!metadata || !formData) return null;
    console.log("[FormBodyView]", metadata, formData);
    const readOnly = !isOwner || formData['status'] !== 'draft';

    return (
        <Box className='form-body'>
            {<FormAnnotation campaignId={campaignId} docId={formData.doc_id} docType={formData.doc_type} forceReadOnly={isEmbed}/>}
            {formMeta.header?.desc && buildHeaderDescElement(formMeta.header.desc)}
            {formMeta.sections.map((section, idx) => (
                <div key={`section-${idx}`} className='form-section'>
                    {!formMeta.options?.hide_section_info && section.section_type!=='hidden' && (<>
                        <h3 className='section-title'>{section.title}</h3>
                        {section.desc && buildSectionDescElement(section.desc)}
                    </>)}
                    {section.items.map(item => (
                        <React.Fragment key={item.key}>
                            <FormItemView
                                meta={item}
                                initContents={getInit(item.key)}
                                funcWriteChangeIntoStore={funcWrapper}
                                readOnly={readOnly}
                                dependencyEffect={getDependencyEffect(item)}
                                formMeta={{formType, campaignId}}
                            />
                            <FormItemValidatorView itemKey={item.key} validatorAtom={formAtoms.validatorAtom} />
                        </React.Fragment>
                    ))}
                </div>
            ))}
        </Box>
    )

}

export default FormBodyView;
import DataGridAg from "./common/datagrid/DataGridAg";
import {apiDeleteFormAnnotation, apiFetchFormAnnotations, apiUpsertFormAnnotation} from "../store/api_admin";
import {useAtomValue} from "jotai/index";
import {metadataAtom, userInfoAtom} from "../store/atoms/atom_config";
import {isAdmin, isBootOrAdmin} from "../service/userService";
import {utcDayjs} from "../util/misc";
import {Paper} from "@mui/material";
import {useEffect, useState} from "react";

const columnDefs = [{
    field: 'annotation_type', title: '타입', width: 90, type: 'select',
    options: {
        selectOptions: [{label: '제외', value: 'excluded'}, {label: '관리자 메모', value: 'admin_note'}],
        mandatory: true,
    },
}, {
    field: 'note', title: 'Note', flex: 1, type: 'text',
}, {
    field: 'updated_at', title: '수정일', width: 120, type: 'defined',
    valueFormatter: (params) => utcDayjs(params.value).format('YY-MM-DD HH:mm'),
    options: {hidden: (v) => !v},
}];

function FormAnnotation({campaignId, docType, docId, forceReadOnly}) {
    const metadata = useAtomValue(metadataAtom);
    const userInfo = useAtomValue(userInfoAtom);
    const [showAnnotation, setShowAnnotation] = useState(false);
    const [editable, setEditable] = useState(!forceReadOnly);
    const defaultValues = {'annotation_type': 'excluded', 'note': ''};
    useEffect(() => {
        setEditable(!forceReadOnly && isAdmin(userInfo, campaignId));
    }, [userInfo]);

    if (!userInfo) return null;
    if (! isBootOrAdmin(userInfo, metadata) || docId === 'new-empty-doc') return null;
    
    return (
        <Paper className='form-annotation' variant="outlined" style={{display:showAnnotation ? "block" : "none"}}>
            <DataGridAg
                queryKey={['form-annotation', campaignId, docId]}
                queryFn={() => apiFetchFormAnnotations(campaignId, docId)}
                queryEnabled={!!metadata}
                upsertMutateFn={apiUpsertFormAnnotation}
                deleteMutateFn={apiDeleteFormAnnotation}
                columnDefs={columnDefs}
                config={{
                    defaultValues: defaultValues,
                    addButtonTitle: '담당자 어노테이션 추가',
                    getRowId: (params) => params.data.annotation_id,
                    notEditable: !editable,
                    gridSize: 'small',
                    upsertMutateParams: {campaignId: campaignId, docId: docId, docType: docType},
                    deleteMutateParams: {campaignId: campaignId, docId: docId, docType: docType},
                    afterFetched: (data) => {
                        setShowAnnotation(editable || data.length > 0);
                    }
                }}
            />
        </Paper>
    );
}

export default FormAnnotation;
import {check_api_response, build_default_headers} from "./api_common";
import {API_BASE_URL} from "./api_common";


const apiFetchNominationList = async(campaignId, mode) => {
    console.log('[API] fetch nomination list', campaignId, mode);
    const params = {campaign_id: campaignId, mode: mode}
    const queryString = new URLSearchParams(params).toString();
    const response = await fetch(`${API_BASE_URL}/nominations?${queryString}`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json;

}

const apiFetchNomination = async(nomination_id) => {
    console.log('[API] fetch_nomination', nomination_id);
    const response = await fetch(`${API_BASE_URL}/nominations/${nomination_id}`, {
        headers: build_default_headers(),
    })
    const json = await check_api_response(response);
    return json;
}

const apiFetchNewEmptyNomination = async(campaignId, docType, docSubType) => {
    console.log('[API] fetch_new_empty_nomination', campaignId, docType, docSubType);
    const params = {campaign_id: campaignId, doc_type: docType, doc_subtype: docSubType};
    const response = await fetch(`${API_BASE_URL}/nominations/new-empty-doc`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(params),
    });
    const json = await check_api_response(response);
    return json;
}


const apiUpdateNomination = async(params) => {
    const {id, formData} = params;
    console.log('[API] update_nomination', id);
    const response = await fetch(`${API_BASE_URL}/nominations/${id}/update-body`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(formData),
    })
    const json = await check_api_response(response);
    return json;
}

const apiDeleteNomination = async(params) => {
    const {id} = params;
    console.log('[API] delete_nomination', id);
    const response = await fetch(`${API_BASE_URL}/nominations/${id}`, {
        method: 'DELETE',
        headers: build_default_headers(),
    })
    const json = await check_api_response(response);
    return json;
}

const apiSubmitNomination = async(params) => {
    const {id, isSubmit} = params;
    console.log('[API] submit_nomination', id, isSubmit);
    const response = await fetch(`${API_BASE_URL}/nominations/${id}/submit`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({type: isSubmit ? 'submit' : 'withdraw'}),
    })
    const json = await check_api_response(response);
    return json;
}

const apiCreateNominationWithData = async(params) => {
    const {id, data} = params;
    console.log('[API] apiCreateNominationWithData');
    const response = await fetch(`${API_BASE_URL}/nominations/${id}/create-with-data`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(data),
    })
    const json = await check_api_response(response);
    return json;

}

export {
    apiFetchNominationList,
    apiFetchNomination,
    apiFetchNewEmptyNomination,
    apiUpdateNomination,
    apiCreateNominationWithData,
    apiDeleteNomination,
    apiSubmitNomination,
}
import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {feedbackSnackbarAtom} from "../../store/atoms/atom_config";
import {useAtom} from "jotai";
import * as Sentry from '@sentry/react';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function FeedbackSnackbar() {
    const [feedbackSnackbar, setFeedbackSnackbar] = useAtom(feedbackSnackbarAtom);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setFeedbackSnackbar(['', 'info']);
    };
    const isError = feedbackSnackbar[1] === 'error';
    const color = isError ? "error" : "navy";
    const severity = isError ? "error" : "info";

    if (isError && !feedbackSnackbar[2]) Sentry.captureMessage(feedbackSnackbar[0]);

    return (
        <Snackbar open={!!feedbackSnackbar[0]} autoHideDuration={isError ? null : 3000} onClose={handleClose}>
            <Alert onClose={handleClose} sx={{ width: '100%' }} color={color} severity={severity}>
                {feedbackSnackbar[0]}
            </Alert>
        </Snackbar>
    );
}

export default FeedbackSnackbar;
import {ADMIN_API_BASE_URL, build_default_headers, check_api_response} from "./api_common";

const apiFetchFormAnnotations = async(campaignId, docId) => {
    console.log('[API] fetch form annotation', campaignId, docId);
    const queryString = new URLSearchParams({campaign_id: campaignId}).toString();
    const response = await fetch(`${ADMIN_API_BASE_URL}/forms/${docId}/annotations?${queryString}`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json.annotations?.annotations || [];
}

const apiUpsertFormAnnotation = async(params) => {
    const {annotation_id, annotation_type, note, campaignId: campaign_id, docType: doc_type, docId: doc_id} = params;
    console.log('[API] upsert form annotation', doc_type, doc_id, annotation_id);
    const response = await fetch(`${ADMIN_API_BASE_URL}/forms/${doc_id}/upsert-annotation`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({campaign_id, doc_id, doc_type, annotation_id, annotation_type, note}),
    });
    const json = await check_api_response(response);
    return json;
}

const apiDeleteFormAnnotation = async(params) => {
    const {annotation_id, campaignId: campaign_id, docType: doc_type, docId: doc_id} = params;
    console.log('[API] delete form annotation', annotation_id);
    const response = await fetch(`${ADMIN_API_BASE_URL}/forms/${doc_id}/delete-annotation`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({campaign_id, doc_id, doc_type, annotation_id})
    });
    const json = await check_api_response(response);
    return json;
}

export {
    apiFetchFormAnnotations,
    apiUpsertFormAnnotation,
    apiDeleteFormAnnotation,
}
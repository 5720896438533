import {createTheme} from "@mui/material/styles";


// https://m2.material.io/design/color/the-color-system.html
// https://mui.com/material-ui/customization/color/

// https://m2.material.io/inline-tools/color/
const hcPalette = {
    navy:{
        900:'#1e2d37', 800:'#2f424e', 700:'#3d5463', 600:'#4c687a', 500:'#58768a', 400:'#718a9c', 300:'#899faf',
        200:'#a9bac7', 100:'#c9d6df', 50:'#e8eff5'},
    orange:{
        900:'#ff7100', 800:'#ff9100', 700:'#ffa101', 600:'#ffb402', 500:'#ffc20a', 400:'#ffcb29', 300:'#ffd650',
        200:'#ffe183', 100:'#ffecb3', 50:'#fff8e1'},
}

const hcColors = {
    navy: hcPalette.navy[900],
    orange: hcPalette.orange[800],
    gray: '#a0a0a0',
    lightGray: '#dfdfdf',
    lighterGray: '#f7f7f7',
    black: '#000000',
    white: '#ffffff',
    red: '#ff0000',
}

const honeycombTheme = createTheme({
    palette: {
        navy: {main: hcPalette.navy[900], dark:'#000000', light: hcPalette.navy[300], contrastText: "#fff"},
        orange: {main: '#ff9100', dark: '#aa7100', contrastText: "#fff"},
        gray: {main: '#a0a0a0', dark: '#acadb4', contrastText: "#fff"},
        white: {main: '#fff', dark: '#acadb4', contrastText: "#000"},
        black: {main: '#000'},
        light_grey: {main: '#dfdfdf'},
        lighter_grey: {main: '#f7f7f7'},
    },
    typography: {
        fontFamily: "'Noto Sans KR', 'sans-serif'"
    },
    components: {
        MuiButton: {
            variants: [{
                props: {variant: 'hc_contained_navy'},
                style: {
                    color: hcColors.white,
                    backgroundColor: hcColors.navy,
                    borderRadius: 0,
                    '&:hover': {
                        backgroundColor: hcPalette.navy[700],
                    },
                    '&:disabled': {
                        backgroundColor: hcColors.gray,
                        color: hcColors.white,
                    }
                }
            }, {
                props: {variant: 'hc_outlined_black'},
                style: {
                    color: hcColors.black,
                    backgroundColor: hcColors.white,
                    borderRadius: 0,
                    fontWeight: 700,
                    border: "2px solid #000",
                }
            }]
        }
    },
});

export {
    honeycombTheme,
    hcPalette,
    hcColors,
};
import dayjs from "dayjs";
const utcDayjs = (utcStr) => dayjs(utcStr, {utc: true}).local();

const URL_PATTERN = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/
const EMAIL_PATTERN = /\S+@\S+\.\S+/;
const MOBILE_PHONE_PATTERN = /^(?:\d{3}-\d{3,4}-\d{4}|\d{10,11})$/;

const isValidEmail = (str) => EMAIL_PATTERN.test(str);
const isValidUrl = (str) => URL_PATTERN.test(str);
const isValidMobilePhone = (str) => MOBILE_PHONE_PATTERN.test(str);

const arrayToObj = (arr, key) => {
    const obj = {};
    arr.forEach((item) => {
        obj[item[key]] = item;
    });
    return obj;
}

const isEmptyArray = (arr) => {
    return arr === undefined || arr === null || arr.length === 0;
}

const divideArray = (array, m) => {
    const dividedArrays = [];
    const length = array.length;
    const chunkSize = Math.ceil(length / m);

    for (let i = 0; i < length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        dividedArrays.push(chunk);
    }

    return dividedArrays;
};

const fileDownloadUsingDom = function (link) {
    const element = document.createElement('a');
    element.setAttribute('href', link);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

const buildErrorMessage = function(error, messages) {
    console.log(`[API ERROR][${error.cause?.err_code}] ${error.message}`)
    if (error.cause) {
        switch (error.cause.err_code) {
            case 400:
                return messages?.[400] || '잘못된 요청입니다.';
            case 401:
                return messages?.[401] || '인증 정보가 잘못되었습니다.';
            case 403:
                return messages?.[403] || '접근 권한이 없습니다.';
            case 404:
                return messages?.[404] || '요청하신 데이터를 찾을 수 없습니다.';
            case 409:
                return messages?.[409] || '이미 존재하는 데이터입니다.';
            case 500:
                return messages?.[500] || '서버에 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.';
            default:
                return '서버에 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.';
        }
    } else {
        return error.message;
    }
}

const moveURL = function (url) {
    window.location.href = url;
}

export {
    utcDayjs,
    arrayToObj,
    isEmptyArray,
    divideArray,
    fileDownloadUsingDom,
    isValidUrl,
    isValidEmail,
    isValidMobilePhone,
    buildErrorMessage,
    moveURL,
}
import React from 'react';
import FormHeader from "../components/FormHeader";
import {useParams} from "react-router-dom";
import {getFormAtoms, metadataAtom,} from "../store/atoms/atom_config";
import {useAtomValue, useSetAtom} from "jotai";
import {useQuery} from "@tanstack/react-query";
import FormBodyView from "../components/FormBodyView";
import {selectFormDocumentApi, setFormDocumentData, writeChangeToStore} from "../service/formService";
import {getFormFormatId} from "../service/metaService";
import FormStepBodyView from "../components/FormStepBodyView";
import FormStepNavi from "../components/FormStepNavi";
import {isEmptyArray} from "../util/misc";

function GenericFormView(props) {
    const {campaignId, formType, formSubType, formId} = useParams();
    console.log('##### [GenericFormView]', formType, formSubType, formId);

    const metadata = useAtomValue(metadataAtom);
    const {formFetchApi, newDocApi, updateFormBodyApi, createFormApi, submitFormApi } = selectFormDocumentApi(formType);
    const formAtoms = getFormAtoms(formType, formSubType);
    const setData= useSetAtom(formAtoms.dataAtom);
    const setStore = useSetAtom(formAtoms.storeAtom);
    const setNeedSave = useSetAtom(formAtoms.needSaveAtom);
    const setFormStepInfo = useSetAtom(formAtoms.stepInfoAtom);
    const setFormDependency = useSetAtom(formAtoms.dependencyAtom);
    const queryKey = [formType, formId];
    const formMetaId = getFormFormatId(metadata, campaignId, formType, formSubType);
    const {data: dataFormDoc, isSuccess: dataFetched}= useQuery(
        queryKey,
        () => formId === 'new-empty-doc' ?
            newDocApi(campaignId, formType, formSubType) :
            formFetchApi(formId),
        {
            enabled: !!(metadata?.connected),
            cacheTime: 0,
            onSuccess: (data) => {
                console.log('### form-doc fetched', formType, formId, data, metadata);
                data[formType]['extra'] = data['extra'];
                setFormDocumentData(data, formType, data?.form_meta_dict[formMetaId],
                    setData, setStore, setNeedSave, setFormStepInfo, setFormDependency);
            }
        }
    );

    if (!dataFetched) return null;
    const formMeta = dataFormDoc?.form_meta_dict[formMetaId];
    console.log('[GenericFormView]', formType, formSubType, formId, formMeta, dataFetched, dataFormDoc);

    const funcWriteChangeIntoStore = (key, value, format) => {
        writeChangeToStore(key, value, format, formId, formMeta, setStore, setNeedSave, setFormDependency);
    }

    const isOwner = dataFormDoc.auth_info?.is_owner === true || formId === 'new-empty-doc';
    const useStepView = !isEmptyArray(formMeta.steps) && isOwner;
    console.log('[GenericFormView] useStepView', useStepView);

    return (
        <>
            <FormHeader
                campaignId={campaignId}
                formMeta={formMeta}
                formType={formType}
                formSubType={formSubType}
                formId={formId}
                queryKey={queryKey}
                dataAtom={formAtoms.dataAtom}
                storeAtom={formAtoms.storeAtom}
                stepInfoAtom={formAtoms.stepInfoAtom}
                validatorAtom={formAtoms.validatorAtom}
                needSaveAtom={formAtoms.needSaveAtom}
                updateFormBodyApi={updateFormBodyApi}
                createFormApi={createFormApi}
                submitFormApi={submitFormApi}
                isOwner={isOwner}
            />
            {useStepView &&
                <><FormStepBodyView
                    campaignId={campaignId}
                    formMeta={formMeta}
                    formType={formType}
                    formAtoms={formAtoms}
                    funcWriteChangeIntoStore={funcWriteChangeIntoStore}
                    isOwner={isOwner}
                    isEmbed={false}
                />
                <FormStepNavi formAtoms={formAtoms} /></>
            }
            {!useStepView &&
                <FormBodyView
                    campaignId={campaignId}
                    formMeta={formMeta}
                    formType={formType}
                    formAtoms={formAtoms}
                    funcWriteChangeIntoStore={funcWriteChangeIntoStore}
                    isOwner={isOwner}
                    isEmbed={false}
                />
            }
        </>
    );
}

export default GenericFormView;
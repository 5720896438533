import React from 'react';
import {OutlinedInput} from '@mui/material';
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import NoDataDiv from "./sub/NoDataDiv";

function HCDatePicker({meta, initContents, funcWriteChangeIntoStore, readOnly, dependencyEffect}) {
    //console.log('[HCDatePicker]', meta?.key, meta, initContents);
    const [value, setValue] = React.useState(dayjs(initContents?.value, 'YYYY.MM.DD'));
    const dataKey = meta.key;
    const disabled = dependencyEffect === 'disabled';

    const onChangeFn = (newValue) => {
        //console.log("onChangeFn", newValue);
        setValue(newValue);
        if (newValue !== null) {
            const formatted = dayjs(newValue).format('YYYY.MM.DD');
            funcWriteChangeIntoStore(dataKey, formatted);
        } else {
            funcWriteChangeIntoStore(dataKey, null);
        }
    }

    if (readOnly) {
        return value.isValid() ?
            <OutlinedInput
                fullWidth
                value={value.format("YYYY. MM. DD")}
                readOnly={readOnly}
                disabled={disabled}
            ></OutlinedInput>
        : <NoDataDiv message={initContents.value === null ? null : "잘못된 날짜 형식입니다."} />;
    } else {
        return (
            <DatePicker value={value} onChange={onChangeFn} format="YYYY. MM. DD" disabled={disabled}/>
        )
    }
}

export default HCDatePicker;
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack} from "@mui/material";
import React from "react";

import {useForm} from "react-hook-form";
import MInputText from "../common/MInputText";
import {apiFindEmail} from "../../store/api_user";
import dayjs from "dayjs";
import TimerTextField from "../common/TimerTextField";
import {useMutation} from "@tanstack/react-query";
import {apiRequestCodeVerification, apiVerifyCodeToken} from "../../store/api_misc";
import {useSetAtom} from "jotai";
import {feedbackSnackbarAtom} from "../../store/atoms/atom_config";
import {isValidMobilePhone} from "../../util/misc";

function FindEmailDialog(props) {
    const { watch, control, reset} = useForm({
        defaultValues: {name: "",mobile_phone: ""},
        shouldFocusError: true,
    });

    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    // before - inprogress - verified
    const [mobileVerificationState, setMobileVerificationState] = React.useState('before');
    const [mobileExpiryTime, setMobileExpiryTime] = React.useState(null);
    const [mobileVerificationToken, setMobileVerificationToken] = React.useState(null);
    const [emailInfo, setEmailInfo] = React.useState(null);

    const requestCodeVerificationMutate = useMutation({
        mutationFn: apiRequestCodeVerification,
        onSuccess: (data, variables, context) => {
            const {code_token} = data;
            console.log('[mutation] requestCodeVerificationMutate', data);
            setMobileVerificationState('inprogress');
            setMobileVerificationToken(code_token);
        },
        onError: (error, variables, context) => {
            console.log('onError', error, variables, context)
            setFeedbackSnackbar(['인증 코드 처리 중 문제가 발생했습니다.', 'error']);
        }
    });
    const verifyCodeTokenMutate = useMutation({
        mutationFn: apiVerifyCodeToken,
        onSuccess: (data, variables, context) => {
            console.log('[mutation] apiRequestSmsVerification', data);
            const {verified_token} = data;
            const {name} = variables;
            setMobileVerificationToken(null);
            setMobileVerificationState('verified');
            findEmailMutate.mutate({token: verified_token, name: name})
        },
        onError: (error, variables, context) => {
            console.log('onError', error, variables, context)
            setFeedbackSnackbar(['인증 코드 처리 중 문제가 발생했습니다.', 'error']);
        }
    });
    const findEmailMutate = useMutation({
        mutationFn: apiFindEmail,
        onSuccess: (data, variables, context) => {
            console.log('[mutation] apiFindEmail', data);
            setEmailInfo(data.emails);
        },
        onError: (error, variables, context) => {
            console.log('onError', error, error.cause, variables, context)
            if (error.cause?.err_code === 404) {
                setFeedbackSnackbar(["이름과 전화번호가 일치하는 사용자를 찾을 수 없습니다.", "error"]);
            } else {
                setFeedbackSnackbar(['이메일 찾기 중 문제가 발생했습니다.', 'error']);
            }
            setMobileVerificationToken(null);
            setMobileVerificationState('before');
        }
    });

    const onClose = () => {
        setMobileVerificationToken(null);
        setMobileVerificationState('before');
        setEmailInfo(null);
        reset();
        props.onClose();
    }

    const expireVerificationTime = () => {
        alert('인증시간이 만료되었습니다. 다시 인증해주세요.');
    }

    const authCodeBtnText = (state) => {
        if (state === 'verified') return '인증 완료';
        else if (state === 'inprogress') return '재발송';
        else return '인증번호 발송';
    }

    const mobileWatch = watch("mobile_phone", "");
    const isValidMobile = isValidMobilePhone(mobileWatch);
    const nameWatch = watch("name");

    return (
    <Dialog fullWidth className="bf-modal" {...props}>
        <DialogTitle className="title">이메일 찾기</DialogTitle>
        <DialogContent className="container">
            <DialogContentText>
                가입하실 때 입력한 이름과 휴대폰 번호를 입력해 주세요.
            </DialogContentText>
            <Stack className="bf-modal-content">
                <Grid container className="form">
                    <Grid item xs={12} className="form-item">
                        <MInputText fullWidth
                                    name="name" control={control} id="name" type="text" variant="outlined"
                                    label="이름" placeholder="이름을 입력하세요"
                        />
                    </Grid>
                    <Grid item xs={8} className="form-item">
                        <MInputText fullWidth
                                    name="mobile_phone" control={control} id="mobile_phone" type="text" variant="outlined"
                                    label="모바일" placeholder="010-000-0000"
                                    readOnly={mobileVerificationState === 'verified'}
                        />
                    </Grid>
                    <Grid item xs={4} className="form-item">
                        <Button className="btn" variant="hc_contained_navy"
                                disabled={!isValidMobile || !nameWatch || mobileVerificationState === 'verified'}
                                onClick={(() => {
                                    requestCodeVerificationMutate.mutate({
                                        request_type: 'sms',
                                        phone_number: mobileWatch,
                                    });
                                    setMobileExpiryTime(dayjs().add(5, 'minutes').toDate());
                                })}
                        >{authCodeBtnText(mobileVerificationState)}</Button>
                    </Grid>
                    {mobileVerificationState === 'inprogress' &&
                    <Grid item xs={12} className="form-item">
                        <TimerTextField fullWidth
                                        expiryTimestamp={mobileExpiryTime}
                                        onExpire={expireVerificationTime}
                                        onOk={(value) => {
                                            verifyCodeTokenMutate.mutate({
                                                request_type: 'sms',
                                                code: value,
                                                code_token: mobileVerificationToken,
                                                name: nameWatch,
                                            })
                                        }}
                        />
                    </Grid>}
                    {emailInfo &&
                    <Grid item xs={12} className="form-item">
                        <div className="email-desc">
                            <p>이메일 주소는 다음과 같습니다.</p>
                            <div className="email-info">
                            {emailInfo.map((email, index) => <p key={index}>{email}</p>)}
                            </div>
                        </div>
                    </Grid>}
                </Grid>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button variant="hc_contained_navy" onClick={onClose}>닫기</Button>
        </DialogActions>
    </Dialog>
    );
}

export default FindEmailDialog;
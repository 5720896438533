import {AgGridReact} from 'ag-grid-react';
import React, {useEffect, useRef, useState} from "react";
import {Button, Grid, IconButton} from "@mui/material";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import '../../css/agGridCustom.css';
import HCTableEditDialog from "./sub/HCTableEditDialog";
import NoDataDiv from "./sub/NoDataDiv";
import dayjs from "dayjs";


function HCTableAg({meta, initContents, funcWriteChangeIntoStore, readOnly, dependencyEffect}) {

    const {columns, view_grid_width, max_rows}  = meta?.options || {};
    const disabled = dependencyEffect === 'disabled';

    const gridRef = useRef();
    const [rows, setRows] = useState(null);
    const [editData, setEditData] = useState(null);

    // console.log('[HCTableAg]', meta?.key, initContents.value, rows);
    const tableColumns = [];
    const rowTemplate = {}

    useEffect(() => {
        if (initContents?.value) {
            const rows = initContents?.value;
            setRows(rows);
        }
    }, [initContents]);

    const deleteRow = (node) => {
        const newRows = [...rows];
        newRows.splice(node.rowIndex, 1);
        setRows(newRows);
        funcWriteChangeIntoStore(meta.key, newRows);
    }

    const editRow = (node) => {
        const row = node.data;
        setEditData({...row, _rowIndex: node.rowIndex});
    }

    const addData = () => {
        setEditData({...rowTemplate, _rowIndex: -1});
    }

    const onEditSave = (params) => {
        const {_rowIndex: _, ...rowEdited} = params;
        let newRows;
        if (params._rowIndex < 0) {
            newRows = [...rows, rowEdited];
        } else {
            newRows = [...rows];
            newRows[params._rowIndex] = rowEdited;
        }
        setRows(newRows);
        funcWriteChangeIntoStore(meta.key, newRows);
        setEditData(null);
    }

    if (rows === null) return null;

    const canAdd = max_rows === null || max_rows === undefined || rows.length < max_rows;
    const defaultColDef = {
        suppressMovable: true,
        sortable: false,
    }
    if (readOnly || disabled) {
        defaultColDef.wrapText = true;
        defaultColDef.autoHeight = true;
    }
    columns.forEach((col, idx) => {
        const column = {field: col.field};
        if (col.title) column.headerName = col.title;
        if (col.width) column.width = col.width;
        if (col.flex) column.flex = col.flex;
        column.cellClass = 'bf-form-hctable-cell';
        switch (col.type) {
            case 'date': rowTemplate[col.field] = dayjs().format('YYYY.MM.DD'); break;
            case 'date_period_yyyymm':
                rowTemplate[col.field] = `,,N`;
                column.valueFormatter = (params) => {
                    const [from, to, current] = params.value.split(',');
                    return `${from} ~ ${current === 'Y' ? '(현재)' : to }`;
                }
                break;
            case 'url':
                if (readOnly) {
                    column.cellRenderer = (params) => {
                        return <a href={params.value} target="_blank" rel="noreferrer">{params.value}</a>
                    }
                }
                break;
            case 'text':
                column.wrapText = true;
                column.autoHeight = true;
                column.cellClass += ' text';
                break;
            default: rowTemplate[col.field] = '';
        }

        tableColumns.push(column);
    });
    if (!readOnly && !disabled) {
        tableColumns.push({
            field: '', width: 90, colId: 'ZZZZZZZZ', cellClass: 'bf-form-hctable-btncell',
            cellRenderer: (props) =>
                <><IconButton size="small" onClick={() => editRow(props.node)}><EditIcon /></IconButton>
                <IconButton size="small" onClick={() => deleteRow(props.node)}><DeleteIcon /></IconButton></>
        });
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{mb: 1}}>
                    {canAdd && !(readOnly || disabled) &&
                        <Button sx={{mt: 1}} variant="hc_contained_navy" onClick={addData}>추가하기</Button>}
                </Grid>
                {rows.length > 0 &&
                <Grid item className="bf-form-hctable ag-theme-alpine" xs={12} sm={view_grid_width}>
                    <AgGridReact columnDefs={tableColumns} rowData={rows} defaultColDef={defaultColDef}
                                 enableCellTextSelection
                                 ref={gridRef} domLayout="autoHeight" />
                </Grid>}
                {(rows.length === 0 && readOnly) &&
                <NoDataDiv />}

            </Grid>
            <HCTableEditDialog open={!! editData}
                               itemDefaultValue={rowTemplate}
                               item={editData} itemMeta={meta}
                               onEditSave={onEditSave} onCancel={() => setEditData(null)}
            ></HCTableEditDialog>
        </>
    )
}

export default HCTableAg

import React from 'react';
import {getFormAtoms, metadataAtom,} from "../store/atoms/atom_config";
import {useAtomValue, useSetAtom} from "jotai";
import {useQuery} from "@tanstack/react-query";
import FormBodyView from "./FormBodyView";
import {selectFormDocumentApi, setFormDocumentData} from "../service/formService";
import {getFormFormatId} from "../service/metaService";

function EmbeddedFormView({campaignId, formType, formSubType, formId}) {
    const metadata = useAtomValue(metadataAtom);
    const {formFetchApi} = selectFormDocumentApi(formType);
    const formAtoms = getFormAtoms(formType, formSubType);
    const setData= useSetAtom(formAtoms.dataAtom);
    const setStore = useSetAtom(formAtoms.storeAtom);
    const setFormDependency = useSetAtom(formAtoms.dependencyAtom);
    const formMetaId = getFormFormatId(metadata, campaignId, formType, formSubType);

    const {data: dataFormDoc, isSuccess: dataFetched}= useQuery(
        [formType, formId],
        () => formFetchApi(formId),
        {
            enabled: !!(metadata?.connected),
            cacheTime: 0,
            onSuccess: (data) => {
                console.log('### form-doc fetched', formType, formId, data, metadata);
                data[formType]['extra'] = data['extra'];
                setFormDocumentData(data, formType, data?.form_meta_dict[formMetaId],
                    setData, setStore, null, null, setFormDependency);
            }
        }
    );

    if (!dataFetched) return null;
    const formMeta = dataFormDoc?.form_meta_dict[formMetaId];
    console.log('[GenericEmbeddedFormView]', formType, formId, dataFetched, dataFormDoc);

    const noOpFuncWriteChangeIntoStore = (key, value, format) => {} // read only component
    return (
        <>
            <FormBodyView
                campaignId={campaignId}
                formMeta={formMeta}
                formType={formType}
                formAtoms={formAtoms}
                funcWriteChangeIntoStore={noOpFuncWriteChangeIntoStore}
                isOwner={false}
                isEmbed={true}
            />
        </>
    );
}

export default EmbeddedFormView;
function BFApplicationGuide() {
    return (
        <div className="bf-application-guide">
            <div className="guide-hello">
                <h2>
                    용기 있는 걸음, 변화를 위한 시도로<br />
                    모두가 함께 살아갈 세상을 움직이는 사람들<br /><br />
                    더 나은 세상을 만드는 여정이 지치지 않고<br />
                    대담한 도전과 새로운 시도를 꿈꿀 수 있도록<br /><br />
                    브라이언 펠로우가 지원합니다.
                </h2>
            </div>
        </div>
    )

}

export default BFApplicationGuide;
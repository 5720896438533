import React from "react";

function bf004_evaluation_nda() {
    return {
        "title": "심사위원 보안서약서",
        "content": (
            <div className="bf-app-agree-terms">
                <p className="desc">본인은 재단법인 브라이언임팩트(이하 “재단”)의 허니콤 홈페이지 서비스(이하 “서비스”)를 통한 펠로우십, 임팩트 그라운드 등 재단의 지원 프로그램
                    관련
                    지원자(이하 “지원자”) 심사 업무(이하 “본 업무”)를 수행하는 심사위원으로서 다음사항을 충분히 숙지하였고, 이를 준수할 것을 엄숙히 서약합니다.</p>
                <br/>
                <p className="desc">1. 본인은 본 업무 중 알게 될 일체의 정보, 서류 및 물건, 기타 이에 관한 업무상·기술상 비밀사항(본 업무 수행 과정에서 알게 된 지원자의
                    개인정보 및
                    사업계획 등 비밀정보 일체를 포함하며, 이하 “비밀정보 등”)과 관련한 재단의 제반 보안규정 및 지침을 성실히 수행할 것임을 확인합니다.</p>
                <p className="desc desc2">2. 본인은 본 업무 수행 중 지득한 모든 비밀정보 등을 일체 공개하거나 누설하지 아니하고, 본 업무 목적 외로 이용하지 아니할 것입니다.
                    또한
                    본인은 본 업무 수행 중 알게 된 지원자에 관한 일체의 정보 등을 온·오프라인 매체(SNS, 유튜브, 포털사이트, 인터넷 커뮤니티, 신문, 방송, 라디오 등을 포함하며 이에
                    한정되지
                    아니함)에 업로드 또는 기고하거나, 제3자에게 유출(판매, 대여, 유용)하지 아니할 것입니다.</p>
                <p className="desc desc2">3. 본인은 본 서약서의 내용을 위반하여 비밀정보 등 및 그에 관한 자료를 유출, 공개하거나 본 업무의 목적 외로 이용한 경우, 개인정보
                    보호법 등
                    관련 법령에 따른 민사·형사·행정상 일체의 책임을 지고, 그로 인하여 재단과 지원자, 그외 제3자에게 손해가 발생한 경우 그 손해를 배상할 것을 확인합니다.</p>
            </div>
        )
    }
}

export default bf004_evaluation_nda;
import {AgGridReact} from 'ag-grid-react';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Grid, IconButton, LinearProgress} from "@mui/material";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import DeleteIcon from "@mui/icons-material/Delete";
import '../../css/agGridCustom.css';
import {useMutation} from "@tanstack/react-query";
import {apiGetPresignedDownloadUrl, apiGetPresignedUploadUrl, apiUploadFileToS3} from "../../store/api_file";
import {useSetAtom} from "jotai";
import {feedbackSnackbarAtom} from "../../store/atoms/atom_config";
import {fileDownloadUsingDom} from "../../util/misc";
import EditIcon from "@mui/icons-material/Edit";
import HCTableEditDialog from "./sub/HCTableEditDialog";
import NoDataDiv from "./sub/NoDataDiv";

function HCAttachedFileTableAg({meta, initContents, funcWriteChangeIntoStore, readOnly, dependencyEffect, formMeta}) {

    const {view_grid_width, max_rows}  = meta?.options || {};
    const disabled = dependencyEffect === 'disabled';

    const gridRef = useRef();
    const uploadBtnRef = useRef();
    const [rows, setRows] = useState(null);
    const [editData, setEditData] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(-1);
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    //console.log('[HCAttachedFileTableAg]', meta?.key, initContents.value, rows);

    useEffect(() => {
        //console.log('#### useEffect', initContents.value)
        if (initContents?.value) {
            const rows = initContents?.value;
            setRows(rows);
        }
    }, [initContents]);

    const uploadProgressCallback = useCallback((loaded, total) => {
        setUploadProgress(Math.round((loaded / total)*100));
    }, []);
    const uploadCompleted = () => {
        console.log('uploadCompleted', uploadProgress);
        if (uploadProgress >= 0) setUploadProgress(-1);
        uploadBtnRef.current.value = '';
        //document.getElementById('btn-upload').value = '';
    }

    const uploadFileMutation = useMutation({
        mutationFn: apiUploadFileToS3,
        onSuccess: (data, variables, context) => {
            console.log('[mutation] uploadFile', data, variables, context);
            const {fileName, objectName} = variables;
            uploadCompleted();
            addTableRow(fileName, objectName);
        },
        onError: (error, variables, context) => {
            console.log('onError', error, variables, context)
            setFeedbackSnackbar(['파일 업로드에 실패했습니다.', 'error']);
            uploadCompleted();
        }
    });

    const getPresignedUploadMutation = useMutation({
        mutationFn: apiGetPresignedUploadUrl,
        onSuccess: (data, variables, context) => {
            console.log('[mutation] getPresignedUrl', data, variables, context);
            const {url, fields} = data;
            const {file, fileName, fileType} = variables;
            const uploadReq = new FormData();
            Object.keys(fields).forEach(key => uploadReq.append(key, fields[key]));
            uploadReq.append("file", file);
            uploadFileMutation.mutate({
                url: url, data: uploadReq, fileName, fileType, objectName: fields.key,
                onProgress: uploadProgressCallback,
            });
        },
        onError: (error, variables, context) => {
            console.log('onError', error, variables, context)
            setFeedbackSnackbar(['파일 업로드에 실패했습니다.', 'error']);
            uploadCompleted();
        }
    });

    const getPresignedDownloadMutation = useMutation({
        mutationFn: apiGetPresignedDownloadUrl,
        onSuccess: (data, variables, context) => {
            const {url} = data;
            fileDownloadUsingDom(url);
        },
        onError: (error, variables, context) => {
            console.log('onError', error, variables, context)
            setFeedbackSnackbar(['파일 다운로드에 실패했습니다.', 'error']);
        }
    });

    const uploadFile = (event) => {
        if (event.target.files.length < 1) return;

        const fileInfo = event.target.files[0];
        if (fileInfo.size > 1000_000_000) {
            setFeedbackSnackbar(["첨부할 파일 크기는 1GB 이하여야 합니다.", 'error']);
            uploadCompleted();
            return;
        }

        getPresignedUploadMutation.mutate({
            campaignId: formMeta.campaignId,
            formType: formMeta.formType,
            fileName: fileInfo.name,
            fileType: fileInfo.type,
            file: event.target.files[0]
        });
    }

    const deleteRow = (node) => {
        const newRows = [...rows];
        newRows.splice(node.rowIndex, 1);
        setRows(newRows);
        funcWriteChangeIntoStore(meta.key, newRows);
    }

    const onDownloadFile = (objectName) => {
        console.log("onDowndloadFile");
        getPresignedDownloadMutation.mutate({
            campaignId: formMeta.campaignId,
            objectName: objectName,
        });
    }

    const fileNameCellRenderer = (params) => {
        const objectName = params.data.object_name;
        return <Button onClick={() => onDownloadFile(objectName)}
                       element="span" color="navy">{params.value}</Button>
    }

    const editRow = (node) => {
        const row = node.data;
        setEditData({...row, _rowIndex: node.rowIndex});
    }

    const addTableRow = (fileName, objectName) => {
        const newRow = {
            'file_name': fileName,
            'object_name': objectName,
            'note': '',
            _rowIndex: -1
        };
        setEditData(newRow);
        /*
        const newData = [...rows, newRow];
        setRows(newData);
        funcWriteChangeIntoStore(meta.key, newData);
        */
    }

    const onEditSave = (params) => {
        const {_rowIndex: _, ...rowEdited} = params;
        let newRows;
        if (params._rowIndex < 0) {
            newRows = [...rows, rowEdited];
        } else {
            newRows = [...rows];
            newRows[params._rowIndex] = rowEdited;
        }
        setRows(newRows);
        funcWriteChangeIntoStore(meta.key, newRows);
        setEditData(null);
    }

    if (rows === null) return null;

    const canAdd = max_rows === null || max_rows === undefined || rows.length < max_rows;
    const tableColumns = [{
        field: 'file_name', sortable: false,
        headerName: '파일명', flex: 1,
        cellRenderer: fileNameCellRenderer
    }, {
        field: 'note', sortable: false,
        headerName: '파일 간략 설명', flex: 1, editable: true
    }];
    const rowTemplate = {'file_name': '', 'object_name': '', 'note': ''};
    const columnForEdit = [
        {field:'file_name', type:'defined', title:'파일명'},
        {field:'object_name', type:'defined', title:'파일명', hidden: true},
        {field:'note', type:'string', title:'설명'}];
    if (!readOnly && !disabled) {
        tableColumns.push({
            field: '', width: 120, colId: 'ZZZZZZZZ',
            cellRenderer: (props) =>
                <><IconButton onClick={() => editRow(props.node)}><EditIcon /></IconButton>
                <IconButton onClick={() => deleteRow(props.node)}><DeleteIcon /></IconButton></>
        });
    }

    return (<>
    <Grid container>
        <Grid item xs={12} sx={{mb: 1}}>
            {canAdd && !(readOnly || disabled) &&
                <label htmlFor="btn-upload">
                    <input id="btn-upload" name="btn-upload" style={{ display: 'none' }} type="file"
                           ref={uploadBtnRef} onChange={uploadFile} disabled={uploadProgress >= 0} />
                    <Button sx={{mt: 1}} variant="hc_contained_navy" component={uploadProgress < 0 ? "span" : "button"}
                            disabled={uploadProgress >= 0}>파일 업로드하기</Button>
                </label>}
            {(uploadProgress >= 0) &&
                <div className="progress-container">
                    <LinearProgress className="progress" color="navy" variant="determinate" value={uploadProgress} />
                </div>}
        </Grid>
        {rows.length > 0 && <Grid item className="bf-form-hctable ag-theme-alpine" xs={12} sm={view_grid_width}>
            <AgGridReact columnDefs={tableColumns} rowData={rows}
                         enableCellTextSelection
                         ref={gridRef} domLayout="autoHeight"
            />
        </Grid>}
        {(rows.length === 0 && readOnly) && <NoDataDiv />}
    </Grid>
    <HCTableEditDialog open={!! editData}
        itemDefaultValue={rowTemplate}
        item={editData} itemMeta={meta}
        predefinedColumns={columnForEdit}
        onEditSave={onEditSave} onCancel={() => setEditData(null)}
    ></HCTableEditDialog>
    </>)
}

export default HCAttachedFileTableAg

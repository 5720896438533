import HeaderView from "../components/HeaderView";
import {Outlet, useParams} from "react-router-dom";
import Footer from "../components/Footer";
import React from "react";

function BFLayout(props) {
    const {campaignId} = useParams();
    const childProps = {campaignId, ...props};
    return (
        <div className="bf-root">
            <HeaderView {...childProps} />
            <Outlet/>
            <Footer {...childProps} />
        </div>
    )
}

export default BFLayout;
import {getTokenCookie} from "./cookies";

const API_BASE_URL= process.env.REACT_APP_API_PREFIX || '/api/v1';
const ADMIN_API_BASE_URL = process.env.REACT_APP_ADMIN_API_PREFIX || '/admin-api/v1';

class ApiError extends Error {
    constructor(message, cause) {
        super(message);
        this.name = 'ApiError';
        this.cause = cause;
    }
}

function build_default_headers(no_auth, use_form) {
    const headers = {}
    if (use_form) headers['Content-Type'] = 'application/x-www-form-urlencoded';
    else headers['Content-Type'] = 'application/json';
    const token = getTokenCookie();
    if (!no_auth && token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
}

async function check_api_response(response) {
    //console.log('[check_api_response]:', response);
    let json;
    try {
        json = await response.json();
    } catch (e) {
        json = null;
    }
    if (! response.ok) {
        const cause = {
            status: response.status,
            err_code: json?.err_code,
            err_info: json?.err_info,
        }
        console.log('[ApiError]', json?.message, response.statusText, cause);
        throw new ApiError(json?.message || response.statusText, cause);
    }
    return json;
}

export {
    API_BASE_URL,
    ADMIN_API_BASE_URL,
    check_api_response,
    build_default_headers
}
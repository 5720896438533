import {Button, Grid, IconButton, Stack} from "@mui/material";
import classNames from "classnames";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import {useNavigate} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {apiDeleteApplication, apiFetchApplicationList} from "../store/api_application";
import {useAtomValue, useSetAtom} from "jotai";
import {feedbackSnackbarAtom, metadataAtom} from "../store/atoms/atom_config";
import {configGlobalConfirm, globalConfirmAtom} from "../store/atoms/atom_ui";
import {getStatusStr} from "../service/formService";
import {utcDayjs} from "../util/misc";
import {getCreatableForms, getSubmittableForms} from "../service/metaService";

const getCampaignProcessSection = (campaignId) => {
    switch (campaignId) {
        case 'brianfellow_2023':
        case 'brianfellow_004':
            return (<ul>
                <li>지원서 제출 : 8월 25일까지</li>
                <li>지원서 검토 결과 : 9월 6일</li>
                <li>1차 현장방문 인터뷰 : 9월 11일 ~ 10월 6일</li>
                <li>2차 선정위원 인터뷰 : 10월 16일 ~ 10월 20일</li>
                <li>최종 펠로우 선정 : 10월 23일</li>
            </ul>);
        case 'brianfellow_005':
            return (<ul>
                <li>지원서 제출 : 5월 2일(목) ~ 5월 31일(금)</li>
                <li>지원서 검토 결과
                    <ul>
                        {/*<li>현재 서류 심사 중입니다.</li>*/}
                        <li>개별 이메일로 결과를 안내해 드리겠습니다.</li>
                    </ul>
                </li>
                <li>현장방문 인터뷰 : 7월 ~ 8월 중 &lt;예정&gt;</li>
                <li>선정위원 인터뷰 : 9월 중 &lt;예정&gt;</li>
                <li>최종 펠로우 선정 : 9월 20일(금) &lt;예정&gt;</li>
            </ul>);
    }
}

const getCampaignApplyEndSection = (campaignId) => {
    switch (campaignId) {
        case 'brianfellow_2023':
        case 'brianfellow_004':
            return (<ul>
                <li>브라이언 펠로우 접수가 종료되었습니다. 감사합니다.</li>
                <li>브라이언 펠로우 4기 최종 발표는 2023년 12월 중순에 공개 예정입니다.</li>
                <li className="orange">(9/6) 지원서 검토 결과는 개별 이메일로 발송되었습니다.</li>
            </ul>);
        case 'brianfellow_005':
            return (<ul>
                <li>브라이언 펠로우 후보자 지원이 종료되었습니다. 감사합니다.</li>
                <li>브라이언 펠로우 시즌5 최종 발표는 2024년 12월에 공개 예정입니다.</li>
            </ul>);
    }
}

function BFApplicationList({campaignId}) {
    const metadata = useAtomValue(metadataAtom);

    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const setGlobalConfirm = useSetAtom(globalConfirmAtom);
    const queryClient = useQueryClient();

    const navigate = useNavigate();
    const {isSuccess, data} = useQuery({
        queryKey: [campaignId, 'applications-my'],
        queryFn: () => apiFetchApplicationList(campaignId, "my"),
        enabled: !!(metadata?.connected)
    });

    const deleteMutation = useMutation({
        mutationFn: apiDeleteApplication,
        onSuccess: (data, variables, context) => {
            setFeedbackSnackbar(['삭제되었습니다.', 'info']);
            queryClient.invalidateQueries({queryKey: [campaignId, 'applications-my']}).then(r => {});
        },
        onError: (error, variables, context) => {
            setFeedbackSnackbar(['삭제 중 오류가 발생했습니다.', 'error']);
        }
    });

    const onDelete = (docId) => {
        setGlobalConfirm(configGlobalConfirm({
            open: true,
            title: '지원서를 정말 삭제하시겠습니까?',
            onOk: () => deleteAction(docId)
        }));
    }
    const deleteAction = (docId) => {
        deleteMutation.mutate({id: docId});
    }

    if (!isSuccess) {
        return null;
    }

    console.log('[BFApplicationList]', data);
    const applied = data?.applications.length > 0;
    const application = applied ? data?.applications[0] : null;
    const submitted = application?.status === 'submitted';
    const updatedAt = submitted ?
        utcDayjs(application?.submitted_at).format('YYYY.MM.DD HH:mm') :
        utcDayjs(application?.updated_at).format('YYYY.MM.DD HH:mm');

    const campaignMeta = metadata.campaigns[campaignId];
    const [creatableForm] = getCreatableForms(metadata, campaignId, 'application', 'apply_site');
    const formSubType = application ? application.sub_type : creatableForm?.subtype;
    const btnText = applied ? (submitted ? '제출된 지원서 읽기' : '지원서 수정하기') : '브라이언 펠로우 지원하기';
    const btnFormId = applied ? application.doc_id : 'new-empty-doc';

    return (<div className="bf-application-list">
        {(applied || ['before', 'applying'].includes(campaignMeta.stage)) &&
        (<Stack direction="row" className={classNames("go-container")}>
            <Button className="title-text" color="black"
                    onClick={() => navigate(`/c/${campaignId}/doc/application/${formSubType}/${btnFormId}`)}
            >{btnText}</Button>
            <IconButton onClick={() => navigate(`/c/${campaignId}/doc/application/${formSubType}/${btnFormId}`)}
            ><ArrowCircleRightIcon className="btn orange" /></IconButton>
        </Stack>)}
        <hr className="guide-hr" />
        <Grid container className="list-container">
                <Grid item xs={12} md={6}>
                    <h2>프로세스 안내</h2>
                    <div className="desc-container">
                        {getCampaignProcessSection(campaignId)}
                        <div className="desc-note">👉 인터뷰 및 최종 선정 일정은 변경될 수 있습니다.</div>
                    </div>
                    {!['before', 'applying'].includes(campaignMeta.stage) && <>
                        <h2>접수 종료 안내</h2>
                        <div className="desc-container">{getCampaignApplyEndSection(campaignId)}</div>
                    </>}
                </Grid>
                {applied && <Grid item xs={12} md={6}>
                    <h2>지원 현황</h2>
                    <div className="desc-container">
                        <ul>
                            <li>지원서 현황: <span className={classNames("status", {
                                orange: submitted,
                            })}>{getStatusStr(application?.doc_type, application?.status)}</span>
                                <span className="update-time">{` (${updatedAt})`}</span>
                            </li>
                            {application?.status === 'submitted' && <>
                                {campaignMeta.stage === 'screening' &&
                                    <li>지원서 검토 현황: <span className="status">심사 중</span></li>}
                                {['evaluating', 'end'].includes(campaignMeta.stage) &&
                                    <li>지원서 검토 현황: <span className="status">심사 완료 👉 이메일로 결과를 안내드립니다.</span></li>}
                            </>}
                        </ul>
                    </div>
                </Grid>}
            </Grid>
    </div>)

}

export default BFApplicationList;
import {DataGrid} from "@mui/x-data-grid";
import {useAtomValue} from "jotai";
import {metadataAtom} from "../store/atoms/atom_config";
import {useQuery} from "@tanstack/react-query";
import React from "react";
import {utcDayjs} from "../util/misc";
import {apiFetchDiggingNoteList} from "../store/api_digging_note";
import classNames from "classnames";
import {Button, IconButton, Stack} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import {useNavigate, useParams} from "react-router-dom";
import {getCreatableForms} from "../service/metaService";

function BFDiggingNoteList(props) {
    const {campaignId} = useParams();
    const metadata = useAtomValue(metadataAtom);
    const navigate = useNavigate();

    const {isSuccess, data}= useQuery({
        queryKey: [campaignId, 'diggingNote-my'],
        queryFn: () => apiFetchDiggingNoteList(campaignId, "my"),
        enabled: !!(metadata?.connected)
    });

    const columns = [
        {field: 'id', headerName: 'ID', cellClassName: "evaluation-cell", headerClassName: "evaluation-header", flex: 1,
            renderCell: (p) => {
                const updatedAt = p.row.updated_at.substring(0, 7);
                return <a href={`/c/${campaignId}/doc/digging_note/${p.row.sub_type}/${p.row.id}`}>디깅노트 ({updatedAt})</a>
            }
        },
        {field: 'note_type', headerName: '종류', width: 110, cellClassName: "evaluation-cell", headerClassName: "evaluation-header",

        },
        {field: 'status', headerName: '진행상황', width: 110, cellClassName: "evaluation-cell", headerClassName: "evaluation-header",
            renderCell: (p) => {
                const status = p.row.status;
                const text = status === 'draft' ? '작성 중' : (status === 'submitted' ? '제출 완료' : '알수 없음');
                return <span className={`status-${status}`}>{text}</span>;
            }
        },
        { field: 'updated_at', headerName: '최근 수정일', width: 150, cellClassName: "evaluation-cell", headerClassName: "evaluation-header"},
    ]

    if (!isSuccess) return null;
    const convertData = (data) => {
        const rows = [];
        data && data.forEach((item) => {
            const noteType = {'fellow_start':'선정', 'fellow_extend': '연장', 'unknown': '알수없음'}[item.sub_type || 'unknown'];
            const row = {
                'id': item.doc_id,
                'writer': item.writer_name,
                'sub_type': item.sub_type,
                'note_type': noteType,
                'status': item.status,
                'updated_at': utcDayjs(item.updated_at).format('YYYY.MM.DD HH:mm'),
            }
            rows.push(row);
        });
        return rows;
    }
    const diggingNotes = convertData(data?.digging_notes);
    const [creatableForm] = getCreatableForms(metadata, campaignId, 'digging_note', 'apply_site');
    const newDocUrl = `/c/${campaignId}/doc/digging_note/${creatableForm.subtype}/new-empty-doc`;

    return (<>
        <div className="bf-recommend-list">
            <Stack direction="row" className={classNames("go-container", {hide:false && diggingNotes.length})}>
                <Button className="title-text" color="black" onClick={() => navigate(newDocUrl)}>디깅노트 작성</Button>
                <IconButton onClick={() => navigate(newDocUrl)}><ArrowCircleRightIcon className="btn orange" /></IconButton>
            </Stack>
            <DataGrid className="grid-container" columns={columns} rows={diggingNotes}
                      disableRowSelectionOnClick
                      autoHeight hideFooter />
        </div>
    </>);
}

export default BFDiggingNoteList;
// https://tech.osci.kr/react-hook-form-with-mui/
import React, {useState} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {useController} from "react-hook-form";
import {Visibility, VisibilityOff} from "@mui/icons-material";

function MInputPassword({name, rules, control, readOnly, ...props}) {
    const {
        field: { value, onChange, onBlur },
        fieldState: { isDirty, isTouched, error },
    } = useController({name, rules, control});

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (e) => {e.preventDefault();};

    return (
        <TextField
            type={showPassword ? "text" : "password"}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!error}
            inputProps={{
                readOnly: readOnly
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            {...props}
        />
    );
}

export default MInputPassword;
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useAtomValue, useSetAtom} from "jotai";
import {feedbackSnackbarAtom, metadataAtom} from "../store/atoms/atom_config";
import {globalConfirmAtom, configGlobalConfirm} from "../store/atoms/atom_ui";
import {apiFetchNominationList, apiDeleteNomination} from "../store/api_nomination";
import DeleteIcon from "@mui/icons-material/Delete";
import {Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {utcDayjs} from "../util/misc";
import {getCreatableForms} from "../service/metaService";


function BFRecommendList({campaignId}) {

    const metadata = useAtomValue(metadataAtom);

    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const setGlobalConfirm = useSetAtom(globalConfirmAtom);

    const queryClient = useQueryClient();
    const {isSuccess, data}= useQuery({
        queryKey: [campaignId, 'nominations-my'],
        queryFn: () => apiFetchNominationList(campaignId, "my"),
        enabled: !!(metadata?.connected)
    });
    const deleteMutation = useMutation({
        mutationFn: apiDeleteNomination,
        onSuccess: (data, variables, context) => {
            // console.log('onSuccess', data, variables, context);
            setFeedbackSnackbar(['삭제 되었습니다.', 'info']);
            queryClient.invalidateQueries({queryKey: [campaignId, 'nominations-my']}).then(r => {});
        },
        onError: (error, variables, context) => {
            setFeedbackSnackbar(['삭제 중 오류가 발생했습니다.', 'error']);
        }
    });
    const navigate = useNavigate();

    if (!isSuccess) {
        return null;
    }
    const nominations = data?.nominations;
    const campaignMeta = metadata.campaigns[campaignId];
    const MAX_NOMINATIONS = 3;
    const num_nom = nominations?.length||0;
    console.log('[BFRecommendList]', data);

    const onDelete = (params) => {
        const docId = params.row.doc_id;
        setGlobalConfirm(configGlobalConfirm({
            open: true,
            title: '추천서를 정말 삭제하시겠습니까?',
            onOk: () => deleteAction(docId)
        }));
    }
    const deleteAction = (docId) => {
        deleteMutation.mutate({id: docId});
    }

    const columns= [
        { field: 'nominee', headerName: '후보자', flex: 3, cellClassName: "nominee-cell", headerClassName: "nominee-header",
            renderCell: (params) => {
                const name = params.row.form_data['후보_이름']?.value||'(이름 없음)';
                const org = params.row.form_data['후보_소속']?.value||'';
                return <a href={`/c/${campaignId}/doc/nomination/${params.row.sub_type}/${params.row.doc_id}`}>{org ? `${name} (${org})` : name}</a>
            },
        },
        { field: 'status', headerName: '추천서 진행 상황', flex: 2, cellClassName: "nominee-cell", headerClassName: "nominee-header",
            renderCell: (params) => {
                const status = params.row.status;
                const text = status === 'draft' ? '작성 중' :
                    status === 'submitted' ? '제출 완료' : '알수 없음';
                return <span className={`status-${status}`}>{text}</span>;
            }
        },
        { field: 'updated_at', headerName: '최근 수정일', flex: 2, cellClassName: "nominee-cell", headerClassName: "nominee-header",
            renderCell: (params) => {
                const longDate = utcDayjs(params.row.updated_at).format('YYYY.MM.DD HH:mm');
                const shortDate = utcDayjs(params.row.updated_at).format('MM.DD');
                return <><Typography className="nom_date" sx={{display: {xs:'none', sm:'block'}}}>{longDate}</Typography>
                        <Typography className="nom_date" sx={{display: {xs:'block', sm:'none'}}}>{shortDate}</Typography></>
            }
        },
        { field: 'actions', type: 'actions', width: 50,
            getActions: (params) => {
                if (params.row.status === 'submitted') return [];
                else return [
                    <GridActionsCellItem icon={<DeleteIcon />} onClick={() => onDelete(params)} label="Delete" />,
                ];
            }
        }
    ];
    const [creatableForm] = getCreatableForms(metadata, campaignId, 'nomination', 'apply_site');

    return (
        <div className="bf-recommend-list">
            {['before', 'applying'].includes(campaignMeta.stage) &&
            (<Stack direction="row" className={classNames("go-container", {hide:num_nom>=MAX_NOMINATIONS})}>
                <Button className="title-text" color="black"
                        onClick={() => navigate(`/c/${campaignId}/doc/nomination/${creatableForm.subtype}/new-empty-doc`)}
                    >{num_nom>0 ? "후보 더 추천하기" : "후보 추천하기"}</Button>
                <IconButton onClick={() => navigate(`/c/${campaignId}/doc/nomination/${creatableForm.subtype}/new-empty-doc`)}
                    ><ArrowCircleRightIcon className="btn orange" /></IconButton>
            </Stack>)}
            <div className={classNames("bf-recommend-grid", {hide:num_nom===0})}>
                <div className="grid-container">
                    {num_nom!==0 && <DataGrid getRowId={(row) => row.doc_id}
                        rows={nominations} columns={columns} autoHeight hideFooter disableColumnMenu />}
                </div>

            </div>
            <hr className="guide-hr" />
            <Grid container className="guide-recomm" spacing={2}>
                <Grid item xs={12} sm={6} className="column">
                    <h2>이런 사람을 추천해주세요</h2>
                    <ol className="guide-column-items">
                        <li> 해결하고자 하는 사회문제가 명확한 사람 </li>
                        <li> 새로운 방식으로 문제를 해결하고자 하는 사람 </li>
                        <li> 작은 성공일지라도 성장의 가능성을 보여주는 사람 </li>
                        <li> 가지않은 길을 두려워 하지 않으며 세상을 선하게 바꾸려고 노력하는 사람 </li>
                    </ol>
                    <p className="guide-column-note">✻ 현재 공직선거 후보자이거나 정치 단체에서 유급 급여를 받고 활동하는 사람은 추천 대상에서 제외됩니다.</p>
                </Grid>
                <Grid item xs={12} sm={6} className="column">
                    <h2>추천 진행 절차 안내</h2>
                    <ol className="guide-column-items">
                        <li>피추천인에 대한 추천서를 제출해 주세요.</li>
                        <li>사무국에서 추천된 피추천인에게 순차적으로 연락드립니다.</li>
                        <li>피추천인이 펠로우 지원서를 제출하면 후보자로 최종 등록됩니다.</li>
                    </ol>
                </Grid>
            </Grid>
        </div>
    );
}

export default BFRecommendList;
import {atom} from 'jotai'

const metadataAtom = atom(null);
const feedbackSnackbarAtom = atom(['', 'info']);

const tokenAtom = atom(null);
const userInfoAtom = atom(null);

const formStepInfoAtom = atom({});

const createFormAtoms = () => {
    return {
        dataAtom: atom({}),
        needSaveAtom: atom(false),
        storeAtom: atom({}),
        validatorAtom: atom({}),
        stepInfoAtom: atom({}),
        dependencyAtom: atom({}),
    };
}

const formAtoms = [];
for (let i = 0; i < 9; i++) {
    formAtoms.push(createFormAtoms());
}

const getFormAtoms = (formType, secondKey) => {
    switch (formType) {
        case 'nomination': return formAtoms[0];
        case 'application': return formAtoms[1];
        case 'digging_note': return formAtoms[7];
        case 'scorecard':
            switch (secondKey) {
                case 'paper': return formAtoms[2];
                case 'onsite': return formAtoms[3];
                case 'final-pre1': return formAtoms[4];
                case 'final-pre2': return formAtoms[5];
                case 'final': return formAtoms[6];
                case 'fellow_extend': return formAtoms[8];
                default: return null;
            }
        default: return null;
    }
}

export {
    metadataAtom,
    feedbackSnackbarAtom,
    userInfoAtom,
    tokenAtom,
    formStepInfoAtom,
    getFormAtoms,
}
import {Button, Grid, Link, Stack} from "@mui/material";
import React from "react";
import "../css/signup.css"

import {useForm} from "react-hook-form";
import MInputText from "../components/common/MInputText";
import {apiSignup} from "../store/api_user";
import dayjs from "dayjs";
import TimerTextField from "../components/common/TimerTextField";
import {useMutation} from "@tanstack/react-query";
import {apiRequestCodeVerification, apiVerifyCodeToken} from "../store/api_misc";
import {useSetAtom} from "jotai";
import {feedbackSnackbarAtom} from "../store/atoms/atom_config";
import MCheckbox from "../components/common/MCheckbox";
import MInputPassword from "../components/common/MInputPassword";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {moveURL, isValidMobilePhone, isValidEmail as isValidEmailAddr, buildErrorMessage} from "../util/misc";
import ConfirmDialog from "../components/modal/ConfirmDialog";
import {clearTokenCookie} from "../store/cookies";

//https://react-hook-form.com/

function Signup() {
    const {
        control, watch, handleSubmit,
        formState: {isSubmitting, isDirty, errors},
    } = useForm({
        defaultValues: {
            name: "", email: "", mobile_phone: "", password: "", password2: "",
            agree_terms: false, agree_privacy: false, agree_marketing: false, agree_ad: false
        },
        shouldFocusError: true,
    });

    // before - inprogress - verified
    const [emailVerificationState, setEmailVerificationState] = React.useState('before');
    const [mobileVerificationState, setMobileVerificationState] = React.useState('before');
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

    const [emailExpiryTime, setEmailExpiryTime] = React.useState(null);
    const [mobileExpiryTime, setMobileExpiryTime] = React.useState(null);
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const [mobileVerificationToken, setMobileVerificationToken] = React.useState(null);
    const [emailVerificationToken, setEmailVerificationToken] = React.useState(null);

    const requestCodeVerificationMutate = useMutation({
        mutationFn: apiRequestCodeVerification,
        onSuccess: (data, variables, context) => {
            const {request_type} = variables;
            const {code_token} = data;
            console.log('[mutation] requestCodeVerificationMutate', request_type, data);
            if (request_type === 'sms') {
                setMobileVerificationState('inprogress');
                setMobileVerificationToken(code_token);
            } else {
                setEmailVerificationState('inprogress');
                setEmailVerificationToken(code_token);
            }
        },
        onError: (error, variables, context) => {
            console.log('onError', error, variables, context)
            const err_msgs = variables.request_type === 'sms' ?
                {400: '전화번호 형식이 잘못되었습니다.'} :
                {400: '이메일 형식이 잘못되었습니다.', 409: '이미 가입된 이메일입니다.'};
            setFeedbackSnackbar([buildErrorMessage(error, err_msgs), 'error']);
        }
    });
    const verifyCodeTokenMutate = useMutation({
        mutationFn: apiVerifyCodeToken,
        onSuccess: (data, variables, context) => {
            console.log('[mutation] apiRequestSmsVerification', data);
            const {request_type} = variables;
            if (request_type === 'sms') {
                setMobileVerificationToken(null);
                setMobileVerificationState('verified');
            } else {
                setEmailVerificationToken(null);
                setEmailVerificationState('verified');
            }
        },
        onError: (error, variables, context) => {
            console.log('onError', error, variables, context)
            setFeedbackSnackbar([buildErrorMessage(error, {400: '인증 정보가 잘못되었습니다.'}), 'error']);
        }
    });
    const signupMutate = useMutation({
        mutationFn: apiSignup,
        onSuccess: (data, variables, context) =>
            setOpenConfirmDialog(true),
        onError: (error, variables, context) => {
            console.log('onError', error, error.cause)
            setFeedbackSnackbar(['회원가입 중 문제가 발생했습니다.', 'error']);
        }
    });

    const moveLogin = () => {
        clearTokenCookie();
        moveURL('/login');
    }

    const doSignup = async (data) => {
        signupMutate.mutate(data);
    }

    const expireVerificationTime = () => {
        alert('인증시간이 만료되었습니다. 다시 인증해주세요.');
    }

    const authCodeBtnText = (state) => {
        if (state === 'verified') return '인증 완료';
        else if (state === 'inprogress') return '재발송';
        else return '인증번호 발송';
    }

    const mobileWatch = watch("mobile_phone", "");
    const isValidMobile = isValidMobilePhone(mobileWatch);

    const emailWatch = watch("email", "");
    const isValidEmail = isValidEmailAddr(emailWatch);

    const emailVerified = emailVerificationState === 'verified';
    const mobileVerified = mobileVerificationState === 'verified';

    return (
    <div className="bf-center-container">
    <Stack className="bf-signup">
        <div className="header"><div className='logo'></div></div>
        <form onSubmit={handleSubmit(doSignup)}>
            <Grid container className="form">
                <Grid item xs={12} className="form-item">
                    <MInputText fullWidth
                        name="name" control={control} id="name" type="text" variant="outlined"
                        label="이름" placeholder="이름을 입력하세요"
                        aria-invalid={!isDirty ? undefined : errors.name ? "true" : "false"}
                        rules={{
                            required: "필수 입력입니다."
                        }}
                    />
                    {errors.name && <small className='error-msg' role="alert">{errors.name.message}</small>}
                </Grid>

                {/* 이메일 */}
                <Grid item xs={8} className="form-item">
                    <MInputText fullWidth
                        name="email" control={control} id="email" type="text" variant="outlined"
                        label="이메일" placeholder="example@email.com"
                        aria-invalid={!isDirty ? undefined : errors.email ? "true" : "false"}
                        rules={{
                            name: "email",
                            required: "이메일은 필수 입력입니다."
                        }}
                        readOnly={emailVerificationState === 'verified'}
                    />
                    {errors.email && <small className="error-msg" role="alert">{errors.email.message}</small>}
                </Grid>
                <Grid item xs={4} className="form-item">
                    <Button className="btn" variant="hc_contained_navy"
                        disabled={!isValidEmail || emailVerificationState === 'verified'}
                        onClick={(() => {
                            requestCodeVerificationMutate.mutate({
                                request_type: 'email',
                                email: emailWatch,
                                check_scheme: 'check_conflict',
                            });
                            setEmailExpiryTime(dayjs().add(10, 'minutes').toDate());
                        })}
                    >{authCodeBtnText(emailVerificationState)}</Button>
                </Grid>
                {emailVerificationState === 'inprogress' &&
                <Grid item xs={12} className="form-item">
                    <TimerTextField fullWidth
                        expiryTimestamp={emailExpiryTime}
                        onExpire={expireVerificationTime}
                        onOk={(value) => {
                            verifyCodeTokenMutate.mutate({
                                request_type: 'email',
                                code: value,
                                code_token: emailVerificationToken,
                            })
                        }}
                    />
                </Grid>}

                {/* 휴대폰 */}
                {emailVerified && <>
                <Grid item xs={8} className="form-item">
                    <MInputText fullWidth
                        name="mobile_phone" control={control} id="mobile_phone" type="text" variant="outlined"
                        label="모바일" placeholder="010-000-0000"
                        aria-invalid={!isDirty ? undefined : errors.mobile_phone ? "true" : "false"}
                        rules={{
                            name: "mobile_phone",
                            required: "휴대폰 번호는 필수 입력입니다.",
                        }}
                        readOnly={mobileVerificationState === 'verified'}
                    />
                    {errors.mobile_phone && <small className="error-msg" role="alert">{errors.mobile_phone.message}</small>}
                </Grid>
                <Grid item xs={4} className="form-item">
                    <Button className="btn" variant="hc_contained_navy"
                        disabled={!isValidMobile || mobileVerificationState === 'verified'}
                        onClick={(() => {
                            requestCodeVerificationMutate.mutate({
                                request_type: 'sms',
                                phone_number: mobileWatch,
                            });
                            setMobileExpiryTime(dayjs().add(5, 'minutes').toDate());
                        })}
                    >{authCodeBtnText(mobileVerificationState)}</Button>
                </Grid>
                {mobileVerificationState === 'inprogress' &&
                <Grid item xs={12} className="form-item">
                    <TimerTextField fullWidth
                        expiryTimestamp={mobileExpiryTime}
                        onExpire={expireVerificationTime}
                        onOk={(value) => {
                            verifyCodeTokenMutate.mutate({
                                request_type: 'sms',
                                code: value,
                                code_token: mobileVerificationToken,
                            })
                        }}
                    />
                </Grid>}
                </>}

                {/* 비밀번호 */}
                {emailVerified && mobileVerified && <>
                <Grid item xs={12} className="form-item">
                    <MInputPassword fullWidth
                        name="password" control={control} id="password" variant="outlined"
                        label="비밀번호" placeholder="10자 이상 설정해 주세요."
                        aria-invalid={!isDirty ? undefined : errors.password ? "true" : "false"}
                        rules={{
                            name: "password",
                            required: "비밀번호는 필수 입력입니다.",
                            minLength: {
                                value: 10,
                                message: "비밀번호는 10자 이상 설정해 주세요."
                            }
                        }}
                    />
                    {errors.password && <small className="error-msg" role="alert">{errors.password.message}</small>}
                </Grid>
                <Grid item xs={12} className="form-item">
                    <MInputPassword fullWidth
                        name="password2" control={control} id="password2" variant="outlined"
                        label="비밀번호 재확인" placeholder="비밀번호를 다시 입력해 주세요."
                        aria-invalid={!isDirty ? undefined : errors.password2 ? "true" : "false"}
                        rules={{
                            name: "password2",
                            required: "필수 입력입니다.",
                            validate: (value) => value === watch('password') || "비밀번호가 일치하지 않습니다."
                        }}
                    />
                    {errors.password2 && <small className="error-msg" role="alert">{errors.password2.message}</small>}
                </Grid>

                <Grid item xs={12} className="form-item">
                    <div className="fill">
                        <MCheckbox name="agree_terms" control={control} id="agree_terms" size="small"
                            label={<p className="agree-msg"><a href="https://brianimpact.oopy.io/apply/policy/terms"
                               target="_blank" rel="noreferrer">이용 약관</a>에 동의합니다.</p>}
                            rules={{name: "agree_terms", required: "반드시 동의하셔야 합니다.",}}
                        />
                    </div>
                    {errors.agree_terms && <small className="error-msg agree" role="alert">{errors.agree_terms.message}</small>}
                    <div className="fill">
                        <MCheckbox name="agree_privacy" fullWidth control={control} id="agree_privacy" size="small"
                            label={<p className="agree-msg"><a href="https://brianimpact.oopy.io/apply/policy/privacy-c"
                            target="_blank" rel="noreferrer">개인정보 수집ㆍ이용</a>에 동의합니다.</p>}
                            rules={{name: "agree_privacy", required: "반드시 동의하셔야 합니다.",}}
                        />
                    </div>
                    {errors.agree_privacy && <small className="error-msg agree" role="alert">{errors.agree_privacy.message}</small>}
                    <MCheckbox name="agree_marketing" fullWidth control={control} id="agree_marketing" size="small"
                        label={<p className="agree-msg"><a href="https://brianimpact.oopy.io/apply/policy/marketing"
                        target="_blank" rel="noreferrer">마케팅 목적의 개인정보 수집ㆍ이용</a>에 동의합니다.(선택)</p>}
                    />
                    <MCheckbox name="agree_ad" fullWidth control={control} id="agree_ad" size="small"
                        label={<p className="agree-msg"><a href="https://brianimpact.oopy.io/apply/policy/ad"
                        target="_blank" rel="noreferrer">광고성 정보 수신</a>에 동의합니다.(선택)</p>}
                    />
                </Grid></>}

                <Grid item xs={12} className="form-item">
                    <Button className="btn" variant="hc_contained_navy" type="submit"
                            disabled={isSubmitting || !emailVerified || !mobileVerified}>
                        가입하기
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" className="signup-ask">
                        <Stack direction="row" alignItems="center">
                            <MailOutlineIcon style={{marginRight: 3}}/>
                            <a href="mailto:contact@brianimpact.org" className="mail" target="_blank" rel="noreferrer">문의</a>
                        </Stack>
                        <Link href="/login" variant="body2" color="#000">로그인</Link>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    </Stack>
    <ConfirmDialog open={openConfirmDialog} cancelExist={false} onOk={() => moveLogin()}
        message="회원 가입이 완료되었습니다."/>
    </div>
    );
}


export default Signup;
import "../../css/bfLandingStatic.css";
import BrianFellow5Teaser from "./brianfellow/BrianFellow5Teaser";
import React from "react";

function BrianFellowLanding() {
    return (
        <BrianFellow5Teaser />
    )
}

export default BrianFellowLanding;
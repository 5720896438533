import {useParams} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import '../../css/error.css';

const ERR_MESSAGES = {
    "404": {title: "페이지를 찾을 수 없습니다.", desc: "입력하신 주소가 정확한 지 다시 한 번 확인해주세요." },
    "403": {title: "접근 권한이 없습니다.", desc: "해당 페이지에 접근할 권한이 없습니다." },
    e101: { title: '지원 사이트 연결에 문제가 있습니다.', desc: '잠시 후 다시 수행해 주세요.' },

    e999: { title: '알 수 없는 오류가 발생했습니다.', desc: '잠시 후 다시 수행해 주세요.'},
}

export default function ErrorWithCode() {
    let {errCode} = useParams();

    if (! errCode) errCode = 'e999';
    const { title, desc } = ERR_MESSAGES[errCode] || ERR_MESSAGES['e999'];
    Sentry.captureMessage(`ErrorWithCode: ${errCode}`)

    return (
        <div>
            <div className="area_error">
                <strong className="tit_error">[{errCode}] {title}</strong>
                <p className="desc_error">{desc || '잠시 후 다시 수행해 주세요. 오류가 지속되면 사무국에 문의 바랍니다.'}</p>
                <a href="/" className="link_error">[홈으로 가기]</a>
            </div>
            <div>
                <footer className="doc-footer">
                    <small>Copyright © BrianImpact All rights reserved.</small><br />
                    <small><a href="mailto:contact@brianimpact.org">contact@brianimpact.org</a></small>
                </footer>
            </div>
        </div>
    )
}

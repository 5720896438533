import React, {useMemo, useState} from 'react';
import {OutlinedInput, Grid, Slider} from '@mui/material';
import NoDataDiv from "./sub/NoDataDiv";
import HCStyledSlider from "../common/HCStyledSlider";

function HCSlider({meta, initContents, funcWriteChangeIntoStore, readOnly, dependencyEffect}) {
    // console.log('[HCSlider]', meta?.key, meta, initContents, readOnly, dependencyEffect);
    const [value, setValue] = useState(initContents?.value || meta?.options?.values?.[0]);
    const dataKey = meta.key;

    const onChangeFn = (e, v) => {
        setValue(v);
        funcWriteChangeIntoStore(dataKey, v);
    }

    //const emptyReadOnly = readOnly && !contents;
    const sliderConfig = useMemo(() => {
        if (! meta?.format) return null;
        const marks = [];
        let minValue = Number.MAX_VALUE;
        let maxValue = Number.MIN_VALUE;
        const numValues = meta.options.values.length;
        for (let i=0; i<numValues; i++) {
            const v = {value:meta.options.values[i]};
            if (i === 0) v['label'] = meta.options.labels[0];
            if (i === numValues-1) v['label'] = meta.options.labels[1];
            if (minValue > v.value) minValue = v.value;
            if (maxValue < v.value) maxValue = v.value;
            marks.push(v);
        }
        return [marks, minValue, maxValue];
    }, [meta])

    return (
        <Grid container>
            <Grid item xs={12} className="bf-slider-container">
                <HCStyledSlider
                    marks={sliderConfig[0]}
                    min={sliderConfig[1]}
                    max={sliderConfig[2]}
                    value={value}
                    valueLabelDisplay="on"
                    onChange={(e, v) => setValue(v)}
                    onChangeCommitted={onChangeFn}
                    disabled={readOnly}
                />
            </Grid>
        </Grid>
    )
}

export default HCSlider;
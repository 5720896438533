// https://developers.kakao.com/docs/latest/ko/message/message-template
export const shareKakao = (route, title, desc, imageUrl, buttonTitle) => { // url이 id값에 따라 변경되기 때문에 route를 인자값으로 받아줌
    if (window.Kakao) {
        const kakao = window.Kakao;
        if (!kakao.isInitialized()) {
            const appKey = process.env.REACT_APP_HONEYCOMB_KAKAO_DEV_KEY
            kakao.init(appKey);
        }

        kakao.Link.sendDefault({
            objectType: "feed",
            content: {
                title: title,
                description: desc,
                imageUrl: imageUrl,
                link: {
                    mobileWebUrl: route,
                    webUrl: route
                }
            },
            buttons: [{
                title: buttonTitle,
                link: {
                    mobileWebUrl: route,
                    webUrl: route
                }
            }]
        });
    }
};
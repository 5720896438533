import {Button, Dialog, DialogContent, DialogTitle, Grid, Stack} from "@mui/material";
import React from "react";
import {useMutation} from "@tanstack/react-query";
import {apiResetPassword, apiChangePassword} from "../../store/api_user";
import {feedbackSnackbarAtom} from "../../store/atoms/atom_config";
import {useSetAtom} from "jotai";
import MInputPassword from "../common/MInputPassword";
import {useForm} from "react-hook-form";
import {moveURL} from "../../util/misc";
import classnames from "classnames";
import ConfirmDialog from "./ConfirmDialog";
import {clearTokenCookie} from "../../store/cookies";


function ChangePasswordDialog(props) {
    const {
        control, watch, reset, handleSubmit,
        formState: {isSubmitting, isDirty, errors}
    } = useForm({
        defaultValues: {old_password: "", password: "", password2: ""},
        shouldFocusError: true,
    });
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const {token, mode, onClose} = props;
    const resetMode = mode === 'reset';

    const onOkConfirmDialog = () => {
        clearTokenCookie();
        moveURL('/login');
    }

    const resetPasswordMutate = useMutation({
        mutationFn: apiResetPassword,
        onSuccess: (data, variables, context) => {
            console.log('[mutation] apiResetPassword', data);
            setOpenConfirmDialog(true);
        },
        onError: (error, variables, context) => {
            console.log('onError', error, error.cause, variables, context)
            if (error.cause?.err_code === 400) {
                setFeedbackSnackbar(["인증 토큰 오류.", "error"]);
            } else {
                setFeedbackSnackbar(['서버에서 문제가 발생했습니다.', 'error']);
            }
        }
    });
    const changePasswordMutate = useMutation({
        mutationFn: apiChangePassword,
        onSuccess: (data, variables, context) => {
            console.log('[mutation] apiChangePassword', data);
            setOpenConfirmDialog(true);
        },
        onError: (error, variables, context) => {
            console.log('onError', error, error.cause, variables, context)
            if (error.cause?.err_code === 401) {
                setFeedbackSnackbar(["현재 비밀번호가 일치하지 않습니다.", "error"]);
            } else {
                setFeedbackSnackbar(['서버에서 문제가 발생했습니다.', 'error']);
            }
        }
    })


    const onCancel = () => {
        reset();
        if (onClose) onClose();
    }

    const doOk = async (data) => {
        if (resetMode) {
            resetPasswordMutate.mutate({token, new_password: data.password});
        } else {
            changePasswordMutate.mutate({old_password: data.old_password, new_password: data.password});
        }
    }

    const title = resetMode ? "비밀번호 재설정하기" : "비밀번호 변경하기";

    return (<>
    <Dialog {...props} className={classnames(props.className, {"bf-modal":true})}>
        <DialogTitle className="title">{title}</DialogTitle>
        <DialogContent className="container">
            <form onSubmit={handleSubmit(doOk)} className="bf-modal-content">
            <Grid container className="form-container">
                {!resetMode &&
                <Grid item xs={12} className="form-item">
                    <MInputPassword fullWidth control={control} variant="outlined" name="old_password" id="old_password"
                                    label="현재 비밀번호" placeholder="현재 비밀번호를 입력해주세요."
                                    aria-invalid={!isDirty ? undefined : errors.old_password ? "true" : "false"}
                                    rules={{name: "old_password", required: "현재 비밀번호는 필수 입력입니다."}} />
                    {errors.old_password && <small className="error-msg" role="alert">{errors.old_password.message}</small>}
                </Grid>}
                <Grid item xs={12} className="form-item">
                    <MInputPassword fullWidth control={control} variant="outlined" name="password" id="password"
                                    label="신규 비밀번호" placeholder="10자 이상 설정해 주세요."
                                    aria-invalid={!isDirty ? undefined : errors.password ? "true" : "false"}
                                    rules={{name: "password", required: "비밀번호는 필수 입력입니다.",
                                        minLength: {value: 10, message: "비밀번호는 10자 이상 설정해 주세요."}}} />
                    {errors.password && <small className="error-msg" role="alert">{errors.password.message}</small>}
                </Grid>
                <Grid item xs={12} className="form-item">
                    <MInputPassword fullWidth control={control} variant="outlined" name="password2" id="password2"
                                    label="신규 비밀번호 재확인" placeholder="비밀번호를 다시 입력해 주세요."
                                    aria-invalid={!isDirty ? undefined : errors.password2 ? "true" : "false"}
                                    rules={{name: "password2", required: "필수 입력입니다.",
                                        validate: (value) => value === watch('password') || "비밀번호가 일치하지 않습니다."}} />
                    {errors.password2 && <small className="error-msg" role="alert">{errors.password2.message}</small>}
                </Grid>
                <Grid item xs={12} className="form-item">
                    <Stack direction="row">
                        {!resetMode && <Button variant="hc_contained_navy" className="btn" onClick={onCancel}>취소</Button>}
                        <Button variant="hc_contained_navy" className="btn" type="submit" disabled={isSubmitting}>확인</Button>
                    </Stack>
                </Grid>
            </Grid>
            </form>
        </DialogContent>
    </Dialog>
    <ConfirmDialog open={openConfirmDialog} cancelExist={false} onOk={() => onOkConfirmDialog()}
                   message="비밀 번호가 변경되었습니다. 다시 로그인 해 주세요."/>
    </>)
}

export default ChangePasswordDialog;
import {IconButton, MenuItem, Paper, Select, Stack} from "@mui/material";
import EmbeddedFormView from "./EmbeddedFormView";
import React, {useMemo} from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LeftArrowIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';

function EmbeddedDocViewList({campaignId, docViewList, hideFn}) {
    const [selected, setSelected] = React.useState(0);

    console.log('##### [EmbeddedDocViewList]', docViewList)
    const links = useMemo(() => {
        const links = [];
        docViewList.forEach((docView, idx) => {
            if (docView.type === 'hc-doc') {
                links.push(`/c/${campaignId}/doc/${docView.doc_type}/${docView.doc_subtype}/${docView.doc_id}`);
            } else if (docView.type === 'wiki-doc' || docView.type === 'google-doc' || docView.type === 'web') {
                links.push(docView.doc_id);
            } else {
                links.push(null);
            }
        });
        return links;
    }, [docViewList]);
    const getEmbedView = (docView) => {
        switch (docView.type) {
            case 'hc-doc':
                return <EmbeddedFormView campaignId={campaignId} formId={docView.doc_id}
                                         formType={docView.doc_type} formSubType={docView.doc_subtype}/>
            case 'google-doc':
            case 'web':
                return <div className="bf-embedview-cannot-view">
                    <p>새 창에서 확인해주세요.</p>
                    <a href={docView.doc_id} target="_blank">{docView.doc_id}</a>
                </div>
            case 'wiki-doc':
            case 'bat-url':
                return <div className="bf-embedview-cannot-view">
                    <Paper style={{padding: "16px"}}>
                        <ul>
                            <li>BEES 플랫폼에서 제공하는 추가 정보입니다.</li>
                            <li>AI는 잘못된 정보를 제공할 수 있으니 유의하시기 바랍니다.</li>
                        </ul>
                    </Paper>
                    <div style={{marginTop: "16px"}}>
                        <p>새 창에서 확인해주세요.</p>
                        <a href={docView.doc_id} target="_blank" rel="noreferrer">{docView.doc_id}</a>
                    </div>
                </div>
            default:
                return <div className="bf-embedview-cannot-view">알 수 없는 문서입니다.</div>
        }
    }

    return (<>
        <Stack className="bf-embedview-menu-container" direction="row" alignItems="center" spacing={2}>
            {hideFn && <IconButton onClick={() => hideFn()}><LeftArrowIcon /></IconButton>}
            <Select value={selected} onChange={(evt) => setSelected(evt.target.value)}
            style={{flex: 1}}>
                {docViewList.map((docView, idx) => <MenuItem key={idx} value={idx}>{docView["name"]}</MenuItem>)}
            </Select>
            <IconButton style0={{width:56, marginLeft:8}}  href={links[selected]} target="_blank"
                        disabled={!links[selected]}><OpenInNewIcon /></IconButton>
        </Stack>
        <div className="bf-embedview-container">{getEmbedView(docViewList[selected])}</div>
    </>);
}

export default EmbeddedDocViewList;
import React, {useEffect, useState} from 'react';
import {AppBar, Button, Divider, Menu, MenuItem, Stack} from '@mui/material';
import {useQuery} from "@tanstack/react-query";
import {apiAnonConnect, apiConnect} from "../store/api_user";
import {metadataAtom, tokenAtom, userInfoAtom} from "../store/atoms/atom_config";
import {useAtom} from "jotai";
import {clearTokenCookie, getTokenCookie} from "../store/cookies";
import {useMatch} from "react-router-dom";
import {moveURL} from "../util/misc";
import {checkRoleForMenu, isBootOrAdmin} from "../service/userService";
import ChangePasswordDialog from "./modal/ChangePasswordDialog";
import classNames from "classnames";
import * as Sentry from '@sentry/react';
import {getFormHomeUrl} from "../service/metaService";

//import {useLocation} from "react-router";


function HeaderView({ hasPublicPage }) {
    // const {campaignId} = useParams();
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);

    const [userInfo, setUserInfo] = useAtom(userInfoAtom);
    const [token, setToken] = useAtom(tokenAtom);
    const [metadata, setMetadata] = useAtom(metadataAtom);
    
    const loginUrl = '/login?redirect=' + window.location.pathname;
    const profile = process.env.REACT_APP_STAGE || 'dev';
    const apiProfile = metadata?.config?.stage || 'unknown';

    const {data: connData, isError, isFetched, error}= useQuery(
        ['connect', token],
        () => apiConnect(),
        { enabled: !!token }
    );

    const {data: anonData} = useQuery(
        ['anonymous-connect'],
        () => apiAnonConnect(),
        { enabled: hasPublicPage===true && !token }
    );

    const logout = () => {
        clearTokenCookie();
        setUserInfo(null);
        Sentry.setUser(null);
        if (! hasPublicPage) moveURL(loginUrl);
    }

    useEffect(() => {
        const token = getTokenCookie();
        if (token) {
            setToken(token);
            if (connData) {
                setMetadata(connData.meta);
                setUserInfo(connData.me);
                Sentry.setUser({email: connData.me.email})
                console.log('[Header] connect', connData);
            }
        } else {
            logout();
        }
    }, [connData]);

    useEffect(() => {
        if (anonData && !connData) {
            setMetadata(anonData.meta);
            console.log('[Header] anon connect', anonData);
        }

    }, [anonData]);

    if (isError) {
        if (error?.cause?.status === 401 || error?.cause?.status === 404) {
            console.error('[Honeycomb] connect error', error.message, error.cause);
            logout();
        } else {
            console.error('[Honeycomb] connect error', error.message, error.cause);
            Sentry.captureException(error);
            moveURL('/error/e101');
        }
    }
    const adminUrl = profile === 'dev' ?
        'https://honeycomb-admin.dev.brianimpact.kr' :
        'https://honeycomb-admin.brianimpact.net';

    const ADMIN_MENU = [];
    if (isBootOrAdmin(userInfo)) {
        ADMIN_MENU.push(<MenuItem key='admin' onClick={()=> {closeMenu()}}
            component="a" href={adminUrl} target="_blank">허니콤 어드민</MenuItem>);
        ADMIN_MENU.push(<Divider key='admin_divider' />);
    }

    const MENU_BUTTONS = [];
    /*
    // 캠페인 별 메뉴
    if (userInfo && campaignId) {
        metadata?.campaigns?.[campaignId]?.menu.forEach(menu => {
            if (checkRoleForMenu(userInfo, campaignId, menu.doc_type, menu.doc_subtype, menu.access)) {
                const url = getFormHomeUrl(metadata, campaignId, menu.doc_type, menu.doc_subtype);
                MENU_BUTTONS.push({to: url, title: menu.name, use: true});
            }
        });
    }
     */
    if (userInfo) {
        (metadata?.bf_meta?.menu||[]).forEach(menu => {
            if (checkRoleForMenu(userInfo, menu.campaign_id, menu.doc_type, menu.doc_subtype, menu.access)) {
                const url = getFormHomeUrl(metadata, menu.campaign_id, menu.doc_type, menu.doc_subtype);
                MENU_BUTTONS.push({to: url, title: menu.name, use: true});
            }
        })
    }

    const MenuButtonComp = ({use, to, title}) => {
        const selected = useMatch(to)
        return (use && <Button className={classNames("btn-ul", {selected: selected})} href={to}>{title}</Button>);
    }
    const closeMenu= () => { setMenuAnchor(null); }

    const userMenu = function(isFetched, userInfo, menuAnchor) {
        if (token && !isFetched) return null;
        if (! userInfo) {
            return <Button className="btn-user" variant="hc_outlined_black" href={loginUrl}>로그인</Button>;
        }
        const name = userInfo.name;
        return (<>
            <Button id="hc-header-user-btn" className="btn-user" variant="hc_outlined_black"
                    onClick={(e) => setMenuAnchor(e.currentTarget)}>{`${name} 님`}</Button>
            <Menu id="menu-appbar"
                  anchorEl={menuAnchor}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={Boolean(menuAnchor)}
                  onClose={closeMenu}>
                {ADMIN_MENU.map(menu => menu)}
                <MenuItem key='change_password' onClick={()=> {
                    closeMenu();
                    setOpenChangePasswordDialog(true);
                }}>비밀번호 변경</MenuItem>
                <Divider key='logout_divider' />
                <MenuItem key='logout' onClick={logout}>로그아웃</MenuItem>
            </Menu>
        </>);
    }

    return (<>
        <AppBar className='bf-header' position='fixed' color="white">
            <Stack direction='row' alignItems="center">
                <a href="/" className='brianimpact-logo-small' aria-label="홈으로 이동">
                    <span style={{display: "block", overflow: "hidden", width: 0, height: 0}}>홈으로 이동</span>
                </a>
                {(profile === 'dev' || apiProfile === 'dev') &&
                    <div className='profile-info'><span>front-{profile}</span><br /><span>api-{apiProfile}</span></div>}
            </Stack>
            <Stack direction='row'>
                {MENU_BUTTONS.map((button, idx) => <MenuButtonComp key={idx} {...button} />)}
                {userMenu(isFetched, userInfo, menuAnchor)}
            </Stack>
        </AppBar>
        <ChangePasswordDialog open={openChangePasswordDialog} mode="change"
                              onClose={()=>setOpenChangePasswordDialog(false)} />
    </>);
}

export default HeaderView;
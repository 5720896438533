import React, {useEffect} from 'react';
import FormHeader from "../components/FormHeader";
import {useParams} from "react-router-dom";
import {getFormAtoms, metadataAtom,} from "../store/atoms/atom_config";
import {debounce} from '@mui/material/utils'
import {useAtomValue, useSetAtom} from "jotai";
import {useQuery} from "@tanstack/react-query";
import FormBodyView from "../components/FormBodyView";
import {setFormDocumentData, writeChangeToStore} from "../service/formService";
import {getFormFormatId} from "../service/metaService";

import {apiFetchEvaluation} from "../store/api_evaluation";
import {apiSubmitScorecard, apiUpdateScorecard} from "../store/api_scorecard";
import ScorecardScoreView from "../components/ScorecardScoreView";
import EmbeddedDocViewList from "../components/EmbeddedDocViewList";
import {layoutConfigAtom} from "../store/atoms/atom_ui";
import {IconButton} from "@mui/material";
import RightArrowIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';

function GenericEvaluationView() {
    const {campaignId, round, evaluationId} = useParams();
    const formType = 'scorecard';
    const formSubType = round;
    const metadata = useAtomValue(metadataAtom);
    const formAtoms = getFormAtoms(formType, formSubType);
    const setData= useSetAtom(formAtoms.dataAtom);
    const setStore = useSetAtom(formAtoms.storeAtom);
    const setNeedSave = useSetAtom(formAtoms.needSaveAtom);
    const setFormStepInfo = useSetAtom(formAtoms.stepInfoAtom);
    const setFormDependency = useSetAtom(formAtoms.dependencyAtom);
    const setLayoutConfig = useSetAtom(layoutConfigAtom)
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
    const [resourceExpanded, setResourceExpanded] = React.useState(true);
    const queryKey = ["evaluation", evaluationId];
    const formMetaId = getFormFormatId(metadata, campaignId, formType, formSubType);


    useEffect(() => {
        const onResize = debounce(() => {
            setWindowHeight(window.innerHeight);
        }, 300);

        window.addEventListener('resize', onResize);
        setLayoutConfig((prev) => { return {...prev, hideFooter: true}});
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [setLayoutConfig]);

    console.log('##### [GenericEvaluationView]');
    const {data: dataFromApi, isSuccess: dataFetched}= useQuery(
        queryKey,
        () => apiFetchEvaluation(evaluationId),
        {
            enabled: !!(metadata?.connected),
            cacheTime: 0,
            onSuccess: (data) => {
                console.log('### evaluation fetched', data);
                data[formType]['extra'] = Object.assign({}, data['extra'], {
                    application: data['application']
                });
                setFormDocumentData(data, formType, data?.form_meta_dict[formMetaId],
                    setData, setStore, setNeedSave, setFormStepInfo, setFormDependency);
                data['relatedDocs'] = data.extra.related_docs;
            }
        }
    );

    if (!dataFetched) return null;
    const formMeta = dataFromApi?.form_meta_dict[formMetaId];
    const formId = dataFromApi[formType].doc_id;
    const isOwner = dataFromApi.auth_info?.is_owner === true;
    console.log('### relatedDocs', dataFromApi.relatedDocs);

    const funcWriteChangeIntoStore = (key, value, format) => {
        writeChangeToStore(key, value, format, formId, formMeta, setStore, setNeedSave, setFormDependency);
    }
    const apiNoOps = () => {};

    return (
        <>
            <FormHeader
                campaignId={campaignId}
                formMeta={formMeta}
                formType={formType}
                formSubType={formSubType}
                formId={formId}
                queryKey={queryKey}
                dataAtom={formAtoms.dataAtom}
                storeAtom={formAtoms.storeAtom}
                stepInfoAtom={formAtoms.stepInfoAtom}
                validatorAtom={formAtoms.validatorAtom}
                needSaveAtom={formAtoms.needSaveAtom}
                updateFormBodyApi={apiUpdateScorecard}
                createFormApi={apiNoOps}
                submitFormApi={apiSubmitScorecard}
                isOwner={isOwner}
            />
            <div className="splitview-container" style={resourceExpanded ? {height: windowHeight} : null}>
                {!resourceExpanded && <div className='drawer'>
                    <IconButton size="large" onClick={() => setResourceExpanded(!resourceExpanded)}><RightArrowIcon /></IconButton>
                </div>}
                {resourceExpanded &&
                    <div className="splitview-left-container">
                        <EmbeddedDocViewList docViewList={dataFromApi.relatedDocs} campaignId={campaignId}
                                             hideFn={() => setResourceExpanded(false)}
                        />
                    </div>}
                <div className="splitview-right-container">
                    {formMeta.options?.use_score &&
                        <ScorecardScoreView formMeta={formMeta} storeAtom={formAtoms.storeAtom}/>}
                    <FormBodyView
                        campaignId={campaignId}
                        formMeta={formMeta}
                        formType={formType}
                        formAtoms={formAtoms}
                        funcWriteChangeIntoStore={funcWriteChangeIntoStore}
                        isOwner={isOwner}
                        isEmbed={true}
                    />
                </div>
            </div>
        </>
    );
}

export default GenericEvaluationView;
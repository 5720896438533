import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React from "react";
import {useAtom} from "jotai";
import {configGlobalConfirm, globalConfirmAtom} from "../../store/atoms/atom_ui";

function GlobalModalDialog({...props}) {
    const [globalConfirm, setGlobalConfirm] = useAtom(globalConfirmAtom);
    const onCancel = () => {
        const callback = globalConfirm?.onCancel;
        setGlobalConfirm(configGlobalConfirm({}));
        if (callback) callback();
    }
    const onOk = () => {
        const callback = globalConfirm?.onOk;
        setGlobalConfirm(configGlobalConfirm({}));
        if (callback) callback();
    }

    return (
        <Dialog fullWidth maxWidth="xs" open={globalConfirm.open} className="bf-global-dlg" {...props}>
            <DialogTitle className="dlg-title">{globalConfirm?.title}</DialogTitle>
            <DialogContent className="dlg-content">
                <DialogContentText className="dlg-content-text">
                    {globalConfirm?.contents.map((content, idx) =>
                        <React.Fragment key={idx}><span key={idx}>{content}</span><br /></React.Fragment>)}
                </DialogContentText>
            </DialogContent>
            <DialogActions className="dlg-actions">
                {globalConfirm?.useCancel && <Button className="btn" onClick={onCancel} variant="hc_contained_navy">{globalConfirm?.cancelStr}</Button>}
                <Button className="btn" onClick={onOk} variant="hc_contained_navy">{globalConfirm?.okStr}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default GlobalModalDialog;
import React from 'react';
import {Navigate, useParams} from "react-router-dom";
import BFEvaluationList from "../components/BFEvaluationList";
import {Grid} from "@mui/material";

function GenericEvaluationHome(props) {
    const {campaignId, round} = useParams();
    const roundInfo = round.split('-');
    const childProps = {campaignId, ...props};

    const bf2023header = () => {
        switch (roundInfo[0]) {
            case 'paper':
                return (<div className="bf-eval-list-header"><h1>서류 심사</h1></div>);
            case 'onsite':
                return (<div className="bf-eval-list-header"><h1>현장 인터뷰 평가</h1></div>);
            case 'final':
                let final_title = '최종 심사';
                if (roundInfo.length > 1) final_title = roundInfo[1] === 'pre1' ? '최종 심사 (사전심사)' : '최종 심사 (본심사)';
                return (
                    <div className="bf-eval-list-header">
                        <h1>브라이언 펠로우 {final_title}</h1>
                        <div className='bf-eval-final'>
                            <p className='desc'>안녕하세요, 이곳은 최종 심사에 참여하는 선정위원의 심사페이지 입니다.</p>
                            <hr />
                            <Grid container className='baseline'>
                                <Grid item xs={6}>
                                    <h2>최종심사 심사기준</h2>
                                    <ol className='baseline-list'>
                                        <li>핵심아젠다(3문항)</li>
                                        <li>혁신성(3문항)</li>
                                        <li>임팩트와 가능성(3문항)</li>
                                        <li>확산 및 모델링가능성(3문항)</li>
                                    </ol>
                                </Grid>
                                <Grid item xs={6}>
                                    <h2>최종 심사 절차 안내</h2>
                                    <ol className='baseline-list'>
                                        <li>1차 온라인 심사 평가 제출</li>
                                        <li>오프라인 인터뷰 진행</li>
                                        <li>오프라인 인터뷰 기반 2차 심사 평가 제출</li>
                                        <li>선정위원 토론 후 최종 심사 평가 제출</li>
                                    </ol>
                                </Grid>
                            </Grid>
                            <hr />
                        </div>
                    </div>
                );
        }
    }

    return (
        <div className="bf-document-home">
            {bf2023header()}
            <BFEvaluationList {...childProps} />
        </div>
    );
}

export default GenericEvaluationHome;
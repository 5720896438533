import {atom} from "jotai";

const DEFAULT_CONFIRM_SETTINGS = {
    open: false,
    title: '',
    contents: [],
    okStr: '확인',
    cancelStr: '취소',
    onOk: null,
    onCancel: null,
    useCancel: true,
}

const globalConfirmAtom = atom(DEFAULT_CONFIRM_SETTINGS);

const configGlobalConfirm = function(settings) {
    return {...DEFAULT_CONFIRM_SETTINGS, ...settings}
}

// hideFooter: true of false
const layoutConfigAtom = atom({});

export {
    globalConfirmAtom,
    configGlobalConfirm,
    layoutConfigAtom,
}
// https://www.npmjs.com/package/react-cookie
import { Cookies } from 'react-cookie'

const cookie = new Cookies();

function setTokenCookie(token) {
    cookie.set('token', token, { path: '/', maxAge: 60*60*24*7, sameSite: 'strict', secure: true });
}

function getTokenCookie() {
    return cookie.get('token');
}

function clearTokenCookie() {
    cookie.remove('token', { path: '/' });
}

export {
    setTokenCookie,
    getTokenCookie,
    clearTokenCookie,
}

import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import bf004_evaluation_nda from "../terms/bf004_evaluation_nda";
import bf004_application_agreement from "../terms/bf004_application_agreement";
import bf005_application_agreement from "../terms/bf005_application_agreement";
import bf005_evaluation_nda from "../terms/bf005_evaluation_nda";
import bf003_fellow_extend_nda from "../terms/bf003_fellow_extend_nda";

const terms_contents = {
    'bf004_evaluation_nda': bf004_evaluation_nda,
    'bf004_application_agreement': bf004_application_agreement,
    'bf005_application_agreement': bf005_application_agreement,
    'bf005_evaluation_nda': bf005_evaluation_nda,
    'bf003_fellow_extend_nda': bf003_fellow_extend_nda,
}

function TermsAgreementDialog({termId, open, closeHandler, okHandler}) {
    if (! terms_contents[termId]) {
        return null;
    }
    const terms = terms_contents[termId]();
    return (
        <Dialog fullWidth className="bf-modal" open={open}
             slotProps={{ backdrop: { style: { backgroundColor: 'rgb(0,0,0,0.9)'} } }}
        >
            <DialogTitle className="title">{terms.title}</DialogTitle>
            <DialogContent className="container">{terms.content}</DialogContent>
            <DialogActions>
                <Button variant="hc_contained_navy" onClick={closeHandler}>취소</Button>
                <Button variant="hc_contained_navy" onClick={okHandler}>동의합니다.</Button>
            </DialogActions>
        </Dialog>

    )
}

export default TermsAgreementDialog;
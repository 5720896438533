import {Button, IconButton, Stack} from '@mui/material';
import {useAtom, useAtomValue, useSetAtom} from "jotai";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {arrayToObj, buildErrorMessage, utcDayjs} from "../util/misc";
import {feedbackSnackbarAtom, metadataAtom, userInfoAtom} from "../store/atoms/atom_config";
import React, {useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {configGlobalConfirm, globalConfirmAtom} from "../store/atoms/atom_ui";
import {getFormActionNameFromType, getFormNameFromType, validateFormData} from "../service/formService";
import StepIndicator from "./common/StepIndicator";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SaveIcon from '@mui/icons-material/Save';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConfirmDialog from "./modal/ConfirmDialog";
import {isAdmin} from "../service/userService";
import mustache from "mustache";
import {apiAgreeTerms} from "../store/api_user";
import {getFormTermsNeeded, isFormSubmittable} from "../service/metaService";
import TermsAgreementDialog from "./modal/TermsAgreementDialog";


function FormHeader({campaignId, formId, formType, formSubType, formMeta, queryKey,
                        needSaveAtom, dataAtom, storeAtom, stepInfoAtom, validatorAtom,
                        updateFormBodyApi, createFormApi, submitFormApi, isOwner}) {

    const metadata = useAtomValue(metadataAtom);
    const [userInfo, setUserInfo] = useAtom(userInfoAtom);
    const data = useAtomValue(dataAtom);
    const [needSave, setNeedSave] = useAtom(needSaveAtom);
    const store = useAtomValue(storeAtom);
    const formStepInfo = useAtomValue(stepInfoAtom);
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const setValidator = useSetAtom(validatorAtom);
    const navigate = useNavigate();
    const setGlobalConfirm = useSetAtom(globalConfirmAtom);
    const queryClient = useQueryClient();
    const [validationMessage, setValidationMessage] = React.useState(null);
    const [termsAgreementNeeded, setTermsAgreementNeeded] = React.useState(null);
    //console.log('[FormHeader]', data, store);

    const numSteps = formMeta?.steps?.length || 0;
    const useStepIndicator = (numSteps>0) && isOwner;
    const round = formSubType;

    const saveFormMutation = useMutation({
        mutationFn: updateFormBodyApi,
        onSuccess: (data, variables, context) => {
            console.log('onSuccess', data, variables, context);
            setFeedbackSnackbar(['저장되었습니다.', 'info']);
            setNeedSave(false);
        },
        onError: (error, variables, context) => {
            console.log('onError', error, variables, context)
            setFeedbackSnackbar(['저장에 실패했습니다.', 'error']);
        }
    });

    const createMutation = useMutation({
        mutationFn: createFormApi,
        onSuccess: (resData, variables, context) => {
            console.log('onCreateSuccess', data, variables, context);
            const docId = data.doc_id;
            setFeedbackSnackbar(['저장되었습니다.', 'info']);
            const refreshUrl = `/c/${campaignId}/doc/${formType}/${formSubType}/${docId}`;
            navigate(refreshUrl, {replace: true});
        },
        onError: (error, variables, context) => {
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });

    const submitMutation = useMutation({
        mutationFn: submitFormApi,
        onSuccess: (resData, variables, context) => {
            console.log('onSuccess', data, variables, context);
            const msg = variables.isSubmit ? '제출되었습니다.' : '회수되었습니다.';
            setFeedbackSnackbar([msg, 'info']);
            queryClient.invalidateQueries(queryKey).then(r => console.log('resetQueries', r));
        },
        onError: (error, variables, context) => {
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });

    const agreeTermsMutation = useMutation({
        mutationFn: apiAgreeTerms,
        onSuccess: (resData, variables, context) => {
            console.log('onSuccess', resData, variables, context);
            setUserInfo(resData);
            setTermsAgreementNeeded(null);
            if (variables.check === 'submit') {
                submitToServer(true);
            }
        },
        onError: (error, variables, context) => {
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });

    const formTitle = useMemo(() => {
        let titleStr = isOwner ? formMeta.header?.title?.user : formMeta.header?.title?.admin;
        if (!titleStr) return '';
        // ToDo
        if (round === 'final-pre1') titleStr += ' (사전심사)';
        else if (round === 'final-pre2') titleStr += ' (본심사)';

        return mustache.render(titleStr, data);
    }, [data, formMeta, isOwner]);

    useEffect(() => {
        if (!userInfo) return;
        const term = getFormTermsNeeded(userInfo, metadata, campaignId, formType, formSubType);
        if (term && term['check'] === 'enter' && !isAdmin(userInfo, campaignId)) {
            setTermsAgreementNeeded(term);
        }
    }, [userInfo, metadata, campaignId, formType, formSubType]);

    /*
    let shouldBlock = React.useCallback(({ currentLocation, nextLocation }) =>
        needSave && currentLocation.pathname !== nextLocation.pathname, [needSave]);
    let blocker = useBlocker(shouldBlock);
     */

    const onSave = () => {
        console.log('### save', store);
        if (formId === 'new-empty-doc') {
            const newDoc = {...data, form_data: store};
            createMutation.mutate({id: formId, data: newDoc});
        } else {
            saveFormMutation.mutate({id: formId, formData: store});
        }
    }

    const onSubmit = (isSubmit) => {
        const docName = getFormNameFromType(formType);
        const submitName = getFormActionNameFromType(formType);
        const submittable = isFormSubmittable(metadata, campaignId, formType, formSubType);
        if (isSubmit && !submittable) {
            const contents = [`${submitName} 가능한 기간이 아닙니다.`]
            if (formType !== 'application') contents.push('재단 사무국으로 문의하시기 바랍니다.');
            setGlobalConfirm(configGlobalConfirm({
                open: true,
                title: `${docName}를 제출할 수 없습니다.`,
                contents: contents,
                useCancel: false,
            }));
        } else {
            setGlobalConfirm(configGlobalConfirm({
                open: true,
                title: isSubmit ? `${docName}를 제출하시겠습니까?` : `${docName}를 회수하시겠습니까?`,
                contents: isSubmit ? ['제출 후에는 수정이 불가능합니다.'] : [`제출을 취소하면 ${docName}를 수정할 수 있습니다.`],
                onOk: () => submitValidation(isSubmit)
            }));
        }
    }

    const submitValidation = (isSubmit) => {
        if (isSubmit) {
            const validateResult = validateFormData(store, formMeta);
            //console.log('validate', validateResult);
            if (validateResult.length > 0) {
                setValidator(arrayToObj(validateResult, 'key'));
                const title = `${validateResult.length}개의 입력값에 오류가 있습니다.`;
                const message = formMeta.steps ?
                    <>입력 양식이 맞지 않거나 필수 항목 중 미입력된 부분이 있어 제출이 완료되지 않았습니다. 오류가 있는 항목은 빨간색으로 표시가 되어있습니다. Step 1부터 차근히 확인해주세요.</> :
                    <>입력 양식이 맞지 않거나 필수 항목 중 미입력된 부분이 있어 제출이 완료되지 않았습니다. 오류가 있는 항목은 빨간색으로 표시가 되어있습니다.</>
                setValidationMessage({title, message});
            } else {
                setValidator({});
                setValidationMessage(null);
                const term = getFormTermsNeeded(userInfo, metadata, campaignId, formType, formSubType);
                if (term && term['check'] === 'submit') {
                    setTermsAgreementNeeded(term);
                } else {
                    submitToServer(isSubmit);
                }
            }
        } else {
            submitToServer(isSubmit);
        }
    }

    const submitToServer = (isSubmit) => {
        submitMutation.mutate({id: formId, isSubmit: isSubmit});
    }

    const agreeTerms = (agreement) => {
        if (agreement) {
            if (termsAgreementNeeded.save) {
                agreeTermsMutation.mutate({terms_id: termsAgreementNeeded.id, check: termsAgreementNeeded.check});
            } else if (termsAgreementNeeded.check === 'submit') {
                setTermsAgreementNeeded(null);
                submitToServer(true);
            }
        } else {
            setTermsAgreementNeeded(null);
            if (termsAgreementNeeded.check === 'enter') {
                navigate(-1);
            }
        }
    }

    const statusChip = (status) => {
        if (status === 'draft') return (<div className="hc-chip">작성 중</div>);
        else if (status === 'submitted') return (<div className="hc-chip orange">제출 완료</div>);
        else return (<div className="hc-chip red">상태 이상</div>)
    }

    if (!data) return null;

    const getListUrl = () => formType === 'scorecard' ? `/c/${campaignId}/eval/${round}`: `/c/${campaignId}/doc/${formType}`;

    const updatedAt = utcDayjs(data.updated_at).format('YYYY.MM.DD HH:mm:ss');
    const canSubmit = !needSave && data.status === 'draft';
    const canWithdraw = isAdmin(userInfo, campaignId) && !needSave && data.status === 'submitted';
    const showSave = isOwner && data.status === 'draft';

    return (
        <div className="form-header">
            <Stack sx={{display:{xs:"none", sm:'flex'}}} className="form-appbar" direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Stack>
                    <h2 className="form-header-title">{formTitle}</h2>
                    <Stack direction="row" spacing={1} className="form-header-info">
                        {statusChip(data.status)}
                        <div className="update-time">{updatedAt}</div>
                        {useStepIndicator && <StepIndicator numStep={numSteps} currentStep={formStepInfo.currentStep} />}
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                    {isOwner && <Button className='btn' variant="hc_contained_navy"
                            onClick={() => navigate(getListUrl())} >목록으로</Button>}
                    {!isOwner && <Button className='btn' variant="hc_contained_navy" onClick={()=>navigate(-1)}>뒤로 가기</Button>}
                    {showSave && <Button className='btn' variant="hc_contained_navy"
                            onClick={onSave} disabled={!needSave}>저장</Button>}
                    {showSave && <Button className='btn' variant="hc_contained_navy" disabled={!canSubmit}
                            onClick={() => onSubmit(true)}>제출</Button>}
                    {canWithdraw && <Button className='btn' variant="hc_contained_navy"
                        onClick={() => onSubmit(false)}>회수</Button>}
                </Stack>
            </Stack>
            <Stack sx={{display:{xs:'block',sm:'none'}}}>
                <h2 className="form-header-title">{formTitle}</h2>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    {statusChip(data.status)}
                    {useStepIndicator && <StepIndicator numStep={numSteps} currentStep={formStepInfo.currentStep} />}
                    <Stack direction="row">
                        {isOwner && <IconButton className='btn' onClick={() => navigate(getListUrl())} ><FormatListBulletedIcon /></IconButton>}
                        {!isOwner && <IconButton className='btn' onClick={()=>navigate(-1)}><ArrowBackIcon /></IconButton>}
                        {showSave && <IconButton className='btn' onClick={onSave} disabled={!needSave}><SaveIcon /></IconButton>}
                        {showSave && <IconButton className='btn' disabled={!canSubmit} onClick={() => onSubmit(true)}><DoneAllIcon /></IconButton>}
                        {canWithdraw && <IconButton className='btn' onClick={() => onSubmit(false)}><RemoveDoneIcon /></IconButton>}
                    </Stack>
                </Stack>
            </Stack>
            <hr className="form-header-hr" />
            <ConfirmDialog open={!! validationMessage} onOk={() => setValidationMessage(null)}
                title={validationMessage?.title} message={validationMessage?.message} />

            <TermsAgreementDialog open={!!termsAgreementNeeded} closeHandler={() => agreeTerms(false)}
                                  termId={termsAgreementNeeded?.id} okHandler={() => agreeTerms(true)}/>

            {/*<ConfirmDialog open={blocker.state === 'blocked'} onOk={() => blocker.proceed()} onClose={() => blocker.reset()}
                title="ha" cancelExist
                message="작성중인 내용이 있습니다. 페이지를 벗어나시겠습니까?" />*/}
        </div>
    );
}

export default FormHeader;
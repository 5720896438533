import React, {useState} from 'react';
import {OutlinedInput, Grid} from '@mui/material';

function UnknownFormItem({meta, initContents, funcWriteChangeIntoStore}) {
    console.log('[UnknownFormItem]', meta?.key, meta?.format, meta, initContents);
    return (
        <Grid container>
            <Grid item xs={12}>
                <OutlinedInput
                    fullWidth
                    defaultValue={initContents}
                    readOnly
                ></OutlinedInput>
            </Grid>
        </Grid>
    )
}

export default UnknownFormItem;